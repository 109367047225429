import React from "react";
import CountUp from "react-countup";
const Counter = ({ number, title }) => {
  return (
    <>
      <div className="number">
        <div>
          <CountUp duration={10} className="counter" end={number} />
          <h4>+</h4>
        </div>
        <span>{title}</span>
      </div>
    </>
  );
};

export default Counter;
