import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectProductsrediff,
  selectCardrediff,
  addrediffCard,
} from "../../../app/slices/shoppingrediffSlice";

const Product = ({
  _id,
  name,
  price,
  description,
  description2,
  description3,
  description4,
  description5,
  description6,
  description7,
  description8,
}) => {
  const dispatch = useDispatch();
  const cardrediff = useSelector(selectCardrediff);
  let message = "Add To Cart";
  if (cardrediff?.productsrediff?.some((pro) => pro.id == _id))
    message = "Added To Cart";

  return (
    <div className="col-12 col-md-4 col-lg-4 ">
      <div className="email-sub-logo-div h-auto">
        <h4>
          <img
            className="img-fluid "
            src="assest/photos/email/rupee.png"
            alt="G-Rank"
            title="grank"
          />
          {price}
          <span>/yr</span>
        </h4>
        <div className="d-flex">
          <div className="email-small-button">{name} </div>
          <h5>{description}</h5>
        </div>
        <h2>{description2}</h2>
        <h2>{description3}</h2>
        <h2>{description4}</h2>
        <h2>{description5}</h2>
        <h2>{description6}</h2>
        <h2>{description7}</h2>
        <h2>{description8}</h2>

        <h6
          onClick={() =>
            sessionStorage.setItem("product26", _id) |
            dispatch(addrediffCard({ id: _id }))
          }
        >
          {message}
        </h6>
      </div>
    </div>
  );
};
const AddToCartRediff = () => {
  const productsrediff = useSelector(selectProductsrediff);
  return (
    <>
      {Array.isArray(productsrediff) &&
        productsrediff.map((prod, i) => <Product key={i} {...prod} />)}
    </>
  );
};

export default AddToCartRediff;
