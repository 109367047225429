import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./slices/AuthSlice";
import shoppingReducer from "./slices/shoppingSlice";
import shoppingsecuReducer from "./slices/shoppingsecuSlice";
import shoppinggoogleReducer from "./slices/shoppinggoogleSlice";
import shoppingmicroReducer from "./slices/shoppingmicroSlice";
import shoppingrediffReducer from "./slices/shoppingrediffSlice";
import dropdownReducer from "./slices/dropdown.Slice";
import dropdowngoogleReducer from "./slices/dropdowngoogle.Slice";
import dropdownmicroReducer from "./slices/dropdownmicro.Slice";
import dropdownrediffReducer from "./slices/dropdownrediff.Slice";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducer = combineReducers({
  auth: authReducer,
  shopping: shoppingReducer,
  shoppingsecu: shoppingsecuReducer,
  shoppinggoogle: shoppinggoogleReducer,
  shoppingmicro: shoppingmicroReducer,
  shoppingrediff: shoppingrediffReducer,
  dropdown: dropdownReducer,
  dropdowngoogle: dropdowngoogleReducer,
  dropdownmicro: dropdownmicroReducer,
  dropdownrediff: dropdownrediffReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const store = configureStore({
  reducer: persistedReducer,
});

// export const Persistor = persistStore(store);

export default store;
