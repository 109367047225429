import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectProductsmicro,
  selectCardmicro,
  addmicroCard,
} from "../../../app/slices/shoppingmicroSlice";

const Product = ({
  _id,
  name,
  price,
  description,
  description2,
  description3,
  description4,
  description5,
  description6,
  description7,
  description8,
  img,
}) => {
  const dispatch = useDispatch();
  const cardmicro = useSelector(selectCardmicro);
  let message = "Add To Cart";
  if (cardmicro?.productsmicro?.some((pro) => pro.id == _id))
    message = "Added To Cart";
  console.log("img", img);
  return (
    <div className="col-12 col-md-3 col-lg-3 email-sub-logo-div2">
      {/* <h4>most popular</h4> */}
      <h5>{name}</h5>
      <div className="email-sub-logo-div4">
        <h4>
          <img
            className="img-fluid "
            src="assest/photos/email/rupee.png"
            alt="G-Rank"
            title="Email"
          />
          {price}
          <span>user/month</span>
        </h4>

        <h2>(annual subscription-auto renews)</h2>
        <h6
          onClick={() =>
            sessionStorage.setItem("product25", _id) |
            dispatch(addmicroCard({ id: _id }))
          }
        >
          {message}
        </h6>
        <h2>Try fee for one month</h2>
        <h2>{description}</h2>
        <p>
          <img
            className="img-fluid micro-yes-height"
            src="assest/photos/email/check.png"
            alt="G-Rank"
            title="Email"
          />
          {description2}
        </p>
        <p>
          <img
            className="img-fluid micro-yes-height"
            src="assest/photos/email/check.png"
            alt="G-Rank"
            title="Email"
          />
          {description3}
        </p>
        <p>
          <img
            className="img-fluid micro-yes-height"
            src="assest/photos/email/check.png"
            alt="G-Rank"
            title="Email"
          />
          {description4}
        </p>
        {description5 == "" ? (
          <p></p>
        ) : (
          <p>
            <img
              className="img-fluid micro-yes-height"
              src="assest/photos/email/check.png"
              alt="G-Rank"
              title="Email"
            />
            {description5}
          </p>
        )}
        {description6 == "" ? (
          <p></p>
        ) : (
          <p>
            <img
              className="img-fluid micro-yes-height"
              src="assest/photos/email/check.png"
              alt="G-Rank"
              title="Email"
            />
            {description6}
          </p>
        )}
        {description7 == "" ? (
          <p></p>
        ) : (
          <p>
            <img
              className="img-fluid micro-yes-height"
              src="assest/photos/email/check.png"
              alt="G-Rank"
              title="Email"
            />
            {description7}
          </p>
        )}
        {description8 == "" ? (
          <p></p>
        ) : (
          <p>
            <img
              className="img-fluid micro-yes-height"
              src="assest/photos/email/check.png"
              alt="G-Rank"
              title="Email"
            />
            {description8}
          </p>
        )}
      </div>
    </div>
  );
};
const AddToCartMicro = () => {
  const productsmicro = useSelector(selectProductsmicro);

  return (
    <>
      {Array.isArray(productsmicro) &&
        productsmicro.map((prod, i) => <Product key={i} {...prod} />)}
    </>
  );
};

export default AddToCartMicro;
