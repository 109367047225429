import React, { useEffect } from "react";
import Header from "../header2/Header";
import Seo from "../seo/Seo";
//import { color, motion } from "framer-motion";
import "animate.css";
import WOW from "wowjs";
const TeamsCondition = () => {
  useEffect(() => {
    new WOW.WOW({
      live: false,
    }).init();
  }, []);
  return (
    <>
      <Seo
        title="Blog | G-Rank"
        description="Dive into our Blog to explore a treasure trove of articles, tips, and expertise that cover a wide range of topics. "
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="react, meta tags, seo"
        searchengines="ALL"
        canonical="https://www.grank.co.in/terms-conditions"
      ></Seo>
      <section className="section-top">
        <Header />

        <div className="container-fluid">
          <div className="row">
            <div className="col-12 teams-heading-div1">
              <h5>Terms & Conditions</h5>
            </div>
          </div>
        </div>
        <div className="container-fluid  teams-section-new">
          <div className="row">
            <div className="col-12 tearm-condition-div">
              <h4>Acceptance of these Terms of Use</h4>
              <p>
                You agree to be bound by these Terms of Use by accessing and
                using the G-Rank Digital Services Private Limited website. If
                you do not agree with any of these terms, please do not use our
                website. We retain the right to change these Terms of Service at
                any time and without notice. You must evaluate the most recent
                version of these terms frequently.
                <br />
                The terms also apply to updates and redesigns, You may
              </p>
              <div className="teams-div2">
                <p className="term-flex-div">
                  <p> (1) </p>
                  <p>
                    Reverse engineering, decompiling or disassembling the
                    Website Products and Services are strictly prohibited.
                  </p>
                </p>
                <p className="term-flex-div">
                  <p> (2)</p>

                  <p>
                    Do not make copies or replicas of website products and
                    services, content or anything else.
                  </p>
                </p>
                <p className="term-flex-div">
                  <p> (3)</p>
                  <p>
                    You will never be a legal person/developer/designer to work
                    on any technical limitations in the Website Products and
                    Services.
                  </p>
                </p>
                <p className="term-flex-div">
                  <p>(4)</p>
                  <p>
                    You are not authorized to publish the Copy of Website
                    Products and Services.
                  </p>
                </p>
                <p className="term-flex-div">
                  <p> (5)</p>
                  <p>
                    Do not transfer the Website Products and Services to any
                    third party.
                  </p>
                </p>
              </div>

              <h4>Payment/Refund/Cancellation</h4>
              <p>We have several types of payment-receiving methods.</p>
              <div className="teams-div2">
                <p className="term-flex-div">
                  <p> (a)</p>
                  <p> UPI</p>
                </p>
                <p className="term-flex-div">
                  <p> (b)</p>
                  <p> Online Payment (Debit / Credit card, Net Banking)</p>
                </p>
                <p className="term-flex-div">
                  <p> (c)</p>
                  <p>NEFT/Fund Transfer</p>
                </p>
                <p className="term-flex-div">
                  <p> (d)</p>
                  <p>Paytm, PhonePay, Google Pay</p>
                </p>
                <p className="term-flex-div">
                  <p> (e)</p>
                  <p> Paypal Payment (Only for Non-Indian Customers)</p>
                </p>
                <p className="term-flex-div">
                  <p>(1)</p>
                  <p>
                    All payments are non-refundable, and no Website Products and
                    Services will be back once sold.
                  </p>
                </p>

                <p className="term-flex-div">
                  <p>(2)</p>
                  <p>
                    In the case of Website Products and Services, We start order
                    processing after 75% of the amount paid by the customer.
                  </p>
                </p>
                <p className="term-flex-div">
                  <p>(3)</p>
                  <p>We do not accept any cheque Payments.</p>
                </p>
                <p className="term-flex-div">
                  <p>(4)</p>
                  <p>You will be liable to pay GST as applicable.</p>
                </p>
                <p className="term-flex-div">
                  <p>(5)</p>
                  <p>
                    All services, License, Product, Modification, design, and
                    error correction are paid or prepaid by the customer to the
                    company.
                  </p>
                </p>
                <p className="term-flex-div">
                  <p>(6)</p>
                  <p>
                    Domain is purchased by the client, so it should be liable
                    for expiration.
                  </p>
                </p>
                <p className="term-flex-div">
                  <p>(7)</p>
                  <p>
                    Once the Website Design is finalized you won't be changing
                    the Website's structure
                  </p>
                </p>
                <p className="term-flex-div">
                  <p> (8)</p>
                  <p>
                    Payment is to be done on time otherwise the project will be
                    suspended due to delayed payment.
                  </p>
                </p>

                <p className="term-flex-div">
                  <p> (9) </p>
                  <p>
                    In case of dues payment, the company will provide no
                    services or support. The company has the right to stop all
                    services & training and withdrawal of Website Products, &
                    cancel the license.
                  </p>
                </p>
                <p className="term-flex-div">
                  <p> (10)</p>
                  <p>
                    Invoice generated at the time of 100% payment clearance and
                    it will be sent to your email ID.
                  </p>
                </p>
                <p className="term-flex-div">
                  <p> (11)</p>
                  <p>
                    We have reserved the right to change the terms & conditions
                    and pricing without prior notice.
                  </p>
                </p>
                <p className="term-flex-div">
                  <p> (12)</p>
                  <p>
                    In case of cancellation of agreement, no refund is given.
                  </p>
                </p>
                <p className="term-flex-div">
                  <p> (13)</p>
                  <p>
                    In case of cancellation/ termination of the agreement, the
                    company will not provide any support.
                  </p>
                </p>
                <p className="term-flex-div">
                  <p> (14)</p>
                  <p>
                    We do not guarantee any specific position in search engine
                    results for your website. We perform Best search engine
                    optimization for your Website
                  </p>
                </p>

                <p className="term-flex-div">
                  <p> (15)</p>
                  <p>
                    The client must indemnify that the company, its officers,
                    directors, employees, agents, licensors, suppliers etc will
                    not be liable for any claims, liabilities, losses, expenses,
                    damage, and cost, including attorney's fees, resulting from
                    the breach of the agreement and activities of the client.
                  </p>
                </p>
              </div>

              <h4>Terms of Service, Notices, and Revisions</h4>
              <p>
                Your use of the G-Rank Digital Services Private Limited website
                is subject to these Terms of Use and our Privacy Policy. Any
                disputes related to privacy or the use of this website are
                governed by these terms and the laws of the state of India. We
                may change these Terms of Use and our Privacy Policy, and you
                are responsible for staying updated with any modifications.
              </p>

              <h4>Newsletter Subscription</h4>

              <p className="animate__animated animate__fadeInUp">
                We will not sell your email information to third parties if you
                subscribe to our newsletter. You may unsubscribe from our email
                anytime by following the instructions in each message or by
                contacting us directly. Unless you specify otherwise, all
                contacts from our website may be added by default to our CRM and
                newsletter distribution list.
              </p>
              <h4>Phone Calls</h4>
              <p>
                By participating in phone calls with G-Rank Digital Services
                Private Limited, you consent to monitoring or recording such
                calls for quality assurance.
              </p>
              <h4>Mandatory Conditions:</h4>
              <p className="term-flex-div">
                <p>1.</p>
                <p>
                  By accessing or using the G-Rank Digital Services Private
                  Limited website, you agree to comply with and be bound by the
                  following mandatory conditions.
                </p>
              </p>
              <p className="term-flex-div">
                <p>2.</p>
                <p>
                  The content provided on the G-Rank website is for general
                  information purposes only and is subject to change without
                  notice.
                </p>
              </p>
              <p className="term-flex-div">
                <p>3.</p>
                <p>
                  Using any information or materials on this website is entirely
                  at your own risk, for which we shall not be liable. You are
                  responsible for ensuring that any available products,
                  services, or information meet your requirements.
                </p>
              </p>
              <p className="term-flex-div">
                <p>4.</p>
                <p>
                  Unauthorized use of this website may give rise to a claim for
                  damages and/or be a criminal offense.
                </p>
              </p>
              <p className="term-flex-div">
                <p>5.</p>
                <p>
                  From time to time, this website may also include links to
                  other websites. These links are provided for your convenience
                  to provide further information. They do not signify that we
                  endorse the website(s). We have no responsibility for the
                  linked website(s) content.
                </p>
              </p>

              <h4 style={{ color: "#555" }}>Refund Policy:</h4>
              <h4>For Hosting - 7-day money-back guarantee</h4>
              <p>
                If you are not happy with our Hosting Services for whatever
                reason, we'll refund your payment. 7-day money-back guarantee
                <br />
                There is no refund in the taxable amount.
                <br />
                We provide the best Web hosting at an affordable cost and you
                can not use this hosting for any kind of illegal work like
                spamming or pornography. If someone is found doing this, we will
                stop hosting services and get any no refund.
              </p>

              <h4>For Domain</h4>
              <p>We do not provide refunds in Domains</p>
              <h4>For Web Development </h4>
              <p>
                We do not provide refunds For Web Development. We provide the
                highest quality of service to our customers
              </p>
              <h4>For Website/Software Design </h4>
              <p>
                We do not refund website/Software Design Once it is Developed.
                We provide the highest quality of service to our customers. In
                case, we are unable to develop a website/software for any
                reason, we will refund all Advanced deposit amounts.
              </p>
              <h4>For Digital Marketing </h4>
              <p>
                We do not provide refunds In Digital Marketing. We create the
                best ad for you but don't take any responsibility for the ad's
                performance
              </p>
              <h4>For SEO Services </h4>
              <p>
                We do not provide refunds In SEO Services. We do the best SEO
                for you, SEO is time-consuming , but We Show improvement in Your
                Website Ranking.
              </p>
              <h4>For Add On </h4>
              <p>We do not provide refunds in addition to services.</p>
              <h4>Cancellation </h4>
              <p>We don't have any Cancellation Policy.</p>
              <h4>Termination</h4>
              <p>
                We may terminate or suspend access to our Service immediately,
                without prior notice or liability, for any reason whatsoever,
                including without limitation if you breach the Terms. All
                provisions of the Terms which by their nature should survive
                termination shall survive termination, including, without
                limitation, ownership provisions, warranty disclaimers,
                indemnity, and limitations of liability.
              </p>
              <h4>MANDATORY CONDITIONS</h4>
              <p>
                We reserve the right to refuse service to anyone for any reason.
                <br />
                You understand that your content (excluding credit card
                information) may be transferred unencrypted and involve (a)
                transmissions over various networks and (b) changes to conform
                and adapt to the technical requirements of connecting networks
                or devices. Credit card information is always encrypted during
                transfer over networks. You agree not to reproduce, duplicate,
                copy, sell, resell, or exploit any Service portion, use of the
                Service, or access to the Service or any contact on the website
                through which the Service is provided without express written
                permission from G-Rank Digital Services Private Limited. The
                headings in this agreement are included for convenience only and
                will not limit or otherwise affect these Terms.
              </p>

              <h4>Copyright and Trademarks</h4>
              <p className="animate__animated animate__fadeInUp">
                All content on the G-Rank Digital Services Private Limited
                website, including text, images, videos, and design, is owned by
                G-Rank Digital Services Private Limited or third parties who
                have permitted its use. Copyright of all such content is
                explicit by its appearance on our website. Using any trademarks
                displayed on our website without written permission from G-Rank
                Digital Services Private Limited is strictly prohibited.
              </p>
              <h4>Methods of payment</h4>
              <p className="animate__animated animate__fadeInUp">
                Information related to accepted payment methods is made
                available during the purchasing process. All payments for G-Rank
                Digital Services Private Limited services must be done through a
                secure payment gateway that accepts credit cards, debit cards,
                net banking, and UPI. Some payment options may be accessible
                only under certain conditions or for a price. The relevant
                information may be found in the G-Rank Digital Services Private
                Limited website's specialised area.
              </p>
              <h4>Data storage and payment processing</h4>
              <p className="animate__animated animate__fadeInUp">
                Grank manages payment methods identified as such directly. In
                this scenario, Grank collects and maintains the data required
                for payment processing and fulfilling any relevant legal
                obligations. Users may discover more about data processing and
                their data rights by visiting Grank.co.in's privacy policy.
                <br />
                Other payment options, if any, are supplied independently by
                third-party services. G-Rank Digital Services Private Limited
                does not collect payment information in such instances and
                instead receives notification from the appropriate third-party
                supplier after the payment has been completed.
                <br />
                G-Rank Digital Services Private Limited reserves the right to
                fulfill the purchase order if payment via the available options
                fails. Any charges or fees incurred as a consequence of a failed
                or denied payment must be met by the User.
              </p>
              <h4>Payment conditions</h4>
              <p className="animate__animated animate__fadeInUp">
                All bills will be generated and sent to the client's registered
                email address by G-Rank Digital Services Private Limited.
                Payment must be paid within 15 days of the invoice date. Please
                pay within the specified time frame to avoid interruption until
                the outstanding amount is cleared.
              </p>
              <h4>Payment in installments</h4>
              <p className="animate__animated animate__fadeInUp">
                The purchase fee may be paid in two or more installments within
                the deadlines provided on the G-Rank Digital Services Private
                Limited website or otherwise notified by G-Rank Digital Services
                Private Limited. Specific services may be excluded from this
                pricing arrangement. The outstanding amount becomes immediately
                due and payable if the consumer does not meet any payment
                deadlines.By using G-Rank Digital Services Private Limited and
                making a payment through Grank.co.in, the client accepts and
                agrees to the payment conditions outlined above.
              </p>
              <h4>Permitted Use</h4>
              <p className="animate__animated animate__fadeInUp">
                Visitors to the G-Rank Digital Services Private Limited website
                are granted permission to access and download material for
                non-commercial use only. This includes making printed copies or
                storing electronic copies of the downloaded material. Proper
                citations and links to our website should be provided when
                referencing or sharing our content.
              </p>
              <h4>Restricted Use</h4>
              <p className="animate__animated animate__fadeInUp">
                Visitors to our website may not copy, distribute, reproduce,
                sell, publish, or transfer any copyrighted material obtained
                from our website without written permission from G-Rank Digital
                Services Private Limited. Using design trademarks or stylized
                versions of our trademarks is also prohibited without written
                consent.
              </p>
              <h4>Violation Reporting</h4>
              <p className="animate__animated animate__fadeInUp">
                Please notify us if you become aware of any infringements on
                copyright, trademarks, or other intellectual property.
              </p>
              <h4>Renewal</h4>
              <p>
                We provide renewal options to Our customers. All renewals are
                available according to Our package.
              </p>
              <h4 style={{ color: "#555" }}>
                G-Rank Digital Services Private Limited
              </h4>
              <div class="teams-div2">
                <h4>Hyderabad :</h4>
                <p>
                  Ground Floor, Queen's Plaza, 1-8-382, Passport Office Rd,
                  Patigadda, Begumpet, Hyderabad,
                  <br /> Telangana 500003
                </p>
                <h4>Bangalore : </h4>
                <p>
                  Second Floor, R K Lake Tower, Service Road, Subramani Nagar,
                  Guddadahalli, Hebbal,
                  <br /> Bengaluru, Karnataka 560024
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TeamsCondition;
