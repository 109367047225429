// dropdownSlice.js
import { createSlice } from "@reduxjs/toolkit";

const dropdownrediffSlice = createSlice({
  name: "dropdownrediff",
  initialState: {}, // Initial state will store selected options for each product
  reducers: {
    setSelectedOptionrediff: (state, action) => {
      const { productId, selectedOptionrediff } = action.payload;
      state[productId] = selectedOptionrediff;
    },
  },
});

export const { setSelectedOptionrediff } = dropdownrediffSlice.actions;
export default dropdownrediffSlice.reducer;
