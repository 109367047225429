import React, { useEffect, useState } from "react";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { NavLink, useNavigate } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import CardCounter from "../../../features/frontend/addtocart/CartCounter";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const Header = () => {
  const [inputData, setInputData] = useState("");
  const [authuser, SetAuthuser] = useState();
  const [detail, setDetail] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    const isAuthenticated = localStorage.getItem("isAuthenticated");
    const login = localStorage.getItem("loggedUser");
    SetAuthuser(isAuthenticated);
    setDetail(login);
  }, []);

  // -----------------

  const handleClose = () => {
    navigate("/");
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("loggedUser");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Assuming you have predefined pages based on input values
    const pages = {
      home: "/",
      domain: "/domain",
      "domain booking": "/domain-booking",
      "domain available": "/domain-available",
      "domain transfer": "/domain-transfer",

      hosting: "/hosting-service",
      "shared hosting": "/shared-hosting-provider",
      "wordpress hosting": "/wordpress-hosting-services",
      "dedicated hosting": "/dedicated-hosting-services",
      "vps hosting": "/vps-hosting-services",
      security: "/web-security-services",
      "standard ssl": "/standard-ssl",
      "wildcard ssl": "/wildcard-ssl",
      "organization validation ssl": "/organization-validation-ssl",
      "extended validation ssl": "/extented-validation-ssl",
      email: "/email-hosting-provider",
      "google workspace": "/google-workspace-service-provider",
      microsoft: "/microsoft-365-service-provider",
      "rediff mail": "/rediff-mail",
      branding: "/branding-agency",
      "brand consulting": "/brand-consulting-firm",
      "brand strategy": "/brand-strategy-agency",
      "brand positioning": "/brand-positioning-agency",
      "brand identity": "/brand-identity-agency",
      "brand communication": "/brand-communication-agency",
      "performance marketing": "/performance-marketing-company",
      "social media marketing": "/social-media-marketing-agency",
      "search engine marketing": "/search-engine-marketing-agency",
      "organic marketing": "/organic-marketing-agency",
      "search engine optimization": "/search-engine-optimization-agency",
      "social media optimization": "/social-media-optimization-agency",
      "creative content": "/creative-content-agency",
      "display ads": "/display-ads-agency",
      "web design": "/web-design-agency",
      "print design": "/print-design-agency",
      "content marketing": "/content-marketing-agency",
      "social media": "/social-media-agency",
      "organics marketing": "/organic-social-media-agency",
      "paid marketing": "/paid-marketing-agency",
      "web development": "/web-development-agency",
      "wordpress development": "/wordpress-development-agency",
      "ecommerce development": "/ecommerce-development-agency",
      gallery: "/our-gallery",
      "case studies": "/case-studies",
      blog: "/blog",
      careers: "/career",
      b2b: "/b2b-digital-marketing-agency",
      b2c: "/b2c-digital-marketing-agency",
      power: "/power-digital-marketing-agency",
      hardware: "/hardware-digital-marketing-agency",
      healthcare: "/hosting-service",
      automotive: "/automotive-digital-marketing-agency",
      tech: "/tech-digital-marketing-agency",
      manufacture: "/manufacturing-digital-marketing-agency",
      "raw material": "/raw-material-digital-marketing-agency",
      "real estate": "/realestate-digital-marketing-agency",
      ecommerce: "/ecommerce-digital-marketing-agency",
      "interial furniture": "/interior-furniture-digital-marketing-agency",
      education: "/education-digital-marketing-agency",
      lifestyle: "/lifestyle-digital-marketing-agency",
    };

    // Get the corresponding page based on the input value
    const nextPage =
      pages[inputData.toLowerCase("") && inputData.toLowerCase()];

    if (nextPage) {
      // Redirect to the corresponding page
      navigate(nextPage);
    } else {
      // Handle invalid input or show an error message
      alert("Invalid input. Please enter a valid value.");
    }
  };
  return (
    <>
      <div className="pt-bottom-header bgwhite">
        <div className="container-fluid">
          <div className="row pt15 pb10 dno">
            <div className="col-7 col-md-5 col-lg-5 search-div">
              <form className="search-box-form" onSubmit={handleSubmit}>
                <button type="submit" className="login-btn">
                  <IconButton color="primary">
                    <SearchIcon />
                  </IconButton>
                </button>
                <input
                  className="login-inp"
                  type="text"
                  id="inputData"
                  name="inputData"
                  value={inputData}
                  onChange={(e) => setInputData(e.target.value)}
                  required
                  placeholder="search for domain, hosting…"
                />
              </form>
            </div>

            <div className="col-5 col-md-7 col-lg-7 text-rig">
              <div className="row  text-rig">
                <div className="col-12">
                  <ul className="icon-top-ul">
                    <li>
                      <NavLink to="">
                        <img
                          src="assest/photos/google-partner-big.png"
                          alt="G-Rank"
                          className="img-fluid "
                          title="google-partner"
                        />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="">
                        <img
                          src="assest/photos/meta-partner-big.png"
                          alt="G-Rank"
                          className="img-fluid "
                          title="meta partner"
                        />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="">
                        <img
                          src="assest/photos/google-workspace-big.png"
                          alt="G-Rank"
                          className="img-fluid "
                          title="google-workspace"
                        />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="">
                        <img
                          src="assest/photos/google-cloud-big.png"
                          alt="G-Rank"
                          className="img-fluid"
                          title="google-cloud"
                        />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="">
                        <img
                          src="assest/photos/microsoft-logo.png"
                          alt="G-Rank"
                          className="img-fluid "
                          title="microsoft"
                        />
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="">
                        <img
                          src="assest/photos/rediff.jpg"
                          alt="G-Rank"
                          className="img-fluid "
                          title="rediff"
                        />
                      </NavLink>
                    </li>
                    <div className="login-sign-div">
                      <NavLink
                        style={({ isActive }) => ({
                          color: isActive ? "#000" : "#000",
                        })}
                        to="/cart"
                        className=""
                      >
                        <h4 className="addtocard-head">
                          <ShoppingCartIcon color="#000" fontSize="small" />

                          <CardCounter />
                        </h4>
                      </NavLink>

                      {authuser === "true" ? (
                        <NavLink
                          to={"/"}
                          style={({ isActive }) => ({
                            color: isActive ? "#fed501" : "#fff",
                          })}
                          className="sign-up-head2 profile-button"
                        >
                          <img
                            src="assest/photos/b2b/account.png"
                            className="profile-button-img"
                            title="grank"
                            alt="grank"
                          />
                          <div className="profile-div">
                            <h5>G-rank Account</h5>
                            <h4>{detail}</h4>

                            <button onClick={handleClose}>
                              <img
                                src="assest/photos/b2b/power.png"
                                className="profile-button-img"
                                title="grank"
                                alt="grank"
                              />
                              Logout
                            </button>
                          </div>
                        </NavLink>
                      ) : (
                        <NavLink
                          to={"/login"}
                          style={({ isActive }) => ({
                            color: isActive ? "#fed501" : "#fff",
                          })}
                          className="sign-up-head"
                        >
                          SignIn
                        </NavLink>
                      )}
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* header start */}
      <div className="second-menu">
        <div className="container">
          <div className="ruby-menu-demo-header mobile-none">
            <div className="ruby-wrapper">
              <ul className="ruby-menu">
                <li>
                  <NavLink to="#">ABOUT US</NavLink>
                  <ul className="">
                    <li className="arrow-div">
                      <NavLink to="#">Industries</NavLink>
                      <ArrowDropDownIcon
                        className="arrow-div-img"
                        color="#fff"
                      />
                      <ul>
                        <li className="arrow-div2">
                          <NavLink to="/b2b-digital-marketing-agency">
                            B2B
                          </NavLink>
                          <ArrowDropDownIcon
                            className="arrow-div-img2"
                            color="#fff"
                          />
                          <ul>
                            <li>
                              <NavLink to="/power-digital-marketing-agency">
                                power
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/hardware-digital-marketing-agency">
                                HardWare
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/healthcare-digital-marketing-agency">
                                Healthcare
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/automotive-digital-marketing-agency">
                                automotive
                              </NavLink>
                            </li>

                            <li>
                              <NavLink to="/tech-digital-marketing-agency">
                                tech
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/manufacturing-digital-marketing-agency">
                                Manufacturing
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/raw-material-digital-marketing-agency">
                                raw material
                              </NavLink>
                            </li>
                          </ul>
                          <span className="ruby-dropdown-toggle" />
                        </li>

                        <li className="arrow-div3">
                          <NavLink to="/b2c-digital-marketing-agency">
                            B2C
                          </NavLink>
                          <ArrowDropDownIcon
                            className="arrow-div-img3"
                            color="#fff"
                          />
                          <ul>
                            <li>
                              <NavLink to="/realestate-digital-marketing-agency">
                                Real-States
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/healthcare-digital-marketing-agency">
                                Healthcare
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/ecommerce-digital-marketing-agency">
                                E-Commerce
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/lifestyle-digital-marketing-agency">
                                Lifestyle
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/interior-furniture-digital-marketing-agency">
                                Interiors & Furniture
                              </NavLink>
                            </li>
                            <li>
                              <NavLink to="/education-digital-marketing-agency">
                                Education
                              </NavLink>
                            </li>
                          </ul>
                          <span className="ruby-dropdown-toggle" />
                        </li>
                      </ul>
                      <span className="ruby-dropdown-toggle" />
                    </li>
                    <li>
                      <NavLink to="/case-studies">case studies</NavLink>
                    </li>
                    <li>
                      <NavLink to="/blog">blog</NavLink>
                    </li>
                    <li>
                      <NavLink to="/career">career</NavLink>
                    </li>
                  </ul>
                  <span className="ruby-dropdown-toggle" />
                </li>
                <li>
                  <NavLink to="/domain">Domain</NavLink>
                  {/* <ul className="">
                    <li>
                      <NavLink to="/domain-available">Domain Available</NavLink>
                    </li>
                    <li>
                      <NavLink to="/domain-transfer">Domain Transfer</NavLink>
                    </li>
                    <li>
                      <NavLink to="/domain-booking">Domain Booking</NavLink>
                    </li>
                  </ul> */}
                  <span className="ruby-dropdown-toggle" />
                </li>
                <li>
                  <NavLink to="/hosting-service">Hosting</NavLink>
                  <ul className="">
                    <li>
                      <NavLink to="/shared-hosting-provider">
                        Shared Hosting
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/wordpress-hosting-services">
                        WordPress Hosting
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/dedicated-hosting-services">
                        Dedicated Hosting
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/vps-hosting-services">VPS Hosting</NavLink>
                    </li>
                  </ul>
                  <span className="ruby-dropdown-toggle" />
                </li>
                <li>
                  <NavLink to="/web-security-services">Security</NavLink>
                  <ul className="">
                    <li>
                      <NavLink to="/standard-ssl">Standard SSL</NavLink>
                    </li>

                    <li>
                      <NavLink to="/organization-validation-ssl">
                        Organization Validation SSL
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to="/extented-validation-ssl">
                        Extented Validation SSL
                      </NavLink>
                    </li>
                  </ul>
                  <span className="ruby-dropdown-toggle" />
                </li>
                <li>
                  <NavLink to="/email-hosting-provider">Email</NavLink>
                  <ul className="">
                    <li>
                      <NavLink to="/google-workspace-service-provider">
                        Google Workspace
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/microsoft-365-service-provider">
                        Microsoft office 365
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/rediff-mail">Rediff Mail</NavLink>
                    </li>
                  </ul>
                  <span className="ruby-dropdown-toggle" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
