import React, { useState, useEffect } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
//import AddIcon from "@mui/icons-material/Add";
//import RemoveIcon from "@mui/icons-material/Remove";
//import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
//import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Seo from "../seo/Seo";
import { NavLink, useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import CustomerContext from "../blog-folder/CustomerContext";
import { loadProducts } from "../../../features/frontend/addtocartmicro/thunk-productmicro";
import AddToCartMicro from "../../../features/frontend/addtocartmicro/AddToCartMicro";
const Microsoft = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const [inputData, setInputData] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProducts());
  }, [dispatch]);
  // useState section start--------------------------------------

  // acoordian button
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);

  //  useState section end----------------------------------------
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings4 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // Slider section start-----------------------------------------
  // function SampleNextArrow(props) {
  //   const { onClick } = props;
  //   return (
  //     <div className="security-slider-custome-button-next" onClick={onClick}>
  //       <KeyboardArrowRightIcon className="forword-arrow" />
  //     </div>
  //   );
  // }

  // function SamplePrevArrow(props) {
  //   const { onClick } = props;
  //   return (
  //     <div className="security-slider-custome-button-prev" onClick={onClick}>
  //       <KeyboardArrowLeftIcon className="backword-arrow" />
  //     </div>
  //   );
  // }
  // Slider section start-----------------------------------------

  // -------------------
  const handleSubmit = (event) => {
    event.preventDefault();

    // Assuming you have predefined pages based on input values
    const pages = {
      home: "/",
      domain: "/domain",
      "domain booking": "/domain-booking",
      "domain available": "/domain-available",
      "domain transfer": "/domain-transfer",

      hosting: "/hosting-service",
      "shared hosting": "/shared-hosting-provider",
      "wordpress hosting": "/wordpress-hosting-services",
      "dedicated hosting": "/dedicated-hosting-services",
      "vps hosting": "/vps-hosting-services",
      security: "/web-security-services",
      "standard ssl": "/standard-ssl",
      "wildcard ssl": "/wildcard-ssl",
      "organization validation ssl": "/organization-validation-ssl",
      "extended validation ssl": "/extented-validation-ssl",
      email: "/email-hosting-provider",
      "google workspace": "/google-workspace-service-provider",
      microsoft: "/microsoft-365-service-provider",
      "rediff mail": "/rediff-mail",
      branding: "/branding-agency",
      "brand consulting": "/brand-consulting-firm",
      "brand strategy": "/brand-strategy-agency",
      "brand positioning": "/brand-positioning-agency",
      "brand identity": "/brand-identity-agency",
      "brand communication": "/brand-communication-agency",
      "performance marketing": "/performance-marketing-company",
      "social media marketing": "/social-media-marketing-agency",
      "search engine marketing": "/search-engine-marketing-agency",
      "organic marketing": "/organic-marketing-agency",
      "search engine optimization": "/search-engine-optimization-agency",
      "social media optimization": "/social-media-optimization-agency",
      "creative content": "/creative-content-agency",
      "display ads": "/display-ads-agency",
      "web design": "/web-design-agency",
      "print design": "/print-design-agency",
      "content marketing": "/content-marketing-agency",
      "social media": "/social-media-agency",
      "organics marketing": "/organic-social-media-agency",
      "paid marketing": "/paid-marketing-agency",
      "web development": "/web-development-agency",
      "wordpress development": "/wordpress-development-agency",
      "ecommerce development": "/ecommerce-development-agency",
      gallery: "/our-gallery",
      "case studies": "/case-studies",
      blog: "/blog",
      careers: "/career",
      b2b: "/b2b-digital-marketing-agency",
      b2c: "/b2c-digital-marketing-agency",
      power: "/power-digital-marketing-agency",
      hardware: "/hardware-digital-marketing-agency",
      healthcare: "/hosting-service",
      automotive: "/automotive-digital-marketing-agency",
      tech: "/tech-digital-marketing-agency",
      manufacture: "/manufacturing-digital-marketing-agency",
      "raw material": "/raw-material-digital-marketing-agency",
      "real estate": "/realestate-digital-marketing-agency",
      ecommerce: "/ecommerce-digital-marketing-agency",
      "interial furniture": "/interior-furniture-digital-marketing-agency",
      education: "/education-digital-marketing-agency",
      lifestyle: "/lifestyle-digital-marketing-agency",
    };

    // Get the corresponding page based on the input value
    const nextPage =
      pages[inputData.toLowerCase("") && inputData.toLowerCase()];

    if (nextPage) {
      // Redirect to the corresponding page
      navigate(nextPage);
    } else {
      // Handle invalid input or show an error message
      alert("Invalid input. Please enter a valid value.");
    }
  };

  const schema = {
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What is included with Microsoft 365?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Microsoft 365 comprises top-tier Office apps (Word, Excel, Teams, etc.), intelligent cloud services, and industry-leading secure email through Outlook",
        },
      },
      {
        "@type": "Question",
        name: "What happened to Office 365 plans?",
        acceptedAnswer: {
          "@type": "Answer",
          text: " Office 365 plans have transitioned into Microsoft 365 business plans. This change involves a new name but retains all the features, including Office apps, cloud services, and security. If you have an existing Office 365 business subscription, it will be automatically renamed to Microsoft 365, with no required action from you. The new name will appear in your Admin Portal and billing statements.",
        },
      },
      {
        "@type": "Question",
        name: "Why should I buy Microsoft 365 from G-Rank if I already have Office apps on my desktop?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "By choosing Microsoft 365 from G-Rank, you receive the latest Office apps with automatic updates. This plan enables seamless document sharing through OneDrive, collaboration via Online Office apps, domain-based email creation, calendar sharing, and contact sharing. Additionally, you gain real-time online meetings and screen-sharing capabilities through Skype for Business (PC) and Lync (Mac). This enhances productivity and collaboration beyond just Office apps on your desktop",
        },
      },
    ],
  };
  return (
    <>
      <Seo
        title="Microsoft 365 Service Provider in Bangalore | Hyderabad"
        description="Microsoft 365 service provider in Bangalore and hyderabad. Explore our suite of versatile apps and cloud-based services designed to empower your work, enhance collaboration, and secure your business. "
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="microsoft 365 service provider in bangalore,
        microsoft 365 service provider in India"
        searchengines="ALL"
        canonical="https://www.grank.co.in/microsoft-365-service-provider"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/email/email-banner2.png"
            alt="G-Rank"
            title="grank"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/email/email-banner2-mobile.png"
            alt="G-Rank"
            title="grank"
          />

          <div className="email-banner-div1">
            <motion.h1
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Microsoft 365 <br />
              Service Provider
            </motion.h1>
            <motion.h2
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Upgrade Your Workspace with Microsoft 365 
            </motion.h2>

            <motion.h3
              href="#"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">Find Out More</NavLink>
            </motion.h3>
            <Breadcrumbs
              className="breadcrumbs"
              aria-label="breadcrumb"
              style={{ paddingTop: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/email-hosting-provider"
              >
                <p>Email</p>
              </NavLink>

              <Typography color="#fff">Microsoft</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}
        {/* second section start */}
        <div>
          <div className="whychoose-sec">
            <div className="container-fluid">
              <h4 className="email-sub-head">Benefits of Microsoft 365 </h4>
              <Slider {...settings4}>
                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">Security</h2>
                    <p>
                      Microsoft employs robust security measures, including data
                      encryption, multi-factor authentication, and advanced
                      threat protection, enhancing the security of your
                      documents and communication.
                    </p>
                  </div>
                </div>

                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">
                      OneDrive <br />
                      Integration
                    </h2>
                    <p>
                      OneDrive, Microsoft's cloud storage solution, is
                      integrated into Office 365, enabling seamless file
                      storage, sharing, and synchronization across devices.
                    </p>
                  </div>
                </div>

                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">
                      Access to All <br />
                      Devices
                    </h2>
                    <p>
                      Office 365 applications are available on various
                      platforms, including Windows, macOS, iOS, and Android,
                      ensuring consistent user experiences across devices. 
                    </p>
                  </div>
                </div>

                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">
                      Integrated <br />
                      Communication
                    </h2>
                    <p>
                      Microsoft Teams, integrated into Office 365, offers chat,
                      video conferencing, and collaboration features in one
                      platform, streamlining communication. 
                    </p>
                  </div>
                </div>

                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">
                      Advanced
                      <br /> Analytics
                    </h2>
                    <p>
                      Office 365 applications offer built-in analytics and
                      reporting features, helping users gain insights from their
                      data. 
                    </p>
                  </div>
                </div>
                <div className="slider-div9">
                  <div className="op">
                    <h2 className="slider-img9">Compatibility</h2>
                    <p>
                      Office 365 maintains compatibility with previous versions
                      of Microsoft Office applications, enabling smoother
                      transitions and collaboration across different software
                      versions.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        {/* second section end */}
        {/* logo section start */}
        <section>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h4 className="email-sub-head">
                  Microsoft 365 Services & Plans
                </h4>
              </div>
              <div className="col-12">
                <div className="row">
                  <AddToCartMicro />
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* logo section end */}
        {/* action yellow start*/}
        <div className="action-yellow-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Want to Elevate Your Brand?
            <br /> Get in Touch.
          </motion.p>
          <NavLink to="">
            <motion.p
              className="action-yellow-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Contact Us <ArrowRightAltIcon />
            </motion.p>
          </NavLink>
        </div>
        {/* action yellow end*/}

        {/* FAQs section start*/}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <motion.h4
                  className="security-circle-heading"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  FAQ's
                </motion.h4>
              </div>
              <div className="col-md-12">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          onClick={() => setOpen1(!open1)}
                        >
                          What is included with Microsoft 365?
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Microsoft 365 comprises top-tier Office apps (Word,
                          Excel, Teams, etc.), intelligent cloud services, and
                          industry-leading secure email through Outlook.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={() => setOpen2(!open2)}
                        >
                          What happened to Office 365 plans?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse "
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Office 365 plans have transitioned into Microsoft 365
                          business plans. This change involves a new name but
                          retains all the features, including Office apps, cloud
                          services, and security. If you have an existing Office
                          365 business subscription, it will be automatically
                          renamed to Microsoft 365, with no required action from
                          you. The new name will appear in your Admin Portal and
                          billing statements.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => setOpen2(!open2)}
                        >
                          Why should I buy Microsoft 365 from G-Rank if I
                          already have Office apps on my desktop?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse "
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          By choosing Microsoft 365 from G-Rank, you receive the
                          latest Office apps with automatic updates. This plan
                          enables seamless document sharing through OneDrive,
                          collaboration via Online Office apps, domain-based
                          email creation, calendar sharing, and contact sharing.
                          Additionally, you gain real-time online meetings and
                          screen-sharing capabilities through Skype for Business
                          (PC) and Lync (Mac). This enhances productivity and
                          collaboration beyond just Office apps on your desktop.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQs section end*/}
        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div className="col-12">
              <motion.h4
                className="web-develop-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Quick Links For You
              </motion.h4>
            </div>
            <div>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        {/*Input start */}
        <section>
          <div className="performance-footerimg-div">
            <img
              className="img-fluid w-100"
              src="assest/photos/security/white-and-blue-back.png"
              alt="G-Rank"
              title="grank"
            />
            <div className="performance-footer-div-text">
              <h4>Learn more about our sevices</h4>
              <form className="searchArea" onSubmit={handleSubmit}>
                <button type="submit" tabindex="2">
                  Explore
                </button>
                <div className="inputDiv">
                  <input
                    type="text"
                    id="inputData"
                    name="inputData"
                    value={inputData}
                    onChange={(e) => setInputData(e.target.value)}
                    requiredtabindex="1"
                  />
                </div>
              </form>
            </div>
          </div>
        </section>
        {/*Input start */}
      </section>
    </>
  );
};

export default Microsoft;
