import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectProductsgoogle,
  selectCardgoogle,
  addgoogleCard,
} from "../../../app/slices/shoppinggoogleSlice";
import CustomerContext from "../../../layout/blanklayout/blog-folder/CustomerContext";

const Product = ({
  _id,
  name,
  price,
  description,
  description2,
  description3,
  description4,
  description5,
  description10,
}) => {
  const dispatch = useDispatch();
  const { handleOpen } = React.useContext(CustomerContext);
  const cardgoogle = useSelector(selectCardgoogle);
  let message = "Add To Cart";
  if (cardgoogle?.productsgoogle?.some((pro) => pro.id == _id))
    message = "Added To Cart";

  return (
    <div className="col-12 col-md-3 col-lg-3  email-sub-logo-div2">
      <h5>{name}</h5>
      <div className="email-sub-logo-div">
        {description10 == "" ? (
          <p style={{ height: "6%" }}> </p>
        ) : (
          <h3>₹{description10} </h3>
        )}
        {price == "00" ? (
          <span className="contact-google text-align-center">
            contact G-Rank for pricing
          </span>
        ) : (
          <h4>
            <img
              className="img-fluid "
              src="assest/photos/email/rupee.png"
              alt="G-Rank"
              title="Email"
            />
            {price}
            <span>INR</span>
          </h4>
        )}
        <h2>
          per user/month, one-year
          <br /> commitment
        </h2>
        {price == "00" ? (
          <h6 onClick={handleOpen}>Contact G-Rank</h6>
        ) : (
          <h6
            onClick={() =>
              sessionStorage.setItem("product24", _id) |
              dispatch(addgoogleCard({ id: _id }))
            }
          >
            {message}
          </h6>
        )}
        <p>
          <img
            className="img-fluid "
            src="assest/photos/email/check.png"
            alt="G-Rank"
            title="Email"
          />
          {description}
        </p>
        <p>
          <img
            className="img-fluid "
            src="assest/photos/email/check.png"
            alt="G-Rank"
            title="Email"
          />
          {description2}
        </p>
        <p>
          <img
            className="img-fluid "
            src="assest/photos/email/check.png"
            alt="G-Rank"
            title="Email"
          />
          {description3}
        </p>
        <p>
          <img
            className="img-fluid "
            src="assest/photos/email/check.png"
            alt="G-Rank"
            title="Email"
          />

          {description4}
        </p>
        <p>
          <img
            className="img-fluid "
            src="assest/photos/email/check.png"
            alt="G-Rank"
            title="Email"
          />
          {description5}
        </p>
      </div>
    </div>
  );
};
const AddToCartGoogles = () => {
  const productsgoogle = useSelector(selectProductsgoogle);

  return (
    <>
      {Array.isArray(productsgoogle) &&
        productsgoogle.map((prod, i) => <Product key={i} {...prod} />)}
    </>
  );
};

export default AddToCartGoogles;
