import React from "react";
import ReactPlayer from "react-player";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

function Player({ isPlaying, setIsPlaying }) {
  const handleisPlaying = () => {
    console.log("before playing?", isPlaying);
    setIsPlaying(true);

    console.log("after playing?", isPlaying);
  };
  const handleisPlaying2 = () => {
    console.log("before playing?", isPlaying);
    setIsPlaying(false);
    console.log("after playing?", isPlaying);
  };
  return (
    <div className="al-video">
      <ReactPlayer
        url="assest/videos/g-rank-web-banner-4.0-ai.mp4"
        playing={isPlaying}
        autoPlay={true}
        controls={false}
        width="1200"
        height="auto"
        // ref={playerRef}
      />
      <div className="al-video-div">
        <button
          type="button"
          className="btn al-video-button"
          onClick={handleisPlaying}
        >
          <PlayArrowIcon />
        </button>
        <button
          type="button"
          className="btn al-video-button"
          onClick={handleisPlaying2}
        >
          <PauseIcon />
        </button>
      </div>
    </div>
  );
}

export default Player;
