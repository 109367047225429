import React, { useState } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { NavLink } from "react-router-dom";
import Seo from "../seo/Seo";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";

const DomainAvailable = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // FAQs section start
  // useState section start--------------------------------------

  // acoordian button
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);

  //  useState section end----------------------------------------

  const schema = {
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "How do I check the availability of a domain on G Rank?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "To check domain availability, simply enter your desired name in the search bar. G Rank will instantly inform you if the domain is available or suggest alternative options.",
        },
      },
      {
        "@type": "Question",
        name: "What should I do if the domain I want is already taken?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "If your preferred domain is taken, G Rank provides creative suggestions and variations. You can explore alternative options that closely align with your brand and business.",
        },
      },
      {
        "@type": "Question",
        name: "Can I reserve a domain that's currently unavailable?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Unfortunately, you can't reserve an unavailable domain. However, G Rank can notify you if it becomes available in the future, helping you secure it at the right time.",
        },
      },
      {
        "@type": "Question",
        name: "Are there any restrictions on domain name length or characters?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "G Rank follows standard domain naming conventions. Keep your domain between 3 and 63 characters, and use letters, numbers, and hyphens. Ensure it reflects your brand and is easy to remember.",
        },
      },
      {
        "@type": "Question",
        name: "How quickly can I register an available domain on G Rank?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Registering an available domain on G Rank is a quick process. Once you find the perfect domain, follow our user-friendly registration steps, and your website can be up and running in no time.",
        },
      },
    ],
  };

  return (
    <>
      <Seo
        title="Search Domain Availability | Bangalore | Hyderabad"
        description="Discover your digital possibilities with available domains. Explore our Domain Available section to find the perfect web address for your project. "
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="domain availability,
        check domain availability,
        Top domain name availability service in India,
        domain name search availability,
        check website name availability"
        searchengines="ALL"
        canonical="https://www.grank.co.in/domain-available"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/domain/domain-availablility.png"
            alt="G-Rank"
            title="domain"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/domain/domain-availablility-mobile.png"
            alt="G-Rank"
            title="domain"
          />

          <div className="domain-banner-div">
            <motion.h1
              style={{ color: "#ffff" }}
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Domain Availability
            </motion.h1>
            <motion.h5
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Check Domain Availability Today!
            </motion.h5>
            <Breadcrumbs
              className=" breadcrumbs"
              aria-label="breadcrumb"
              style={{ float: "right", paddingTop: "140px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/domain"
              >
                <p>Domain</p>
              </NavLink>

              <Typography color="#fff">Domain Available</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}
        {/* yellow section start */}

        <section className="yellow-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h4 className="yellow-section-heading">
                  Choose From The Most Popular
                  <br /> Domain Extensions
                </h4>
              </div>
              <div className="col-12 col-md-4 col-lg-4 ">
                <div className="yellow-section-div">
                  <h5>.com</h5>
                  <p>
                    Recommended extension for technology <br />
                    related projects.
                  </p>
                  <h2>₹ 999.00</h2>
                  <h3>
                    ₹ 199.00<span>/yr + gst</span>
                  </h3>
                  <h4>
                    *1st year with a 2 or more
                    <br /> years registration
                  </h4>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4 ">
                <div className="yellow-section-div">
                  <h5>.com</h5>
                  <p>
                    Recommended extension for technology <br />
                    related projects.
                  </p>
                  <h2>₹ 999.00</h2>
                  <h3>
                    ₹ 199.00<span>/yr + gst</span>
                  </h3>
                  <h4>
                    *1st year with a 2 or more
                    <br /> years registration
                  </h4>
                </div>
              </div>
              <div className="col-12 col-md-4 col-lg-4 ">
                <div className="yellow-section-div">
                  <h5>.com</h5>
                  <p>
                    Recommended extension for technology <br />
                    related projects.
                  </p>
                  <h2>₹ 999.00</h2>
                  <h3>
                    ₹ 199.00<span>/yr + gst</span>
                  </h3>
                  <h4>
                    *1st year with a 2 or more
                    <br /> years registration
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* yellow section end */}
        {/* domain plan start */}
        <section className="domain-plan-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <motion.h4
                  className="domain-slider-heading"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  Give Your Domain A Boost
                </motion.h4>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <motion.div
                  className="domain-plan-div"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1 }}
                >
                  <h2>Hosting</h2>
                  <p>
                    Add secure and reliable hosting to your domain through our
                    affordable plans
                  </p>
                  <h5>Starting at</h5>
                  <h4>
                    Rs. 357.71<span>/mo</span>
                  </h4>
                  <h3>
                    <span>Add To Cart</span>
                  </h3>
                </motion.div>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <motion.div
                  className="domain-plan-div"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.5 }}
                >
                  <h2>SSL Protection</h2>
                  <p>
                    Choose from top SSL certificates to secure your site data.
                  </p>
                  <h5>Starting at</h5>
                  <h4>
                    Rs. 798.45<span>/mo</span>
                  </h4>
                  <h3>
                    <span>Add To Cart</span>
                  </h3>
                </motion.div>
              </div>
              <div className="col-12 col-md-4 col-lg-4">
                <motion.div
                  className="domain-plan-div"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 2 }}
                >
                  <h2>Business Emails</h2>
                  <p>
                    Get fast, inituitive business or personal email that's
                    linked to your domain.
                  </p>
                  <h5>Starting at</h5>
                  <h4>
                    Rs. 210.00<span>/mo</span>
                  </h4>
                  <h3>
                    <span>Add To Cart</span>
                  </h3>
                </motion.div>
              </div>
            </div>
          </div>
        </section>
        {/* domain plan end*/}

        {/* FAQs section start*/}
        <section className="FAQs-heading-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <h4>Frequently Asked Questions</h4>
              </div>
            </div>
          </div>
        </section>
        <section className="domain-extention-FAQs-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          onClick={() => setOpen1(!open1)}
                        >
                          How do I check the availability of a domain on G Rank?
                          <span>
                            {open1 ? (
                              <AddIcon className="accordion-arrow" />
                            ) : (
                              <RemoveIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          To check domain availability, simply enter your
                          desired name in the search bar. G Rank will instantly
                          inform you if the domain is available or suggest
                          alternative options.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={() => setOpen2(!open2)}
                        >
                          Can I reserve a Domain Name without Purchasing it?
                          <span>
                            {open2 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse show"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Generally, you cannot reserve a Domain Name without
                          purchasing it. Domain Names are typically registered
                          on a first-come, first-served basis. Once someone
                          registers a domain, it's not available for others to
                          reserve or use
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading3">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse3"
                          aria-expanded="false"
                          aria-controls="collapse3"
                          onClick={() => setOpen3(!open3)}
                        >
                          Can I reserve a domain that's currently unavailable?
                          <span>
                            {open3 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse3"
                      className="collapse show"
                      aria-labelledby="heading3"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Unfortunately, you can't reserve an unavailable
                          domain. However, G Rank can notify you if it becomes
                          available in the future, helping you secure it at the
                          right time.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse4"
                          aria-expanded="false"
                          aria-controls="collapse4"
                          onClick={() => setOpen4(!open4)}
                        >
                          Are there any restrictions on domain name length or
                          characters?
                          <span>
                            {open4 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse4"
                      className="collapse show"
                      aria-labelledby="heading4"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          G Rank follows standard domain naming conventions.
                          Keep your domain between 3 and 63 characters, and use
                          letters, numbers, and hyphens. Ensure it reflects your
                          brand and is easy to remember.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading5">
                      <h2 className="mb-0">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse5"
                          aria-expanded="false"
                          aria-controls="collapse5"
                          onClick={() => setOpen5(!open5)}
                        >
                          How quickly can I register an available domain on G
                          Rank?
                          <span>
                            {open5 ? (
                              <RemoveIcon className="accordion-arrow" />
                            ) : (
                              <AddIcon className="accordion-arrow" />
                            )}
                          </span>
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse5"
                      className="collapse show"
                      aria-labelledby="heading5"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Registering an available domain on G Rank is a quick
                          process. Once you find the perfect domain, follow our
                          user-friendly registration steps, and your website can
                          be up and running in no time.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQs section end*/}

        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div className="col-12">
              <motion.h4
                className="web-develop-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Quick Links For You
              </motion.h4>
            </div>
            <div>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        {/* Black-section-div start*/}
        <section>
          <div className="footerimg-div">
            <img
              className="img-fluid w-100"
              src="assest/photos/domain/white-and-blue-back.png"
              alt="G-Rank"
              title="domain"
            />
            <div className="footer-div-text">
              <motion.p
                initial={{ opacity: 0, scale: 0, y: 100 }}
                whileInView={{ opacity: 1, scale: 1, y: 0 }}
                transition={{ duration: 0.6 }}
              >
                Want to Elevate Your Brand?
                <br /> Get in Touch.
              </motion.p>
              <NavLink to="">
                <motion.p
                  className="footer-div-buttom"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                  onClick={handleOpen}
                >
                  Contact Us <ArrowRightAltIcon />
                </motion.p>
              </NavLink>
            </div>
          </div>
        </section>
        {/* Black-section-div end*/}
      </section>
    </>
  );
};

export default DomainAvailable;
