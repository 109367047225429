import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Paper } from "@mui/material";
import Header from "../../../layout/blanklayout/header2/Header";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const theme = createTheme();

const Login = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    password: "",
  });
  const [users, setUsers] = useState([]);

  // axios.defaults.withCredentials = true;

  useEffect(() => {
    axios
      .get("https://servers.myvidya.co.in/api/v1/users")
      .then((res) => {
        setUsers(res?.data);
      })
      .catch((err) => {});
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(
        "https://servers.myvidya.co.in/login",

        values
      );

      if (response.data.Status === "Success") {
        // Set authentication status in local storage
        localStorage.setItem("isAuthenticated", "true");
        // Assuming the user's email is unique, find the user by email
        const loggedInUser = users.find((user) => user.email === values.email);
        const login = [loggedInUser.email];

        localStorage.setItem("loggedUser", login);
        // Check if the user was found and if roles are defined and an array
        if (loggedInUser) {
          // Check user roles and navigate accordingly
          if (loggedInUser.role === "admin") {
            navigate("/secured");
            alert("Login Success");
          } else {
            // navigate(-1);
            navigate("/cart");
            alert("Login Success");
          }
        } else {
          navigate("/login");
          alert("Login failed. User not found or roles undefined");
        }
      } else {
        navigate("/login");
        alert("Login failed");
      }
    } catch (error) {
      // console.error("Error during login:", error);
      alert(`An error occurred during login: ${error.message}`);
    }
  };

  return (
    <>
      <section className="section-top">
        <Header />
        <ThemeProvider theme={theme}>
          <Box className="sign-section">
            <Container
              fixed
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <CssBaseline />
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                }}
              >
                <Grid item xs={12} md={7} xl={7} sx={{ alignSelf: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="assest/photos/login-img23.png"
                      alt="G-Rank"
                      className="img-fluid  sign-img"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5} xl={5}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Paper
                      className=""
                      sx={{
                        px: 4,
                        py: 8,
                        my: 8,
                        backgroundColor: "#f9f9f9",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="h1"
                          variant="h5"
                          className="sign-in-text"
                        >
                          Sign In
                        </Typography>
                      </Box>
                      <Box
                        component="form"
                        onSubmit={handleSubmit}
                        noValidate
                        sx={{ mt: 1 }}
                      >
                        <TextField
                          margin="normal"
                          type="email"
                          fullWidth
                          label="Email Address"
                          name="email"
                          value={values.email}
                          onChange={(e) =>
                            setValues({ ...values, email: e.target.value })
                          }
                          autoFocus
                        />
                        <TextField
                          margin="normal"
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          value={values.password}
                          onChange={(e) =>
                            setValues({ ...values, password: e.target.value })
                          }
                          autoComplete="current-password"
                        />
                        <FormControlLabel
                          control={
                            <Checkbox value="remember" color="primary" />
                          }
                          label="Remember me"
                        />
                        <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                        >
                          Sign In
                        </Button>
                        <Grid container>
                          <Grid item xs>
                            {/* <Link href="#" variant="body2">
                              Forgot password?
                            </Link> */}
                          </Grid>
                          <Grid item>
                            <Link
                              href="/register"
                              variant="body2"
                              color={"#1976d2"}
                            >
                              {"Don't have an account? Sign Up"}
                            </Link>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </ThemeProvider>
      </section>
    </>
  );
};

export default Login;
