import React, { useState } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import { NavLink, useNavigate } from "react-router-dom";
import Seo from "../seo/Seo";
//import AddToCart from "../../../features/frontend/addtocart/AddToCart";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";

const SharedHosting = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const [inputData, setInputData] = useState("");
  const navigate = useNavigate();

  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // -------------------
  const handleSubmit = (event) => {
    event.preventDefault();

    // Assuming you have predefined pages based on input values
    const pages = {
      home: "/",
      domain: "/domain",
      "domain booking": "/domain-booking",
      "domain available": "/domain-available",
      "domain transfer": "/domain-transfer",

      hosting: "/hosting-service",
      "shared hosting": "/shared-hosting-provider",
      "wordpress hosting": "/wordpress-hosting-services",
      "dedicated hosting": "/dedicated-hosting-services",
      "vps hosting": "/vps-hosting-services",
      security: "/web-security-services",
      "standard ssl": "/standard-ssl",
      "wildcard ssl": "/wildcard-ssl",
      "organization validation ssl": "/organization-validation-ssl",
      "extended validation ssl": "/extented-validation-ssl",
      email: "/email-hosting-provider",
      "google workspace": "/google-workspace-service-provider",
      microsoft: "/microsoft-365-service-provider",
      "rediff mail": "/rediff-mail",
      branding: "/branding-agency",
      "brand consulting": "/brand-consulting-firm",
      "brand strategy": "/brand-strategy-agency",
      "brand positioning": "/brand-positioning-agency",
      "brand identity": "/brand-identity-agency",
      "brand communication": "/brand-communication-agency",
      "performance marketing": "/performance-marketing-company",
      "social media marketing": "/social-media-marketing-agency",
      "search engine marketing": "/search-engine-marketing-agency",
      "organic marketing": "/organic-marketing-agency",
      "search engine optimization": "/search-engine-optimization-agency",
      "social media optimization": "/social-media-optimization-agency",
      "creative content": "/creative-content-agency",
      "display ads": "/display-ads-agency",
      "web design": "/web-design-agency",
      "print design": "/print-design-agency",
      "content marketing": "/content-marketing-agency",
      "social media": "/social-media-agency",
      "organics marketing": "/organic-social-media-agency",
      "paid marketing": "/paid-marketing-agency",
      "web development": "/web-development-agency",
      "wordpress development": "/wordpress-development-agency",
      "ecommerce development": "/ecommerce-development-agency",
      gallery: "/our-gallery",
      "case studies": "/case-studies",
      blog: "/blog",
      careers: "/career",
      b2b: "/b2b-digital-marketing-agency",
      b2c: "/b2c-digital-marketing-agency",
      power: "/power-digital-marketing-agency",
      hardware: "/hardware-digital-marketing-agency",
      healthcare: "/hosting-service",
      automotive: "/automotive-digital-marketing-agency",
      tech: "/tech-digital-marketing-agency",
      manufacture: "/manufacturing-digital-marketing-agency",
      "raw material": "/raw-material-digital-marketing-agency",
      "real estate": "/realestate-digital-marketing-agency",
      ecommerce: "/ecommerce-digital-marketing-agency",
      "interial furniture": "/interior-furniture-digital-marketing-agency",
      education: "/education-digital-marketing-agency",
      lifestyle: "/lifestyle-digital-marketing-agency",
    };

    // Get the corresponding page based on the input value
    const nextPage =
      pages[inputData.toLowerCase("") && inputData.toLowerCase()];

    if (nextPage) {
      // Redirect to the corresponding page
      navigate(nextPage);
    } else {
      // Handle invalid input or show an error message
      alert("Invalid input. Please enter a valid value.");
    }
  };
  return (
    <>
      <Seo
        title="Shared Hosting Services in Bangalore | Hyderabad"
        description="Shared hosting services in bangalore and hyderabad. Explore our Shared Hosting services and unlock reliable and affordable web hosting for your website."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="best shared hosting,
        best shared web hosting service in india,
        best shared web hosting ,
        shared website hosting,
        premium shared hosting"
        searchengines="ALL"
        canonical="https://www.grank.co.in/shared-hosting-provider"
      ></Seo>
      <section className="section-top">
        <Header />
        <div>
          <div className="hosting-banner">
            <div className="row">
              <div className="col-12 col-md-5 col-lg-5" id="order2">
                <div className="exp-hosting">
                  <motion.h1
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    Shared Hosting​ Provider
                  </motion.h1>

                  <motion.p
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    If you are looking for an reliable shared hosting provider
                    for optimal web performance, look no further!
                  </motion.p>

                  <motion.h3
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.6 }}
                    onClick={handleOpen}
                  >
                    <NavLink to="">Explore</NavLink>
                  </motion.h3>
                </div>
                <Breadcrumbs
                  className="hosting-breadcumbs breadcrumbs"
                  aria-label="breadcrumb"
                >
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/"
                  >
                    <p>Home</p>
                  </NavLink>
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/hosting-service"
                  >
                    <p>Hosting</p>
                  </NavLink>

                  <Typography color="#000">Shared Hosting</Typography>
                </Breadcrumbs>
              </div>
              <div className="col-12 col-md-7 col-lg-7" id="order1">
                <img
                  className="img-fluid w-100"
                  src="assest/photos/hosting/hosting-banner2.png"
                  alt="grank"
                  title="grank"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="explore-plans">
            <motion.h2
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
            >
              Explore the shared hosting plans!
            </motion.h2>

            <motion.h3
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">Click Here</NavLink>
            </motion.h3>
          </div>
        </div>

        <div>
          <div className="features-sec2">
            <div className="container">
              <div className="titleh2 mt60">
                <h2>Benefits</h2>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="whychoose-sec">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-md-4 col-lg-4 slider-div5">
                  <div className="op">
                    <img
                      src="assest/photos/hosting/shared-hosting-icon1.png"
                      alt="grank"
                      title="grank"
                    />
                    <h2 className="slider-img5">Cost-Effective</h2>
                    <p>Affordable option for small budgets.</p>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 slider-div5">
                  <div className="op">
                    <img
                      src="assest/photos/hosting/shared-hosting-icon3.png"
                      alt="grank"
                      title="grank"
                    />
                    <h2 className="slider-img5">Ease of Use</h2>
                    <p>
                      User-friendly interfaces and control <br />
                      panels.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-4 col-lg-4 slider-div5">
                  <div className="op">
                    <img
                      src="assest/photos/hosting/shared-hosting-icon5.png"
                      alt="grank"
                      title="grank"
                    />
                    <h2 className="slider-img5">Maintenance</h2>
                    <p>
                      Provider handles server upkeep
                      <br /> and updates.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container shared-title-sec">
          <div className="titleh2 ">
            <h2>About Shared Hosting</h2>
            <p className="shared-title-p">
              Shared hosting plans are highly favored by newcomers seeking to
              host their initial websites. Shared hosting entails multiple
              <br />
              websites being hosted on a single physical server. The
              cost-effectiveness and minimal maintenance obligations
              <br /> make this form of hosting an appealing choice.
            </p>
          </div>
        </div>

        {/* <div>
          <div className="bestprice-sec">
            <div className="container-fluid">
              <div className="titleh2w2">
                <h2>Choose Your Shared Hosting Plan</h2>
              </div>

              <section>
                <div className="">
                  <div className="overflow-div">
                    <div className="p-0 table-static-div">
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p style={{ color: " #436fe8" }}>
                            Our Full Features Comparison
                          </p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Domain</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Hosting</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Website Maintenance</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>SSL Certificate</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Google Workspace Mails</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Google Marketing</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Social Media Marketing</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Branding</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Technical Support</p>
                        </div>
                      </h4>
                    </div>
                    <AddToCart />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div> */}
        {/* why choose us for shared hosting start */}
        <div>
          <div className="features-sec2">
            <div className="container">
              <div className="titleh2 mt60">
                <h2>Why Choose Us For Shared Hosting</h2>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="whychoose-sec">
            <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-md-3 col-lg-3 slider-div6">
                  <div className="op2">
                    <h2 className="slider-img5">
                      G-Rank Shared <br />
                      Hosting Plans
                    </h2>
                    <p>
                      Reliable and cost-effective website hosting solutions.
                    </p>
                    <p>
                      Servers located in India for faster loading times and
                      enhanced performance.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3 slider-div6">
                  <div className="op2">
                    <h2 className="slider-img5">Key Features</h2>
                    <p>
                      99.9% Uptime Guarantee:
                      <br /> Ensuring your website is always available.
                    </p>
                    <p>
                      Experienced Support Team:
                      <br />
                      Ready to assist with technical issues and queries.
                    </p>
                    <p>
                      Variety of Business Types:
                      <br />
                      Suitable for all types of businesses.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3 slider-div6">
                  <div className="op2">
                    <h2 className="slider-img5">Benefits</h2>
                    <p>
                      Fast Loading Speeds:
                      <br />
                      Servers in India optimize website performance.
                    </p>
                    <p>
                      Secure Hosting:
                      <br />
                      Ensuring the safety of your website's data.
                    </p>
                    <p>
                      Exceptional Customer Support:
                      <br />
                      Professional assistance when needed.
                    </p>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-3 slider-div6">
                  <div className="op2">
                    <h2 className="slider-img5">Explore Plans</h2>
                    <p>
                      Visit our website to find premium shared hosting plans.
                    </p>
                    <p>
                      Experience the advantages of reliable hosting and
                      exceptional support.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* why choose us for shared hosting end */}

        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div>
              <motion.h2
                className="branding-quick-link"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Quick Links
                <p className="d-block"> For You</p>
              </motion.h2>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}

        <section>
          <div className="performance-footerimg-div">
            <img
              className="img-fluid w-100"
              src="assest/photos/branding/branding-footer-img.png"
              alt="G-Rank"
              title="grank"
            />
            <div className="performance-footer-div-text">
              <h4>Learn more about our sevices</h4>
              <form className="searchArea" onSubmit={handleSubmit}>
                <button type="submit" tabindex="2">
                  Explore
                </button>
                <div className="inputDiv">
                  <input
                    type="text"
                    id="inputData"
                    name="inputData"
                    value={inputData}
                    onChange={(e) => setInputData(e.target.value)}
                    requiredtabindex="1"
                  />
                </div>
              </form>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default SharedHosting;
