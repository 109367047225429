import React from "react";
import Header from "../header2/Header";
import { NavLink } from "react-router-dom";
import Seo from "../seo/Seo";
import { motion } from "framer-motion";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

const PartnershipCollaboration = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Grank",
    url: "https://www.grank.co.in/partnershipcollaboration",
    logo: "https://www.grank.co.in/assest/photos/g-rank-white-logo.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "8522953903",
      contactType: "",
      areaServed: "IN",
      availableLanguage: "en",
    },
    sameAs: [
      "https://www.facebook.com/Grank.India",
      "https://twitter.com/Grank_India/",
      "https://www.instagram.com/grank.india/",
      "https://www.youtube.com/channel/UCAuK0EC-TmLLHVSmrjxMnIA",
      "https://www.linkedin.com/company/g-rank-digital-marketing-company/",
    ],
  };

  return (
    <>
      <Seo
        title="Case Studies"
        description="Explore the stories of our success. Dive into our Case Studies to witness firsthand how we've helped businesses overcome challenges, achieve milestones, and realize their goals. "
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="react, meta tags, seo"
        searchengines="ALL"
        canonical="https://www.grank.co.in/case-studies"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/case-studies/case-studies-banner.png"
            alt="G-Rank"
            title="case studies"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/case-studies/case-studies-banner-mobile.png"
            alt="G-Rank"
            title="case studies"
          />

          <div className="case-banner-div">
            <motion.h2
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Partnership Collaboration
            </motion.h2>
            <Breadcrumbs
              className=" breadcrumbs"
              aria-label="breadcrumb"
              style={{ paddingTop: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#ccc",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>

              <Typography color="text.primary">
                PartnershipCollaboration
              </Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}
        {/* images-new start */}
        <section className="blog-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 case-div-new-partner">
                <NavLink to="/dirak">
                  <motion.img
                    initial={{ opacity: 0, scale: 0, x: 100 }}
                    whileInView={{ opacity: 1, scale: 1, x: 0 }}
                    transition={{ duration: 0.6 }}
                    className="img-fluid animate__bounceIn"
                    src="assest/photos/google-partner-big.png"
                    alt="G-Rank"
                  />
                </NavLink>
              </div>
              <div className="col-12 col-md-4 col-lg-4 case-div-new-partner">
                <NavLink to="/ace-force">
                  <motion.img
                    initial={{ opacity: 0, scale: 0, x: 100 }}
                    whileInView={{ opacity: 1, scale: 1, x: 0 }}
                    transition={{ duration: 0.6 }}
                    className="img-fluid animate__bounceIn"
                    src="assest/photos/meta-partner-big.png"
                    alt="G-Rank"
                  />
                </NavLink>
              </div>
              <div className="col-12 col-md-4 col-lg-4 case-div-new-partner">
                <NavLink to="/cms">
                  <motion.img
                    initial={{ opacity: 0, scale: 0, x: 100 }}
                    whileInView={{ opacity: 1, scale: 1, x: 0 }}
                    transition={{ duration: 0.6 }}
                    className="img-fluid animate__bounceIn"
                    src="assest/photos/google-workspace-big.png"
                    alt="G-Rank"
                  />
                </NavLink>
              </div>
              <div className="col-12 col-md-4 col-lg-4 case-div-new-partner">
                <NavLink to="/shodhana">
                  <motion.img
                    initial={{ opacity: 0, scale: 0, x: 100 }}
                    whileInView={{ opacity: 1, scale: 1, x: 0 }}
                    transition={{ duration: 0.6 }}
                    className="img-fluid animate__bounceIn"
                    src="assest/photos/google-cloud-big.png"
                    alt="G-Rank"
                  />
                </NavLink>
              </div>
              <div className="col-12 col-md-4 col-lg-4 case-div-new-partner">
                <NavLink to="/signature">
                  <motion.img
                    initial={{ opacity: 0, scale: 0, x: 100 }}
                    whileInView={{ opacity: 1, scale: 1, x: 0 }}
                    transition={{ duration: 0.6 }}
                    className="img-fluid animate__bounceIn"
                    src="assest/photos/rediff-big.png"
                    alt="G-Rank"
                  />
                </NavLink>
              </div>
              <div className="col-12 col-md-4 col-lg-4 case-div-new-partner">
                <NavLink to="/eco-life">
                  <motion.img
                    initial={{ opacity: 0, scale: 0, x: 100 }}
                    whileInView={{ opacity: 1, scale: 1, x: 0 }}
                    transition={{ duration: 0.6 }}
                    className="img-fluid animate__bounceIn"
                    src="assest/photos/microsoft-logo.png"
                    alt="G-Rank"
                  />
                </NavLink>
              </div>
            </div>
          </div>
        </section>
        {/* images-new end */}
      </section>
    </>
  );
};

export default PartnershipCollaboration;
