import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  selectCardsecu,
  selectProductssecu,
  addsecuCard,
  removesecuCard,
  removesecuCardall,
} from "../../../app/slices/shoppingsecuSlice";
import {
  selectCardgoogle,
  selectProductsgoogle,
  addgoogleCard,
  removegoogleCard,
  removegoogleCardall,
} from "../../../app/slices/shoppinggoogleSlice";
import {
  selectCardmicro,
  selectProductsmicro,
  addmicroCard,
  removemicroCard,
  removemicroCardall,
} from "../../../app/slices/shoppingmicroSlice";
import {
  selectCardrediff,
  selectProductsrediff,
  addrediffCard,
  removerediffCard,
  removerediffCardall,
} from "../../../app/slices/shoppingrediffSlice";
import { setSelectedOption } from "../../../app/slices/dropdown.Slice";
import { setSelectedOptiongoogle } from "../../../app/slices/dropdowngoogle.Slice";
import { setSelectedOptionmicro } from "../../../app/slices/dropdownmicro.Slice";
import { setSelectedOptionrediff } from "../../../app/slices/dropdownrediff.Slice";

import Header from "../../../layout/blanklayout/header2/Header";
import { NavLink } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";
import Swal from "sweetalert2";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import axios from "axios";

const Cart = () => {
  const dispatch = useDispatch();
  const cardProductssecu = useSelector(selectCardsecu);
  const cardProductsgoogle = useSelector(selectCardgoogle);
  const cardProductsmicro = useSelector(selectCardmicro);
  const cardProductsrediff = useSelector(selectCardrediff);

  const productssecu = useSelector(selectProductssecu);
  const productsgoogle = useSelector(selectProductsgoogle);
  const productsmicro = useSelector(selectProductsmicro);
  const productsrediff = useSelector(selectProductsrediff);

  const [productsecuList, setProductSecuList] = useState([]);
  const [productgoogleList, setProductgoogleList] = useState([]);
  const [productmicroList, setProductmicroList] = useState([]);
  const [productrediffList, setProductrediffList] = useState([]);

  // // Extract names from each cardProducts variable
  const secuProductNames = productsecuList.map((prod, i) => prod.payname);
  const googleProductNames = productgoogleList.map((prod, i) => prod.payname);
  const microProductNames = productmicroList.map((prod, i) => prod.payname);
  const rediffProductNames = productrediffList.map((prod, i) => prod.payname);
  const allProductNames = [
    ...secuProductNames,
    ...googleProductNames,
    ...microProductNames,
    ...rediffProductNames,
  ];
  const formattedData = allProductNames.reduce((acc, productName, index) => {
    acc[`name${index + 1}`] = productName;
    return acc;
  }, {});
  const concatenatedValues = Object.values(formattedData).join(" ");
  const concate = { name: concatenatedValues };

  // productInfo end --------------------------------------------
  // secu-dropdown
  const [selectedOptions, setSelectedOptions] = useState({});
  const selectedOptionss = useSelector((state) => state.dropdown);

  useEffect(() => {
    sessionStorage.setItem("selectedOptions", JSON.stringify(selectedOptionss));
  }, [selectedOptions]);

  useEffect(() => {
    const storedOptions = sessionStorage.getItem("selectedOptions");
    if (storedOptions) {
      setSelectedOptions(JSON.parse(storedOptions));
    }
  }, []);

  const handleSelectChange = (productId, value) => {
    setSelectedOptions({
      ...selectedOptions,
      [productId]: value,
    });
    dispatch(setSelectedOption({ productId, selectedOption: value }));
  };
  // google-dropdown
  const [selectedOptionsgoogle, setSelectedOptionsgoogle] = useState({});
  const selectedOptionssgoogle = useSelector((state) => state.dropdowngoogle);

  useEffect(() => {
    sessionStorage.setItem(
      "selectedOptionsgoogle",
      JSON.stringify(selectedOptionssgoogle)
    );
  }, [selectedOptionsgoogle]);

  useEffect(() => {
    const storedOptionsgoogle = sessionStorage.getItem("selectedOptionsgoogle");
    if (storedOptionsgoogle) {
      setSelectedOptionsgoogle(JSON.parse(storedOptionsgoogle));
    }
  }, []);

  const handleSelectChangegoogle = (productId, value) => {
    setSelectedOptionsgoogle({
      ...selectedOptionsgoogle,
      [productId]: value,
    });
    dispatch(
      setSelectedOptiongoogle({ productId, selectedOptiongoogle: value })
    );
  };
  // micro-dropdown
  const [selectedOptionsmicro, setSelectedOptionsmicro] = useState({});
  const selectedOptionssmicro = useSelector((state) => state.dropdownmicro);

  useEffect(() => {
    sessionStorage.setItem(
      "selectedOptionsmicro",
      JSON.stringify(selectedOptionssmicro)
    );
  }, [selectedOptionsmicro]);

  useEffect(() => {
    const storedOptionsmicro = sessionStorage.getItem("selectedOptionsmicro");
    if (storedOptionsmicro) {
      setSelectedOptionsmicro(JSON.parse(storedOptionsmicro));
    }
  }, []);

  const handleSelectChangemicro = (productId, value) => {
    setSelectedOptionsmicro({
      ...selectedOptionsmicro,
      [productId]: value,
    });
    dispatch(setSelectedOptionmicro({ productId, selectedOptionmicro: value }));
  };
  // rediff-dropdown
  const [selectedOptionsrediff, setSelectedOptionsrediff] = useState({});
  const selectedOptionssrediff = useSelector((state) => state.dropdownrediff);

  useEffect(() => {
    sessionStorage.setItem(
      "selectedOptionsrediff",
      JSON.stringify(selectedOptionssrediff)
    );
  }, [selectedOptionsrediff]);

  useEffect(() => {
    const storedOptionsrediff = sessionStorage.getItem("selectedOptionsrediff");
    if (storedOptionsrediff) {
      setSelectedOptionsrediff(JSON.parse(storedOptionsrediff));
    }
  }, []);

  const handleSelectChangerediff = (productId, value) => {
    setSelectedOptionsrediff({
      ...selectedOptionsrediff,
      [productId]: value,
    });
    dispatch(
      setSelectedOptionrediff({ productId, selectedOptionrediff: value })
    );
  };

  // ----------------------------------------------------
  useEffect(() => {
    const productsecuList = cardProductssecu.productssecu.map((prod) => {
      const p = productssecu.find(({ _id }) => _id == prod.id);
      return { ...prod, ...p };
    });
    setProductSecuList(productsecuList);
  }, [cardProductssecu]);

  useEffect(() => {
    const prodListgoogle = cardProductsgoogle.productsgoogle.map((prod) => {
      const p = productsgoogle.find(({ _id }) => _id == prod.id);
      return { ...prod, ...p };
    });
    setProductgoogleList(prodListgoogle);
  }, [cardProductsgoogle]);

  useEffect(() => {
    const prodListmicro = cardProductsmicro.productsmicro.map((prod) => {
      const p = productsmicro.find(({ _id }) => _id == prod.id);
      return { ...prod, ...p };
    });
    setProductmicroList(prodListmicro);
  }, [cardProductsmicro]);

  useEffect(() => {
    const prodListrediff = cardProductsrediff.productsrediff.map((prod) => {
      const p = productsrediff.find(({ _id }) => _id == prod.id);
      return { ...prod, ...p };
    });
    setProductrediffList(prodListrediff);
  }, [cardProductsrediff]);

  // -------------------------------------------------------------------------------------------
  // secu
  const addToCardsecu = (_id) => {
    dispatch(addsecuCard({ id: _id }));
  };
  const removeToCardsecu = (_id) => {
    dispatch(removesecuCard({ id: _id }));
  };
  const removeToCardsecuallcart = (prod) => {
    dispatch(removesecuCardall(prod));
  };
  // google
  const addToCardgoogle = (_id) => {
    dispatch(addgoogleCard({ id: _id }));
  };
  const removeToCardgoogle = (_id) => {
    dispatch(removegoogleCard({ id: _id }));
  };
  const removeToCardgoogleallcart = (prod) => {
    dispatch(removegoogleCardall(prod));
  };
  // micro
  const addToCardmicro = (_id) => {
    dispatch(addmicroCard({ id: _id }));
  };
  const removeToCardmicro = (_id) => {
    dispatch(removemicroCard({ id: _id }));
  };
  const removeToCardmicroallcart = (prod) => {
    dispatch(removemicroCardall(prod));
  };
  // rediff
  const addToCardrediff = (_id) => {
    dispatch(addrediffCard({ id: _id }));
  };
  const removeToCardrediff = (_id) => {
    dispatch(removerediffCard({ id: _id }));
  };
  const removeToCardrediffallcart = (prod) => {
    dispatch(removerediffCardall(prod));
  };

  // payment
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const loadUsers = () => {
    axios
      .get("https://servers.myvidya.co.in/api/v1/users")
      .then((response) => {
        setUsers(response?.data);
      })
      .catch((err) => {
        console.error("Error fetching users:", err);
      });
  };

  React.useEffect(() => {
    if (users.length === 0 && myData) {
      loadUsers();
    }
  }, [users, myData]);

  var myData = localStorage.getItem("loggedUser");

  //Filtering users by email
  React.useEffect(() => {
    if (users.length > 0 && myData) {
      const filteredUser = users.find((user) => user.email === myData);
      if (filteredUser) {
        setSelectedUser(filteredUser);
      }
    }
  }, [users, myData]);

  //generate txnid
  function generateUniqueNumber() {
    return Math.floor(100000 + Math.random() * 900000); // Generate a random 4-digit number
  }
  let uniqueNumber = generateUniqueNumber();

  // -------------
  let total = 0;
  return (
    <>
      <section className="section-top">
        <Header />

        <div className="container-fluid  your-cart-container">
          <div className="row">
            <div className="col-12 total-cart-header">
              <h1>
                <ShoppingCartIcon className="cart-icon" />
                Your cart
              </h1>
            </div>

            <div className="col-12 col-md-12 col-lg-7">
              {/* <CartSecu /> */}
              {productsecuList.map((prod, i) => {
                const handleDelete = () => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "Deleted!",
                        text: "Your  Item has been deleted.",
                        icon: "success",
                      });
                      // removeToCardsecu(prod.id);
                      removeToCardsecuallcart(prod);
                    }
                  });
                };

                const selectedOption = selectedOptions[prod.id] || 1;
                total += prod.price * prod.quantity * selectedOption;

                return (
                  <div key={prod._id} className="">
                    <div className=" total-cart ">
                      <div className="cart-img-div">
                        <img
                          className="img-fluid "
                          src="assest/photos/social.png"
                          alt="G-Rank"
                        />
                      </div>
                      <div key={prod.id}>
                        <h1>{prod.name}</h1>
                        <p>{prod.discription}</p>
                        <h4>
                          <img
                            className="img-fluid "
                            src="assest/photos/rupee3.png"
                            alt="G-Rank"
                          />
                          {prod.price} <span>/yr</span>
                        </h4>
                        <div className="flex-cart-div">
                          <section>
                            <h6 className="cart-Quatity-h6">Qty:</h6>
                            <div className="cart-Quatity-div">
                              <button
                                className="total-cart-button"
                                onClick={() => removeToCardsecu(prod._id)}
                              >
                                -
                              </button>
                              <span className="total-cart-span">
                                {prod.quantity}
                              </span>
                              <button
                                className="total-cart-button"
                                onClick={() => addToCardsecu(prod._id)}
                              >
                                +
                              </button>
                            </div>
                          </section>

                          <select
                            className="total-cart-select"
                            value={selectedOption}
                            onChange={(e) => {
                              handleSelectChange(prod.id, e.target.value);
                            }}
                          >
                            <option value={1}>1 year</option>
                            <option value={2}>2 years</option>
                            <option value={3}>3 years</option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <h2>
                          <h6 className="cart-Quatity-h6">Subtotal:-</h6>
                          <img
                            className="img-fluid "
                            src="assest/photos/rupee3.png"
                            alt="G-Rank"
                          />
                          {Math.round(
                            prod.price * prod.quantity * selectedOption
                          )}
                        </h2>
                        <div className="button-dis">
                          <IconButton
                            className="delete-icon-cart"
                            onClick={handleDelete}
                            style={{ backgroundColor: "#fff" }}
                          >
                            <DeleteIcon color="#ccc" />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* <Cartgoogle /> */}
              {productgoogleList.map((prod, i) => {
                const handleDeletesecu = () => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "Deleted!",
                        text: "Your  Item has been deleted.",
                        icon: "success",
                      });
                      removeToCardgoogleallcart(prod);
                    }
                  });
                };

                const selectedOptiongoogle =
                  selectedOptionsgoogle[prod.id] || 1;
                total += prod.price * 12 * prod.quantity * selectedOptiongoogle;

                return (
                  <div key={prod._id} className="">
                    <div className=" total-cart ">
                      <div class="cart-img-div">
                        <img
                          className="img-fluid "
                          src="assest/photos/social-google.png"
                          alt="G-Rank"
                        />
                      </div>
                      <div key={prod.id}>
                        <h1>Google Workspace- {prod.name}</h1>
                        <p>{prod.discription}</p>
                        <h4>
                          <img
                            className="img-fluid "
                            src="assest/photos/rupee3.png"
                            alt="G-Rank"
                          />
                          {Math.round(prod.price * 12)} <span>/yr</span>
                        </h4>
                        <div className="flex-cart-div">
                          <section>
                            <h6 className="cart-Quatity-h6">Qty:</h6>
                            <div className="cart-Quatity-div">
                              <button
                                className="total-cart-button"
                                onClick={() => removeToCardgoogle(prod._id)}
                              >
                                -
                              </button>
                              <span className="total-cart-span">
                                {prod.quantity}
                              </span>
                              <button
                                className="total-cart-button"
                                onClick={() => addToCardgoogle(prod._id)}
                              >
                                +
                              </button>
                            </div>
                          </section>
                          <select
                            className="total-cart-select"
                            value={selectedOptiongoogle}
                            onChange={(e) => {
                              handleSelectChangegoogle(prod.id, e.target.value);
                            }}
                          >
                            <option value={1}>1 year</option>
                            <option value={2}>2 years</option>
                            <option value={3}>3 years</option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <h2>
                          <h6 className="cart-Quatity-h6">Subtotal:-</h6>
                          <img
                            className="img-fluid "
                            src="assest/photos/rupee3.png"
                            alt="G-Rank"
                          />
                          {Math.round(
                            prod.price *
                              12 *
                              prod.quantity *
                              selectedOptiongoogle
                          )}
                        </h2>
                        <div className="button-dis">
                          <IconButton
                            className="delete-icon-cart"
                            onClick={handleDeletesecu}
                            style={{ backgroundColor: "#fff" }}
                          >
                            <DeleteIcon color="#ccc" />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* <Cartmicro /> */}
              {productmicroList.map((prod, i) => {
                const handleDeletemicro = () => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "Deleted!",
                        text: "Your  Item has been deleted.",
                        icon: "success",
                      });
                      removeToCardmicroallcart(prod);
                    }
                  });
                };

                const selectedOptionmicro = selectedOptionsmicro[prod.id] || 1;
                total += prod.price * 12 * prod.quantity * selectedOptionmicro;

                return (
                  <div key={prod._id} className="">
                    <div className=" total-cart ">
                      <div class="cart-img-div">
                        <img
                          className="img-fluid "
                          src="assest/photos/social-micro.jpg"
                          alt="G-Rank"
                        />
                      </div>
                      <div key={prod.id}>
                        <h1>{prod.name}</h1>
                        <p>{prod.discription}</p>
                        <h4>
                          <img
                            className="img-fluid "
                            src="assest/photos/rupee3.png"
                            alt="G-Rank"
                          />
                          {prod.price * 12} <span>/yr</span>
                        </h4>
                        <div className="flex-cart-div">
                          <section>
                            <h6 className="cart-Quatity-h6">Qty:</h6>
                            <div className="cart-Quatity-div">
                              <button
                                className="total-cart-button"
                                onClick={() => removeToCardmicro(prod._id)}
                              >
                                -
                              </button>
                              <span className="total-cart-span">
                                {prod.quantity}
                              </span>
                              <button
                                className="total-cart-button"
                                onClick={() => addToCardmicro(prod._id)}
                              >
                                +
                              </button>
                            </div>
                          </section>
                          <select
                            className="total-cart-select"
                            value={selectedOptionmicro}
                            onChange={(e) => {
                              handleSelectChangemicro(prod.id, e.target.value);
                            }}
                          >
                            <option value={1}>1 year</option>
                            <option value={2}>2 years</option>
                            <option value={3}>3 years</option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <h2>
                          <h6 className="cart-Quatity-h6">Subtotal:-</h6>
                          <img
                            className="img-fluid "
                            src="assest/photos/rupee3.png"
                            alt="G-Rank"
                          />
                          {Math.round(
                            prod.price *
                              12 *
                              prod.quantity *
                              selectedOptionmicro
                          )}
                        </h2>
                        <div className="button-dis">
                          <IconButton
                            className="delete-icon-cart"
                            onClick={handleDeletemicro}
                            style={{ backgroundColor: "#fff" }}
                          >
                            <DeleteIcon color="#ccc" />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
              {/* <Cartrediff /> */}
              {productrediffList.map((prod, i) => {
                const handleDeleterediff = () => {
                  Swal.fire({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Yes, delete it!",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire({
                        title: "Deleted!",
                        text: "Your Item has been deleted.",
                        icon: "success",
                      });
                      removeToCardrediffallcart(prod);
                    }
                  });
                };
                const selectedOptionrediff =
                  selectedOptionsrediff[prod.id] || 1;
                total += prod.price * prod.quantity * selectedOptionrediff;

                return (
                  <div key={prod._id} className="">
                    <div className=" total-cart ">
                      <div class="cart-img-div">
                        <img
                          className="img-fluid h-auto "
                          src="assest/photos/rediff-big.png"
                          alt="G-Rank"
                        />
                      </div>
                      <div key={prod.id}>
                        <h1>{prod.name}</h1>
                        <p>{prod.discription}</p>
                        <h4>
                          <img
                            className="img-fluid "
                            src="assest/photos/rupee3.png"
                            alt="G-Rank"
                          />
                          {prod.price} <span>/yr</span>
                        </h4>
                        <div className="flex-cart-div">
                          <section>
                            <h6 className="cart-Quatity-h6">Qty:</h6>
                            <div className="cart-Quatity-div">
                              <button
                                className="total-cart-button"
                                onClick={() => removeToCardrediff(prod._id)}
                              >
                                -
                              </button>
                              <span className="total-cart-span">
                                {prod.quantity}
                              </span>
                              <button
                                className="total-cart-button"
                                onClick={() => addToCardrediff(prod._id)}
                              >
                                +
                              </button>
                            </div>
                          </section>
                          <select
                            className="total-cart-select"
                            value={selectedOptionrediff}
                            onChange={(e) => {
                              handleSelectChangerediff(prod.id, e.target.value);
                            }}
                          >
                            <option value={1}>1 year</option>
                            <option value={2}>2 years</option>
                            <option value={3}>3 years</option>
                          </select>
                        </div>
                      </div>
                      <div>
                        <h2>
                          <h6 className="cart-Quatity-h6">Subtotal:-</h6>
                          <img
                            className="img-fluid "
                            src="assest/photos/rupee3.png"
                            alt="G-Rank"
                          />

                          {Math.round(
                            prod.price * prod.quantity * selectedOptionrediff
                          )}
                        </h2>
                        <div className="button-dis">
                          <IconButton
                            className="delete-icon-cart"
                            onClick={handleDeleterediff}
                            style={{ backgroundColor: "#fff" }}
                          >
                            <DeleteIcon color="#ccc" />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="col-12 col-md-12 col-lg-5">
              <div>
                <h5 className="cart-notes">
                  <span> NOTE : </span>Please note that due to our manual
                  processing system, your purchase may not reflect immediately.
                  And also please be advised that upon placing your order/item,
                  our support team will promptly commence processing. Please
                  anticipate a response from our end within 48 to 72 hours, as
                  we are currently operating with manual processes. We
                  appreciate your patience and understanding during this time.
                  If, for any reason, you do not receive a response within the
                  specified timeframe, kindly reach out to us at
                  server@grank.co.in. Your satisfaction is our priority, and we
                  are here to assist you.
                </h5>
              </div>
              <div className="total-payment">
                <h2>Order Summary</h2>
                <hr />
                <h2>
                  Total <span>(INR)</span>
                  <pre className="total-price">
                    <img
                      className="img-fluid "
                      src="assest/photos/rupee3.png"
                      alt="G-Rank"
                    />
                    {Math.round(total)}
                  </pre>
                </h2>
                <p style={{ textAlign: "center" }}>
                  total does not include applicable taxes
                </p>

                <form
                  method="POST"
                  action="https://servers.myvidya.co.in/initiate_payment"
                >
                  <div class="main-form d-none">
                    <div class="mandatory-data">
                      <div class="form-field">
                        <label for="txnid">
                          Transaction ID<sup>*</sup>
                        </label>
                        {selectedUser && (
                          <input
                            id="txnid"
                            class="txnid"
                            name="txnid"
                            value={uniqueNumber}
                            placeholder="T31Q6JT8HB"
                          />
                        )}
                      </div>

                      <div class="form-field">
                        <label for="amount">
                          Amount<sup>(should be float)*</sup>
                        </label>
                        <input
                          id="amount"
                          class="amount"
                          name="amount"
                          value={Math.round(total)}
                          placeholder="125.25"
                        />
                      </div>

                      <div class="form-field">
                        <label for="firstname">
                          First Name<sup>*</sup>
                        </label>
                        {selectedUser && (
                          <input
                            id="first_name"
                            class="name"
                            name="name"
                            value={selectedUser.name}
                            placeholder="Easebuzz Pvt. Ltd."
                          />
                        )}
                      </div>

                      <div class="form-field">
                        <label for="email">
                          Email ID<sup>*</sup>
                        </label>
                        {selectedUser && (
                          <input
                            id="email"
                            class="email"
                            name="email"
                            value={selectedUser.email}
                            placeholder="initiate.payment@easebuzz.in"
                          />
                        )}
                      </div>

                      <div class="form-field">
                        <label for="phone">
                          Phone<sup>*</sup>
                        </label>

                        <input
                          id="phone"
                          class="phone"
                          name="phone"
                          value="9112390972"
                          placeholder="0123456789"
                        />
                      </div>

                      <div class="form-field">
                        <label for="productinfo">
                          Product Information<sup>*</sup>
                        </label>
                        {/* {productrediffList.map((prod, index) => ( */}

                        <input
                          id="productinfo"
                          class="productinfo"
                          name="productinfo"
                          value={concate.name}
                          placeholder="Apple Laptop"
                        />

                        {/* ))} */}
                      </div>

                      <div class="form-field">
                        <label for="surl">
                          Success URL<sup>*</sup>
                        </label>
                        <input
                          id="surl"
                          class="surl"
                          name="surl"
                          value="https://servers.myvidya.co.in/response"
                          placeholder="http://localhost:3000/response"
                        />
                      </div>

                      <div class="form-field">
                        <label for="furl">
                          Failure URL<sup>*</sup>
                        </label>
                        <input
                          id="furl"
                          class="furl"
                          name="furl"
                          value="https://servers.myvidya.co.in/response"
                          placeholder="http://localhost:3000/response"
                        />
                      </div>
                    </div>

                    <h3>Optional Parameters</h3>
                    <hr />
                    <div class="optional-data">
                      <div class="form-field">
                        <label for="udf1">UDF1</label>
                        <input
                          id="udf1"
                          class="udf1"
                          name="udf1"
                          value=""
                          placeholder="User description1"
                        />
                      </div>

                      <div class="form-field">
                        <label for="udf2">UDF2</label>
                        <input
                          id="udf2"
                          class="udf2"
                          name="udf2"
                          value=""
                          placeholder="User description2"
                        />
                      </div>

                      <div class="form-field">
                        <label for="udf3">UDF3</label>
                        <input
                          id="udf3"
                          class="udf3"
                          name="udf3"
                          value=""
                          placeholder="User description3"
                        />
                      </div>

                      <div class="form-field">
                        <label for="udf4">UDF4</label>
                        <input
                          id="udf4"
                          class="udf4"
                          name="udf4"
                          value=""
                          placeholder="User description4"
                        />
                      </div>

                      <div class="form-field">
                        <label for="udf5">UDF5</label>
                        <input
                          id="udf5"
                          class="udf5"
                          name="udf5"
                          value=""
                          placeholder="User description5"
                        />
                      </div>
                      <div class="form-field">
                        <label for="address1">Address 1</label>
                        <input
                          id="address1"
                          class="address1"
                          name="address1"
                          value=""
                          placeholder="#250, Main 5th cross,"
                        />
                      </div>

                      <div class="form-field">
                        <label for="address2">Address 2</label>
                        <input
                          id="address2"
                          class="address2"
                          name="address2"
                          value=""
                          placeholder="Saket nagar, Pune"
                        />
                      </div>

                      <div class="form-field">
                        <label for="city">City</label>
                        <input
                          id="city"
                          class="city"
                          name="city"
                          value=""
                          placeholder="Pune"
                        />
                      </div>

                      <div class="form-field">
                        <label for="state">State</label>
                        <input
                          id="state"
                          class="state"
                          name="state"
                          value=""
                          placeholder="Maharashtra"
                        />
                      </div>

                      <div class="form-field">
                        <label for="country">Country</label>
                        <input
                          id="country"
                          class="country"
                          name="country"
                          value=""
                          placeholder="India"
                        />
                      </div>

                      <div class="form-field">
                        <label for="zipcode">Zip-Code</label>
                        <input
                          id="zipcode"
                          class="zipcode"
                          name="zipcode"
                          value=""
                          placeholder="123456"
                        />
                      </div>

                      <div class="form-field">
                        <label for="sub_merchant_id">Sub-Merchant ID</label>
                        <input
                          id="sub_merchant_id"
                          class="sub_merchant_id"
                          name="sub_merchant_id"
                          value=""
                          placeholder="123456"
                        />
                      </div>

                      <div class="form-field">
                        <label for="unique_id">Unique Id</label>
                        <input
                          id="unique_id"
                          class="unique_id"
                          name="unique_id"
                          value=""
                          placeholder="Customer unique Id"
                        />
                      </div>

                      <div class="form-field">
                        <label for="split_payments">Split payment</label>
                        <input
                          id="split_payments"
                          class="split_payments"
                          name="split_payments"
                          value=""
                          placeholder='{ "axisaccount" : 100, "hdfcaccount" : 100}'
                        />
                      </div>
                      <div class="form-field">
                        <label for="customer_authentication_id">
                          Customer Authentication ID
                        </label>
                        <input
                          id="customer_authentication_id"
                          class="customer_authentication_id"
                          name="customer_authentication_id"
                          value=""
                          placeholder="customer authentication number"
                        />
                      </div>
                    </div>
                    <input
                      id="udf6"
                      type="hidden"
                      class="udf6"
                      name="udf6"
                      value=""
                      placeholder="User description6"
                    />
                    <input
                      id="udf7"
                      type="hidden"
                      class="udf7"
                      name="udf7"
                      value=""
                      placeholder="User description7"
                    />
                    <input
                      id="udf8"
                      type="hidden"
                      class="udf8"
                      name="udf8"
                      value=""
                      placeholder="User description8"
                    />
                    <input
                      id="udf9"
                      type="hidden"
                      class="udf9"
                      name="udf9"
                      value=""
                      placeholder="User description9"
                    />
                    <input
                      id="udf10"
                      type="hidden"
                      class="udf10"
                      name="udf10"
                      value=""
                      placeholder="User description10"
                    />
                  </div>

                  <div class="btn-submit ">
                    {selectedUser ? (
                      <button className="ready-to-pay" type="submit">
                        Proceed to Pay
                      </button>
                    ) : (
                      <NavLink to="/login">
                        <button className="ready-to-pay">
                          Login to Proceed
                        </button>
                      </NavLink>
                    )}
                  </div>
                </form>

                <h4>
                  <img
                    className="img-fluid "
                    src="assest/photos/lock.png"
                    alt="G-Rank"
                  />
                  Secured Payment
                </h4>
                <div className="total-pay-img">
                  <img
                    className="img-fluid "
                    src="assest/photos/visa.png"
                    alt="G-Rank"
                  />
                  <img
                    className="img-fluid "
                    src="assest/photos/mastercard-logo.jpg"
                    alt="G-Rank"
                  />
                  <img
                    className="img-fluid "
                    src="assest/photos/amex-card.png"
                    alt="G-Rank"
                  />
                  <img
                    className="img-fluid "
                    src="assest/photos/rupay.png"
                    alt="G-Rank"
                  />
                  <img
                    className="img-fluid "
                    src="assest/photos/maestro.png"
                    alt="G-Rank"
                  />
                  <img
                    className="img-fluid "
                    src="assest/photos/paytm.jpg"
                    alt="G-Rank"
                  />
                </div>
                <p>We also accept indian debit cards, UPI and Netbanking</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Cart;
