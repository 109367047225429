import { createSlice } from "@reduxjs/toolkit";

const shoppingrediffSlice = createSlice({
  name: "shoppingrediff",
  initialState: {
    card: { userId: 0, productsrediff: [] },
    productsrediff: [],
  },
  reducers: {
    addrediffProducts: (state, action) => {
      //add product to list
      return { ...state, productsrediff: action.payload };
    },
    addrediffCard: (state, { payload: prod }) => {
      //quantity increase by 1
      const index = state?.card?.productsrediff?.findIndex(
        (pro) => pro.id == prod.id
      );
      if (index >= 0) {
        const p = state?.card?.productsrediff[index];
        state.card.productsrediff.splice(index, 1, {
          id: p.id,
          quantity: p.quantity + 1,
        });
      } else {
        state?.card?.productsrediff.push({ id: prod.id, quantity: 1 }); //add one quantity
      }
    },
    removerediffCard: (state, { payload: prod }) => {
      //quantity decrease by 1
      const index = state?.card?.productsrediff?.findIndex(
        (pro) => pro.id == prod.id
      );
      const p = state?.card?.productsrediff[index];
      if (index >= 0 && p.quantity > 1) {
        state.card.productsrediff.splice(index, 1, {
          id: p.id,
          quantity: p.quantity - 1,
        });
      } else if (p?.quantity == 1) {
        state.card.productsrediff.splice(index, 1); // quantity zero(delete from card)
      }
    },
    removerediffCardall: (state, { payload: prod }) => {
      // Filter out the product from the card products array
      state.card.productsrediff = state.card.productsrediff.filter(
        (product) => product.id !== prod.id
      );
    },
  },
});

export const {
  addrediffProducts,
  addrediffCard,
  removerediffCard,
  removerediffCardall,
} = shoppingrediffSlice.actions;

export const selectCardrediff = (state) => state.shoppingrediff.card;
export const selectProductsrediff = (state) =>
  state.shoppingrediff.productsrediff;

export default shoppingrediffSlice.reducer;
