import { createContext } from "react";
 const CustomerContext = createContext({})
 export default CustomerContext 










 


