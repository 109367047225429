import React, { Suspense } from "react";
import routes from "../../shared/routes/AdminRoutes";
import { Routes, Navigate, Route } from "react-router-dom";

const SidebarRoutes = () => {
  return (
    <Suspense fallback={<h2>Loading....</h2>}>
      <Routes>
        {Array.isArray(routes) &&
          routes.map(({ path, hasSubRoutes, component }, i) => (
            <Route
              key={i}
              path={hasSubRoutes ? `${path}/*` : path}
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  {component}
                </Suspense>
              }
            />
          ))}
        <Route path="*" element={<Navigate to={routes[0]?.path} />} />
      </Routes>
    </Suspense>
  );
};

export default SidebarRoutes;
