import React from "react";
import Header from "../header2/Header";
import { NavLink } from "react-router-dom";
import Seo from "../seo/Seo";
import { motion } from "framer-motion";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";

const CaseStudies = () => {
  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Grank",
    url: "https://www.grank.co.in/case-studies",
    logo: "https://www.grank.co.in/assest/photos/g-rank-white-logo.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "8522953903",
      contactType: "",
      areaServed: "IN",
      availableLanguage: "en",
    },
    sameAs: [
      "https://www.facebook.com/Grank.India",
      "https://twitter.com/Grank_India/",
      "https://www.instagram.com/grank.india/",
      "https://www.youtube.com/channel/UCAuK0EC-TmLLHVSmrjxMnIA",
      "https://www.linkedin.com/company/g-rank-digital-marketing-company/",
    ],
  };

  return (
    <>
      <Seo
        title="Case Studies"
        description="Explore the stories of our success. Dive into our Case Studies to witness firsthand how we've helped businesses overcome challenges, achieve milestones, and realize their goals. "
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="react, meta tags, seo"
        searchengines="ALL"
        canonical="https://www.grank.co.in/case-studies"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/case-studies/case-studies-banner.png"
            alt="G-Rank"
            title="case studies"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/case-studies/case-studies-banner-mobile.png"
            alt="G-Rank"
            title="case studies"
          />

          <div className="case-banner-div">
            <motion.h2
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
            >
              Case Studies
            </motion.h2>
            <Breadcrumbs
              className=" breadcrumbs"
              aria-label="breadcrumb"
              style={{ paddingTop: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#ccc",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>

              <Typography color="text.primary">Case Studies</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}
        {/* images-new start */}
        <section className="blog-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 ">
                <NavLink to="/dirak">
                  <div className="case-div-new">
                    <h4>Dirak India</h4>
                  </div>
                </NavLink>
              </div>
              <div className="col-12 col-md-4 col-lg-4 ">
                <NavLink to="/ace-force">
                  <div className="case-div-new2">
                    <h4>Ace Forge</h4>
                  </div>
                </NavLink>
              </div>
              <div className="col-12 col-md-4 col-lg-4 ">
                <NavLink to="/cms">
                  <div className="case-div-new3">
                    <h4>CMS GP</h4>
                  </div>
                </NavLink>
              </div>
              <div className="col-12 col-md-4 col-lg-4 ">
                <NavLink to="/shodhana">
                  <div className="case-div-new4">
                    <h4>Shodhana</h4>
                  </div>
                </NavLink>
              </div>
              <div className="col-12 col-md-4 col-lg-4 ">
                <NavLink to="/signature">
                  <div className="case-div-new5">
                    <h4>Candeur Signature</h4>
                  </div>
                </NavLink>
              </div>
              <div className="col-12 col-md-4 col-lg-4 ">
                <NavLink to="/eco-life">
                  <div className="case-div-new6">
                    <h4>Ecolife Developers</h4>
                  </div>
                </NavLink>
              </div>
              <div className="col-12 col-md-4 col-lg-4 ">
                <NavLink to="/jayabheri">
                  <div className="case-div-new7">
                    <h4>Jayabheri Nirvana</h4>
                  </div>
                </NavLink>
              </div>
              <div className="col-12 col-md-4 col-lg-4 ">
                <NavLink to="/Ramky">
                  <div className="case-div-new8">
                    <h4>Ramky Estates</h4>
                  </div>
                </NavLink>
              </div>
              <div className="col-12 col-md-4 col-lg-4 ">
                <NavLink to="/performax">
                  <div className="case-div-new9">
                    <h4>Performax</h4>
                  </div>
                </NavLink>
              </div>
            </div>
          </div>
        </section>
        {/* images-new end */}
        {/* images start */}
        {/* <section className="blog-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 blog-images-section">
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.2 }}
                >
                  <img
                    className="img-fluid w-100"
                    src="assest/photos/case-studies/case-studies-img1.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                </motion.div>
                <h4 className="case-studies-div">
                  <img
                    className="img-fluid "
                    src="assest/photos/case-studies/dirak.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/dirak"
                  >
                    <h4>Dirak India</h4>
                  </NavLink>
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 blog-images-section">
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.5 }}
                >
                  <img
                    className="img-fluid w-100"
                    src="assest/photos/case-studies/case-studies-img2.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                </motion.div>
                <h4 className="case-studies-div">
                  <img
                    className="img-fluid "
                    src="assest/photos/case-studies/ace.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/ace-force"
                  >
                    <h4>Ace Forge</h4>
                  </NavLink>
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 blog-images-section">
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.2 }}
                >
                  <img
                    className="img-fluid w-100"
                    src="assest/photos/case-studies/case-studies-img3.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                </motion.div>
                <h4 className="case-studies-div">
                  <img
                    className="img-fluid "
                    src="assest/photos/case-studies/cms.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/cms"
                  >
                    <h4>CMS GP</h4>
                  </NavLink>
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 blog-images-section">
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.5 }}
                >
                  <img
                    className="img-fluid w-100"
                    src="assest/photos/case-studies/case-studies-img4.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                </motion.div>
                <h4 className="case-studies-div">
                  <img
                    className="img-fluid "
                    src="assest/photos/case-studies/shodhana.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/shodhana"
                  >
                    <h4>Shodhana</h4>
                  </NavLink>
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 blog-images-section">
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.2 }}
                >
                  <img
                    className="img-fluid w-100"
                    src="assest/photos/case-studies/case-studies-img5.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                </motion.div>
                <h4 className="case-studies-div">
                  <img
                    className="img-fluid "
                    src="assest/photos/case-studies/signature.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/signature"
                  >
                    <h4>Candeur Signature</h4>
                  </NavLink>
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 blog-images-section">
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.5 }}
                >
                  <img
                    className="img-fluid w-100"
                    src="assest/photos/case-studies/case-studies-img6.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                </motion.div>
                <h4 className="case-studies-div">
                  <img
                    className="img-fluid "
                    src="assest/photos/case-studies/eco-life.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/eco-life"
                  >
                    <h4>Ecolife Developers</h4>
                  </NavLink>
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 blog-images-section">
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.2 }}
                >
                  <img
                    className="img-fluid w-100"
                    src="assest/photos/case-studies/case-studies-img7.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                </motion.div>
                <h4 className="case-studies-div">
                  <img
                    className="img-fluid "
                    src="assest/photos/case-studies/jayabheri-nirvana.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/jayabheri"
                  >
                    <h4>Jayabheri Nirvana</h4>
                  </NavLink>
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 blog-images-section">
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.5 }}
                >
                  <img
                    className="img-fluid w-100"
                    src="assest/photos/case-studies/case-studies-img8.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                </motion.div>
                <h4 className="case-studies-div">
                  <img
                    className="img-fluid "
                    src="assest/photos/case-studies/ramky.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/Ramky"
                  >
                    <h4>Ramky Estates</h4>
                  </NavLink>
                </h4>
              </div>
              <div className="col-12 col-md-6 col-lg-6 blog-images-section">
                <motion.div
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.2 }}
                >
                  <img
                    className="img-fluid w-100"
                    src="assest/photos/case-studies/case-studies-img9.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                </motion.div>
                <h4 className="case-studies-div">
                  <img
                    className="img-fluid "
                    src="assest/photos/case-studies/performax.png"
                    alt="G-Rank"
                    title="case studies"
                  />
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/performax"
                  >
                    <h4>Performax</h4>
                  </NavLink>
                </h4>
              </div>
            </div>
          </div>
        </section> */}
        {/* images end */}
      </section>
    </>
  );
};

export default CaseStudies;
