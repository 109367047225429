import React from "react";
import Header from "../header2/Header";
import Seo from "../seo/Seo";
const PrivacyPolicy = () => {
  return (
    <>
      <Seo
        title="Blog | G-Rank"
        description="Dive into our Blog to explore a treasure trove of articles, tips, and expertise that cover a wide range of topics. "
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="react, meta tags, seo"
        searchengines="ALL"
        canonical="https://www.grank.co.in/privacy-policy"
      ></Seo>
      <section className="section-top">
        <Header />
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 teams-heading-div1">
              <h5>privacy policy</h5>
            </div>
          </div>
        </div>
        <div className="container-fluid  teams-section-new">
          <div className="row">
            <div className="col-12 tearm-condition-div">
              <h4>Summary:</h4>
              <p>
                We at G-Rank Digital Services Private Limited are dedicated to
                safeguarding your personal information since we respect your
                right to privacy. This privacy statement describes your rights
                and options about your information and how we gather, store, and
                utilize it. Depending on the services you use, there may be
                differences in the precise data we collect and why. Please see
                the pertinent parts of this privacy policy for further
                information.
                <br />
                This page informs you of our policies regarding collecting,
                using, and disclosing Personal Information when you use our
                Service. We will not use or share your information with anyone
                except as described in this Privacy Policy.
                <br />
                We use your Personal Information to provide and improve the
                Service. By using the Service, you agree to this policy's
                collection and use of information . Unless otherwise defined in
                this Privacy Policy, terms used in this Privacy Policy have the
                same meanings as in our Terms and Conditions, accessible at
                https://grank.co.in/
              </p>

              <h4>Consent</h4>
              <p>
                Visiting our website means you agree to all the terms and
                conditions of our privacy policy.
              </p>
              <h4>Contact</h4>
              <p>
                If you visit our website, request a quote, fill out our lead
                form or newsletter form or provide your details in any other
                way. We can contact you to tell you more about our services,
                show you remarketing ads or email you to tell you more about our
                services, offers or information.
              </p>
              <h4>Copyright</h4>
              <p>
                We own a copyright on this privacy policy. If any customers were
                found overstepping the terms and conditions of this policy would
                be subject to legal action. This Privacy Policy can change at
                any time to include or exclude clauses and G-Rank Digital
                Services Pvt Ltd is not responsible for updating Users .
              </p>
              <h4>Why do we collect data?</h4>
              <p>
                We collect data solely for legitimate purposes related to our
                services. Our data processing activities align with your right
                to privacy, protecting your personal integrity and private life.
                As the data controller, we determine the purposes and methods of
                data collection.
              </p>

              <h4>Our data collection aims to:</h4>
              <p>Enhance, debug, and maintain our services.</p>
              <p>Customise user experiences.</p>
              <p>Comply with legal requirements.</p>
              <p>Conduct analysis and research for marketing campaigns.</p>
              <p>Improve security and prevent fraud.</p>

              <p>
                We may also use your data to send personalised information
                regarding updates, upgrades, enhancements, surveys,
                recommendations, and advertisements if relevant to you.
              </p>
              <h4>Information Collection And Use</h4>
              <p>
                While using our Service, we may ask you to provide us with
                certain personally identifiable information that can be used to
                contact or identify you. Personally identifiable information
                (“Personal Information”) may include, but is not limited to:
              </p>
              <div className="teams-div2">
                <p>Name</p>
                <p>Email address</p>
                <p> Telephone number</p>
              </div>

              <h4>Log Data</h4>
              <p>
                We collect your browser’s information whenever you visit our
                Service (“Log Data”). This Log Data may include information such
                as your computer’s Internet Protocol (“IP”) address, browser
                type, browser version, the pages of our Service that you visit,
                the time and date of your visit, and the time spent on those
                pages, and other statistics.
              </p>

              <h4>How we use your information</h4>
              <div className="teams-div2">
                <p>
                  We use the information we collect in various ways, including:
                </p>
                <p className="term-flex-div">
                  <p>(a)</p>
                  <p> Provide, operate, and maintain our website</p>
                </p>
                <p className="term-flex-div">
                  <p>(b)</p>
                  <p> Improve, personalize, and expand our website</p>
                </p>
                <p className="term-flex-div">
                  <p>(c)</p>
                  <p>Understand and analyze how you use our website</p>
                </p>
                <p className="term-flex-div">
                  <p>(d)</p>
                  <p>
                    Develop new products, services, features, and functionality
                  </p>
                </p>
                <p className="term-flex-div">
                  <p>(e)</p>
                  <p>
                    Communicate with you, either directly or through one of our
                    partners, including for customer service, to provide you
                    with updates and other information relating to the website,
                    and for marketing and promotional purposes
                  </p>
                </p>

                <p>(f) Send you emails</p>
                <p>(g) Find and prevent fraud</p>
              </div>

              <h4>How do we protect your data?</h4>
              <p>
                - When you explicitly disclose it to us, such as filling out a
                form on one of our websites.
              </p>

              <p>
                - We collect information about you when you use our services or
                visit our websites.
              </p>

              <p>- When third parties provide us with information</p>

              <p>
                Some of our services involve third-party technology or code,
                which may access collected data. We may also share anonymised or
                aggregated data sets with trusted partners and third parties.
              </p>

              <p>
                As a domestic marketing company with data centres across the
                state, your data may be transferred to areas without the same
                data protection laws as your location. Nevertheless, we ensure
                stringent protection of your data.
              </p>
              <br />
              <h4>Cookies</h4>

              <p>
                Cookies are files with a small amount of data, which may include
                an anonymous unique identifier. Cookies are sent to your browser
                from a website and stored on your computer’s hard drive.
                <br />
                We use “cookies” to collect information. You can instruct your
                browser to refuse all cookies or to indicate when a cookie is
                being sent. However, if you do not accept cookies, you may be
                unable to use some portions of our Service.
              </p>

              <h4>Service Providers</h4>

              <p>
                We may employ third-party companies and individuals to
                facilitate our Service, provide the Service on our behalf,
                perform Service-related services, or assist us in analyzing how
                our Service is used.
                <br />
                These third parties have access to your Personal Information
                only to perform these tasks on our behalf and are obligated not
                to disclose or use it for any other purpose.
              </p>

              <h4>Third-Party Privacy Policies</h4>

              <p>
                This privacy policy page is the sole proprietorship of G-Rank
                Digital Services Pvt Ltd and does not apply to other advertisers
                or websites. Thus, we recommend that you to consult the
                respective Privacy Policies of these third-party ad servers for
                more accurate information. It may include their practices and
                instructions about opting out of certain options. You can choose
                to disable cookies through your browser options.
              </p>

              <h4>GRIEVANCES</h4>

              <p>
                If You have any concerns, questions, grievances or complaints
                about the Site its content, or this Privacy Policy, You can
                contact G-Rank at https://grank.co.in/
              </p>

              <h4>Security</h4>

              <p>
                The security of your Personal Information is important to us,
                but remember that no method of transmission over the Internet or
                method of electronic storage is 100% secure. While we strive to
                use commercially acceptable means to protect your Personal
                Information, we cannot guarantee its absolute security.
              </p>

              <h4>Children's Information</h4>

              <p>
                Another part of our priority is adding protection for children
                while using the internet. We encourage parents and guardians to
                observe, participate in, and/or monitor and guide their online
                activity.
                <br />
                G-Rank does not knowingly collect any personally identifiable
                Information from children under the age of 18. Suppose your
                child provided this kind of information on our website. In that
                case, we strongly encourage you to contact us immediately and we
                will do our best to remove such information from our records
                promptly.
              </p>
              <h4>Children’s Privacy</h4>

              <p>
                Our Service does not address anyone under the age of 18
                (“Children”).
                <br />
                We do not knowingly collect personally identifiable information
                from children under 18. If you are a parent or guardian and you
                are aware that your child has provided us with Personal
                Information, please contact us. If we discover that a child
                under 18 has provided us with Personal Information, we will
                delete such information from our servers immediately.
              </p>
              <h4>Compliance With Laws</h4>

              <p>
                We will disclose your Personal Information where required to do
                so by law or subpoena.
              </p>
              <h4>Changes To This Privacy Policy</h4>

              <p>
                We may update our Privacy Policy from time to time. We will
                notify you of any changes by posting the new Privacy Policy on
                this page. You are advised to review this Privacy Policy
                periodically for any changes. These Privacy Policy changes are
                effective when posted on this page.
              </p>
              <h4>What else should I know?</h4>

              <p>
                We maintain transparency regarding the collection and use of
                your data. Your rights extend beyond consent and opt-out
                choices. If you have provided personal information, you can
                modify or change it anytime.
                <br />
                We retain personal data only as long as necessary for processing
                purposes outlined in this privacy policy or as required by
                applicable laws. When data is no longer necessary, relevant, or
                legally required, we take steps to delete, aggregate, or
                anonymise it.
                <br />
                If you have any questions about this privacy policy or concerns
                regarding our services’ privacy practices, contact us via:
              </p>

              <h4 style={{ color: "#555" }}>
                G-Rank Digital Services Private Limited
              </h4>
              <div class="teams-div2">
                <h4>Hyderabad :</h4>
                <p>
                  Ground Floor, Queen's Plaza, 1-8-382, Passport Office Rd,
                  Patigadda, Begumpet, Hyderabad,
                  <br /> Telangana 500003
                </p>
                <h4>Bangalore : </h4>
                <p>
                  Second Floor, R K Lake Tower, Service Road, Subramani Nagar,
                  Guddadahalli, Hebbal,
                  <br /> Bengaluru, Karnataka 560024
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
