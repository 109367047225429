// dropdownSlice.js
import { createSlice } from "@reduxjs/toolkit";

const dropdownSlice = createSlice({
  name: "dropdown",
  initialState: {}, // Initial state will store selected options for each product
  reducers: {
    setSelectedOption: (state, action) => {
      const { productId, selectedOption } = action.payload;
      state[productId] = selectedOption;
    },
  },
});

export const { setSelectedOption } = dropdownSlice.actions;
export default dropdownSlice.reducer;
