import { API, endpoints } from "../api";

class AddtocartgoogleService {
  static createAddtocartgoogle() {
    return API.post(endpoints.api.googleworkspace.create);
  }

  static updateAddtocartgoogle() {
    return API.post(endpoints.api.googleworkspace.update);
  }

  static deleteAddtocartgoogle() {
    return API.post(endpoints.api.googleworkspace.delete);
  }

  static fetchOneAddtocartgoogle() {
    return API.post(endpoints.api.googleworkspace.getOne);
  }

  // static fetchAllAddtocartgoogle() {
  //   return API.post(endpoints.api.googleworkspace.getAll);
  // }

  static fetchAllAddtocartgoogle(query) {
    const url = query
      ? endpoints.api.googleworkspace.getAll + query
      : endpoints.api.googleworkspace.getAll;
    return API.get(url);
  }
}

export default AddtocartgoogleService;
