import React from "react";

function PaymentForm() {
  return (
    <div className="grid-container">
      <header className="wrapper">
        <div className="logo">
          <a href="../index.html">
            <img src="/static/images/eb-logo.svg" alt="Easebuzz" />
          </a>
        </div>

        <div className="hedding">
          <h2>
            <a className="highlight" href="../index.html">
              Back
            </a>
          </h2>
        </div>
      </header>

      <div className="form-container">
        <h2>INITIATE PAYMENT API</h2>
        <hr />
        <form
          method="POST"
          action="https://servers.myvidya.co.in/initiate_payment"
        >
          <div class="main-form">
            <h3>Mandatory Parameters</h3>
            <hr />
            <div class="mandatory-data">
              <div class="form-field">
                <label for="txnid">
                  Transaction ID<sup>*</sup>
                </label>
                <input
                  id="txnid"
                  class="txnid"
                  name="txnid"
                  value="323e"
                  placeholder="T31Q6JT8HB"
                />
              </div>

              <div class="form-field">
                <label for="amount">
                  Amount<sup>(should be float)*</sup>
                </label>
                <input
                  id="amount"
                  class="amount"
                  name="amount"
                  value="120.00"
                  placeholder="125.25"
                />
              </div>

              <div class="form-field">
                <label for="firstname">
                  First Name<sup>*</sup>
                </label>
                <input
                  id="first_name"
                  class="name"
                  name="name"
                  value="suraj"
                  placeholder="Easebuzz Pvt. Ltd."
                />
              </div>

              <div class="form-field">
                <label for="email">
                  Email ID<sup>*</sup>
                </label>
                <input
                  id="email"
                  class="email"
                  name="email"
                  value="surajl@gmail.co.in"
                  placeholder="initiate.payment@easebuzz.in"
                />
              </div>

              <div class="form-field">
                <label for="phone">
                  Phone<sup>*</sup>
                </label>
                <input
                  id="phone"
                  class="phone"
                  name="phone"
                  value="9112390932"
                  placeholder="0123456789"
                />
              </div>

              <div class="form-field">
                <label for="productinfo">
                  Product Information<sup>*</sup>
                </label>
                <input
                  id="productinfo"
                  class="productinfo"
                  name="productinfo"
                  value="abcd"
                  placeholder="Apple Laptop"
                />
              </div>

              <div class="form-field">
                <label for="surl">
                  Success URL<sup>*</sup>
                </label>
                <input
                  id="surl"
                  class="surl"
                  name="surl"
                  value="http://localhost:3000/response"
                  placeholder="http://localhost:3000/response"
                />
              </div>

              <div class="form-field">
                <label for="furl">
                  Failure URL<sup>*</sup>
                </label>
                <input
                  id="furl"
                  class="furl"
                  name="furl"
                  value="http://localhost:3000/response"
                  placeholder="http://localhost:3000/response"
                />
              </div>
            </div>

            <h3>Optional Parameters</h3>
            <hr />
            <div class="optional-data">
              <div class="form-field">
                <label for="udf1">UDF1</label>
                <input
                  id="udf1"
                  class="udf1"
                  name="udf1"
                  value=""
                  placeholder="User description1"
                />
              </div>

              <div class="form-field">
                <label for="udf2">UDF2</label>
                <input
                  id="udf2"
                  class="udf2"
                  name="udf2"
                  value=""
                  placeholder="User description2"
                />
              </div>

              <div class="form-field">
                <label for="udf3">UDF3</label>
                <input
                  id="udf3"
                  class="udf3"
                  name="udf3"
                  value=""
                  placeholder="User description3"
                />
              </div>

              <div class="form-field">
                <label for="udf4">UDF4</label>
                <input
                  id="udf4"
                  class="udf4"
                  name="udf4"
                  value=""
                  placeholder="User description4"
                />
              </div>

              <div class="form-field">
                <label for="udf5">UDF5</label>
                <input
                  id="udf5"
                  class="udf5"
                  name="udf5"
                  value=""
                  placeholder="User description5"
                />
              </div>
              <div class="form-field">
                <label for="address1">Address 1</label>
                <input
                  id="address1"
                  class="address1"
                  name="address1"
                  value=""
                  placeholder="#250, Main 5th cross,"
                />
              </div>

              <div class="form-field">
                <label for="address2">Address 2</label>
                <input
                  id="address2"
                  class="address2"
                  name="address2"
                  value=""
                  placeholder="Saket nagar, Pune"
                />
              </div>

              <div class="form-field">
                <label for="city">City</label>
                <input
                  id="city"
                  class="city"
                  name="city"
                  value=""
                  placeholder="Pune"
                />
              </div>

              <div class="form-field">
                <label for="state">State</label>
                <input
                  id="state"
                  class="state"
                  name="state"
                  value=""
                  placeholder="Maharashtra"
                />
              </div>

              <div class="form-field">
                <label for="country">Country</label>
                <input
                  id="country"
                  class="country"
                  name="country"
                  value=""
                  placeholder="India"
                />
              </div>

              <div class="form-field">
                <label for="zipcode">Zip-Code</label>
                <input
                  id="zipcode"
                  class="zipcode"
                  name="zipcode"
                  value=""
                  placeholder="123456"
                />
              </div>

              <div class="form-field">
                <label for="sub_merchant_id">Sub-Merchant ID</label>
                <input
                  id="sub_merchant_id"
                  class="sub_merchant_id"
                  name="sub_merchant_id"
                  value=""
                  placeholder="123456"
                />
              </div>

              <div class="form-field">
                <label for="unique_id">Unique Id</label>
                <input
                  id="unique_id"
                  class="unique_id"
                  name="unique_id"
                  value=""
                  placeholder="Customer unique Id"
                />
              </div>

              <div class="form-field">
                <label for="split_payments">Split payment</label>
                <input
                  id="split_payments"
                  class="split_payments"
                  name="split_payments"
                  value=""
                  placeholder='{ "axisaccount" : 100, "hdfcaccount" : 100}'
                />
              </div>
              <div class="form-field">
                <label for="customer_authentication_id">
                  Customer Authentication ID
                </label>
                <input
                  id="customer_authentication_id"
                  class="customer_authentication_id"
                  name="customer_authentication_id"
                  value=""
                  placeholder="customer authentication number"
                />
              </div>
            </div>

            <div class="btn-submit">
              <button type="submit">SUBMIT</button>
            </div>
            <input
              id="udf6"
              type="hidden"
              class="udf6"
              name="udf6"
              value=""
              placeholder="User description6"
            />
            <input
              id="udf7"
              type="hidden"
              class="udf7"
              name="udf7"
              value=""
              placeholder="User description7"
            />
            <input
              id="udf8"
              type="hidden"
              class="udf8"
              name="udf8"
              value=""
              placeholder="User description8"
            />
            <input
              id="udf9"
              type="hidden"
              class="udf9"
              name="udf9"
              value=""
              placeholder="User description9"
            />
            <input
              id="udf10"
              type="hidden"
              class="udf10"
              name="udf10"
              value=""
              placeholder="User description10"
            />
          </div>
        </form>
      </div>
    </div>
  );
}

export default PaymentForm;
