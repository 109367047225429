import React from "react";
import { useSelector } from "react-redux";
import { selectCardsecu } from "../../../app/slices/shoppingsecuSlice";
import { selectCardgoogle } from "../../../app/slices/shoppinggoogleSlice";
import { selectCardmicro } from "../../../app/slices/shoppingmicroSlice";
import { selectCardrediff } from "../../../app/slices/shoppingrediffSlice";
import { Link } from "react-router-dom";

const CardCounter = () => {
  const lengthsecu = useSelector(selectCardsecu)?.productssecu?.length || 0;
  const lengthgoogle =
    useSelector(selectCardgoogle)?.productsgoogle?.length || 0;
  const lengthmicro = useSelector(selectCardmicro)?.productsmicro?.length || 0;
  const lengthrediff =
    useSelector(selectCardrediff)?.productsrediff?.length || 0;

  // Calculate total length
  const totalLength = lengthsecu + lengthgoogle + lengthmicro + lengthrediff;

  return (
    <>
      <Link to="/cart">
        <span className="cart-count">{totalLength}</span>
      </Link>
    </>
  );
};

export default CardCounter;
