import React, { useEffect, useRef, useState } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { NavLink } from "react-router-dom";
import Seo from "../seo/Seo";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";
//import AddIcon from "@mui/icons-material/Add";
//import RemoveIcon from "@mui/icons-material/Remove";

const OrganicMarketing = () => {
  const { handleOpen } = React.useContext(CustomerContext);

  // acoordian button
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);

  //  useState section end----------------------------------------
  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // video autoplay start
  const vidRef = useRef();
  useEffect(() => {
    vidRef.current.play();
  }, []);
  // video autoplay end

  const schema = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "Grank",
    url: "https://www.grank.co.in/",
    potentialAction: {
      "@type": "SearchAction",
      target:
        "https://www.grank.co.in/organic-marketing-agency{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };
  return (
    <>
      <Seo
        title="Organic Marketing Agency in Bangalore | Hyderabad"
        description="Display advertising service in bangalore and hyderbad. Amplify your brand's visibility and impact with our Display Ads strategies."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="best organic marketing agency in Bangalore,
        top organic marketing agency in Hyderabad,
        top organic marketing firm in India "
        searchengines="ALL"
        canonical="https://www.grank.co.in/organic-marketing-agency"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div className="organic-marketing-banner">
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/organicmarketing/organic-marketing-banner.png"
            alt="G-Rank"
            title="organic marketing"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/organicmarketing/organic-marketing-banner-mobile2.png"
            alt="G-Rank"
            title="organic marketing"
          />
          <div className="organic-marketing-banner-new-div">
            <motion.img
              className="img-fluid w-100"
              src="assest/photos/organicmarketing/organic-yellow-patch2.png"
              alt="G-Rank"
              title="grank"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
            />

            <h1>
              Organic
              <br />
              Marketing Agency
            </h1>

            <h2>Marketing Done Right!</h2>

            <h3 onClick={handleOpen}>
              <NavLink to="">Explore</NavLink>
            </h3>
            <Breadcrumbs
              className="org-breadcru breadcrumbs"
              aria-label="breadcrumb"
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#000",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>

              <Typography color="#000">Organic Marketing</Typography>
            </Breadcrumbs>
            <div className="div2-p2">
              <motion.p
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                <span className="voilet">300% </span>
                <br />
                Organic growth <br />
                Quarterly
              </motion.p>
            </div>
            <div>
              <motion.p
                className="p2"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Retain more than <span className="pink">150+</span>
                <br /> customers through Organic <br />
                Marketing
              </motion.p>
            </div>
          </div>
        </div>
        {/* banner end */}

        <section className="organ">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <motion.h2
                  className="creative-header2"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.2 }}
                >
                  <p>
                    Welcome to our world of organic marketing expertise! Our
                    team of techie wizards has a treasure trove of experience
                    and a knack for making businesses flourish. We're geared up
                    to boost your brand across every marketing channel, from
                    Google, Facebook and everything in between. Let's take your
                    business to the next level together!
                  </p>
                  <br />
                  <p>
                    Get ready for an amazing experience with our organic
                    marketing agency! We specialize in conducting personalized
                    brand research analysis and crafting a custom strategy that
                    harnesses the power of SEO, drives traffic with engaging
                    content, and ensures your input is always valued for
                    sustained success. Get ready to take your brand to new
                    heights!
                  </p>
                </motion.h2>
              </div>
            </div>
          </div>
        </section>

        {/* Question mark start */}
        <section className="question-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4">
                <img
                  className="img-fluid w-100 shakex"
                  src="assest/photos/organicmarketing/organic-marketing-question-img.png"
                  alt="G-Rank"
                  title="organic marketing"
                />
              </div>
              <div className="col-12 col-md-8 col-lg-8 question-div">
                <motion.h4
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Why Choose G-Rank?
                </motion.h4>
                <motion.h2
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Best organic marketing agency in Hyderabad and Bangalore . At
                  G-Rank, we excel in elevating your online presence with
                  cutting-edge strategies. Our expert team is dedicated to every
                  project, utilizing their in-depth knowledge of search engine
                  algorithms and user behavior. We craft personalized strategies
                  to drive targeted traffic to your website. Authenticity and
                  long-term growth are our priorities, as we focus on
                  sustainable organic marketing techniques that build a strong
                  foundation for your online brand, ensuring lasting success.
                </motion.h2>
              </div>
            </div>
          </div>
        </section>
        {/* Question mark end */}
        {/* video section start */}
        <section className="video-section">
          <video
            className="img-fluid oragnic-marketing-video"
            src="assest/videos/organic-marketing-video.mp4"
            ref={vidRef}
            muted
            autoPlay
            loop
          />
          <div className="videos-text">
            <h4>Grow Your Business Organically</h4>
            <p>
              In today's fiercely competitive digital landscape, businesses must
              employ effective strategies to build customer trust. As an agency,
              we understand the significance of organic marketing, a powerful
              strategy that creates valuable content for prospective consumers
              without incurring significant costs. Our approach to organic
              marketing is rooted in a long-term perspective, aiming to attract
              and convert people naturally without relying heavily on paid
              advertising.
            </p>
          </div>
        </section>
        {/* video section end */}
        {/* marketing service start */}
        <section className="organic-market-service-section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="org-mark-sem-text">
                  <motion.h4
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.6 }}
                  >
                    Our Organic Marketing Services
                  </motion.h4>
                  <p>
                    Whether you need just one or many of our Organic marketing
                    services, we're here to help. We are an Organic Marketing
                    Agency.
                  </p>
                </div>
              </div>
              <motion.div
                className="col-12 col-md-4 col-lg-4"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1.2 }}
              >
                <motion.div
                  className="organic-market-service-div"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    className="img-fluid "
                    src="assest/photos/organicmarketing/organic-seo.png"
                    alt="G-Rank"
                    title="organic marketing"
                  />
                </motion.div>
                <h4 className="organic-market-service-text">
                  Search Engine <br />
                  Optimization
                </h4>
              </motion.div>
              <motion.div
                className="col-12 col-md-4 col-lg-4"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1.2 }}
              >
                <motion.div
                  className="organic-market-service-div"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    className="img-fluid "
                    src="assest/photos/organicmarketing/organic-social-media.png"
                    alt="G-Rank"
                    title="organic marketing"
                  />
                </motion.div>
                <h4 className="organic-market-service-text">
                  Social Media <br />
                  Optimization
                </h4>
              </motion.div>
              <motion.div
                className="col-12 col-md-4 col-lg-4"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1.2 }}
              >
                <motion.div
                  className="organic-market-service-div"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.8 }}
                >
                  <img
                    className="img-fluid "
                    src="assest/photos/organicmarketing/organic-content-marketing.png"
                    alt="G-Rank"
                    title="organic marketing"
                  />
                </motion.div>
                <h4 className="organic-market-service-text">
                  Content
                  <br /> Marketing
                </h4>
              </motion.div>
            </div>
          </div>
        </section>
        {/* marketing service end */}
        {/* action yellow start*/}
        <div className="action-yellow-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Want to Elevate Your Brand?
            <br /> Get in Touch.
          </motion.p>
          <NavLink to="">
            <motion.p
              className="action-yellow-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Contact Us <ArrowRightAltIcon />
            </motion.p>
          </NavLink>
        </div>
        {/* action yellow end*/}
        {/* FAQs section start*/}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <motion.h2
                  className="slider-heading"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  FAQs & Support
                </motion.h2>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          onClick={() => setOpen1(!open1)}
                        >
                          What is Organic Marketing?
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Organic marketing is a long-term digital marketing
                          strategy that allows companies to build brand
                          awareness, and engage and entertain leads using
                          non-paid tactics such as SEO, blog posts, video
                          content, social media optimisation, email marketing,
                          etc.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={() => setOpen2(!open2)}
                        >
                          Why is SEO important for my business?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          SEO is crucial for increasing your website's
                          visibility in search engine results, driving organic
                          traffic, and enhancing brand credibility, ultimately
                          leading to improved conversion rates and revenue
                          growth.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => setOpen3(!open3)}
                        >
                          How long does it take to see results from SEO efforts?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          The timeline for SEO results varies based on factors
                          such as competition, industry, and website health.
                          Generally, you can expect noticeable improvements in
                          4-6 months, with continued momentum over time.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse4"
                          aria-expanded="false"
                          aria-controls="collapse4"
                          onClick={() => setOpen4(!open4)}
                        >
                          How can social media optimization benefit my business?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse4"
                      className="collapse"
                      aria-labelledby="heading4"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          SMO can elevate your brand's online presence, foster
                          customer engagement, drive website traffic and enhance
                          brand loyalty through strategic content sharing and
                          audience interaction across various social platforms.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse5"
                          aria-expanded="false"
                          aria-controls="collapse5"
                          onClick={() => setOpen5(!open5)}
                        >
                          What are the key metrics to track for measuring SMO
                          success?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse5"
                      className="collapse"
                      aria-labelledby="heading5"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Important SMO metrics include engagement rate, reach,
                          click-through rate, follower growth, and conversions.
                          These indicators help gauge the effectiveness of your
                          social media strategies and their impact on your
                          business goals.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQs section end*/}

        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div>
              <motion.h2
                className="branding-quick-link"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Quick Links
                <p className="d-block"> For You</p>
              </motion.h2>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        <img
          className="img-fluid w-100"
          src="assest/photos/branding/branding-footer-img.png"
          alt="G-Rank"
          title="organic marketing"
        />
      </section>
    </>
  );
};

export default OrganicMarketing;
