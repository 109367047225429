import { createSlice } from "@reduxjs/toolkit";

const shoppinggoogleSlice = createSlice({
  name: "shoppinggoogle",
  initialState: {
    card: { userId: 0, productsgoogle: [] },
    productsgoogle: [],
  },
  reducers: {
    addgoogleProducts: (state, action) => {
      //add product to list
      return { ...state, productsgoogle: action.payload };
    },
    addgoogleCard: (state, { payload: prod }) => {
      //quantity increase by 1
      const index = state?.card?.productsgoogle?.findIndex(
        (pro) => pro.id == prod.id
      );
      if (index >= 0) {
        const p = state?.card?.productsgoogle[index];
        state.card.productsgoogle.splice(index, 1, {
          id: p.id,
          quantity: p.quantity + 1,
        });
      } else {
        state?.card?.productsgoogle.push({ id: prod.id, quantity: 1 }); //add one quantity
      }
    },
    removegoogleCard: (state, { payload: prod }) => {
      //quantity decrease by 1
      const index = state?.card?.productsgoogle?.findIndex(
        (pro) => pro.id == prod.id
      );
      const p = state?.card?.productsgoogle[index];
      if (index >= 0 && p.quantity > 1) {
        state.card.productsgoogle.splice(index, 1, {
          id: p.id,
          quantity: p.quantity - 1,
        });
      } else if (p?.quantity == 1) {
        state.card.productsgoogle.splice(index, 1); // quantity zero(delete from card)
      }
    },
    removegoogleCardall: (state, { payload: prod }) => {
      // Filter out the product from the card products array
      state.card.productsgoogle = state.card.productsgoogle.filter(
        (product) => product.id !== prod.id
      );
    },
  },
});

export const {
  addgoogleProducts,
  addgoogleCard,
  removegoogleCard,
  removegoogleCardall,
} = shoppinggoogleSlice.actions;

export const selectCardgoogle = (state) => state.shoppinggoogle.card;
export const selectProductsgoogle = (state) =>
  state.shoppinggoogle.productsgoogle;

export default shoppinggoogleSlice.reducer;
