import { createSlice } from "@reduxjs/toolkit";

const shoppingmicroSlice = createSlice({
  name: "shoppingmicro",
  initialState: {
    card: { userId: 0, productsmicro: [] },
    productsmicro: [],
  },
  reducers: {
    addmicroProducts: (state, action) => {
      //add product to list
      return { ...state, productsmicro: action.payload };
    },
    addmicroCard: (state, { payload: prod }) => {
      //quantity increase by 1
      const index = state?.card?.productsmicro?.findIndex(
        (pro) => pro.id == prod.id
      );
      if (index >= 0) {
        const p = state?.card?.productsmicro[index];
        state.card.productsmicro.splice(index, 1, {
          id: p.id,
          quantity: p.quantity + 1,
        });
      } else {
        state?.card?.productsmicro.push({ id: prod.id, quantity: 1 }); //add one quantity
      }
    },
    removemicroCard: (state, { payload: prod }) => {
      //quantity decrease by 1
      const index = state?.card?.productsmicro?.findIndex(
        (pro) => pro.id == prod.id
      );
      const p = state?.card?.productsmicro[index];
      if (index >= 0 && p.quantity > 1) {
        state.card.productsmicro.splice(index, 1, {
          id: p.id,
          quantity: p.quantity - 1,
        });
      } else if (p?.quantity == 1) {
        state.card.productsmicro.splice(index, 1); // quantity zero(delete from card)
      }
    },
    removemicroCardall: (state, { payload: prod }) => {
      // Filter out the product from the card products array
      state.card.productsmicro = state.card.productsmicro.filter(
        (product) => product.id !== prod.id
      );
    },
  },
});

export const {
  addmicroProducts,
  addmicroCard,
  removemicroCard,
  removemicroCardall,
} = shoppingmicroSlice.actions;

export const selectCardmicro = (state) => state.shoppingmicro.card;
export const selectProductsmicro = (state) => state.shoppingmicro.productsmicro;

export default shoppingmicroSlice.reducer;
