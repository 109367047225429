import React, { useState, useEffect, Suspense, useMemo } from "react"; // Removed lazy, useNavigate, useLocation
import { HelmetProvider } from "react-helmet-async";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom"; // Moved Navigate here from useNavigate
import axios from "axios";
import BlankLayout from "./layout/blanklayout/BlankLayout";
import FullLayout from "./layout/full-layout/FullLayout";

function App() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const helmetContext = {};

  const ProtectedRoute = useMemo(() => {
    return ({ children }) => {
      const loggedUser = users.find((user) => user.role === "admin");
      console.log("loggedUser-div:", loggedUser);
      return loggedUser ? children : <Navigate to="/login" />;
    };
  }, [users]);

  useEffect(() => {
    axios
      .get("https://servers.myvidya.co.in/api/v1/users")
      .then((res) => {
        setUsers(res?.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <HelmetProvider context={helmetContext}>
        <Suspense fallback={<div>Loading components...</div>}>
          <Routes>
            <Route path="/*" element={<BlankLayout />} />
            <Route
              path="/secured/*"
              element={
                <ProtectedRoute>
                  <FullLayout />
                </ProtectedRoute>
              }
            />
          </Routes>
        </Suspense>
      </HelmetProvider>
    </>
  );
}

export default App;
