import { API, endpoints } from "../api";

class AddtocartmicroService {
  static createAddtocartmicro() {
    return API.post(endpoints.api.microsoft.create);
  }

  static updateAddtocartmicro() {
    return API.post(endpoints.api.microsoft.update);
  }

  static deleteAddtocartmicro() {
    return API.post(endpoints.api.microsoft.delete);
  }

  static fetchOneAddtocartmicro() {
    return API.post(endpoints.api.microsoft.getOne);
  }

  // static fetchAllAddtocartmicro() {
  //   return API.post(endpoints.api.microsoft.getAll);
  // }

  static fetchAllAddtocartmicro(query) {
    const url = query
      ? endpoints.api.microsoft.getAll + query
      : endpoints.api.microsoft.getAll;
    return API.get(url);
  }
}

export default AddtocartmicroService;
