import React, { useState } from "react";
import Header from "../header2/Header";
import Seo from "../seo/Seo";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";

const Automotive = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    to: "suraj@grank.co.in",
    subject: "Enquire From Career Form",
    message: "",
    name: "",
    phone: "",
    branch: "",
    designation: "",
    experience: "",
    city: "",
    business: "",
    company: "",
    cc: "server@grank.co.in",
    bcc: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm(formData);
    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await fetch(
          "https://servers.myvidya.co.in/send-email2",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        if (response.ok) {
          await response.json();
          localStorage.setItem("userMessage", formData.email);
          navigate("/thank-you");
        } else {
          // You can log the error message from the response here if needed
          const errorResult = await response.json();
          console.error("Error from server:", errorResult);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    } else {
      setErrors(validationErrors);
    }
  };

  const validateForm = (data) => {
    let errors = {};
    if (!data.name || !data.name.trim()) {
      errors.name = "Name is required";
    }
    if (!data.phone || !data.phone.trim()) {
      errors.phone = "Phone is required";
    }
    if (!data.email || !data.email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(data.email.trim())) {
      errors.email = "Invalid email format";
    }
    if (!data.message || !data.message.trim()) {
      errors.message = "Message is required";
    }
    return errors;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Grank",
    url: "https://www.grank.co.in/career",
    logo: "https://www.grank.co.in/assest/photos/g-rank-white-logo.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "8522953903",
      contactType: "",
      areaServed: "IN",
      availableLanguage: "en",
    },
    sameAs: [
      "https://www.facebook.com/Grank.India",
      "https://twitter.com/Grank_India/",
      "https://www.instagram.com/grank.india/",
      "https://www.youtube.com/channel/UCAuK0EC-TmLLHVSmrjxMnIA",
      "https://www.linkedin.com/company/g-rank-digital-marketing-company/",
    ],
  };

  return (
    <>
      <Seo
        title="Career | G-Rank"
        description="Explore our Career webpage to discover exciting opportunities, a supportive work environment, and a chance to be part of a dynamic team."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="react, meta tags, seo"
        searchengines="ALL"
        canonical="https://www.grank.co.in/career"
        schema={schema}
      />
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100 career-img-banner "
            src="assest/photos/career/career-banner.png"
            alt="G-Rank"
            title="career"
          />
          <div className="career-banner-div">
            <h2 className="waviy ">
              <span style={{ color: "#fff" }}>Hello there!</span>
            </h2>
            <motion.h3
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1.2 }}
            >
              If you have it in you to be a change-maker, team player and
              impactful contributor, you are welcome.
            </motion.h3>
            <Breadcrumbs
              className=" breadcrumbs"
              aria-label="breadcrumb"
              style={{
                paddingTop: "40px",
                display: "flex",
                justifyContent: "center",
                color: "#fff",
              }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#fff",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <Typography color="#fff">Blog</Typography>
            </Breadcrumbs>
          </div>
          <div className="career-yellow-div">
            <h2>What we look for in you</h2>
          </div>
        </div>
        {/* banner end */}
        {/* second-section start */}
        <section className="career-second-section">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 career-second-div">
                <motion.img
                  className="img-fluid"
                  src="assest/photos/career/career-icon1.png"
                  alt="G-Rank"
                  title="career"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.2 }}
                />
                <h3>People Skills</h3>
                <p>
                  We seek individuals with excellent interpersonal skills,
                  capable of building strong relationships within the team.
                </p>
              </div>
              <div className="col-12 col-md-4 col-lg-4 career-second-div">
                <motion.img
                  className="img-fluid"
                  src="assest/photos/career/career-icon2.png"
                  alt="G-Rank"
                  title="career"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.2 }}
                />
                <h3>Growth Mindset</h3>
                <p>
                  We look for individuals who are eager to learn and adapt,
                  contributing to their personal and professional growth.
                </p>
              </div>
              <div className="col-12 col-md-4 col-lg-4 career-second-div">
                <motion.img
                  className="img-fluid"
                  src="assest/photos/career/career-icon3.png"
                  alt="G-Rank"
                  title="career"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 1.2 }}
                />
                <h3>Team Player</h3>
                <p>
                  We value collaboration and the ability to work effectively
                  with others towards common goals.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* second-section end */}
        <section className="career-form-section">
          <div className="container">
            <h1>Apply Now</h1>
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-md-6 mb-3">
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    placeholder="Name"
                    required
                  />
                  {errors.name && <span className="error">{errors.name}</span>}
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <input
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    placeholder="Phone"
                    required
                  />
                  {errors.phone && (
                    <span className="error">{errors.phone}</span>
                  )}
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    placeholder="Email"
                    required
                  />
                  {errors.email && (
                    <span className="error">{errors.email}</span>
                  )}
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <input
                    type="text"
                    name="branch"
                    value={formData.branch}
                    onChange={handleChange}
                    placeholder="Branch"
                    required
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <input
                    type="text"
                    name="designation"
                    value={formData.designation}
                    onChange={handleChange}
                    placeholder="Designation"
                    required
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <input
                    type="text"
                    name="experience"
                    value={formData.experience}
                    onChange={handleChange}
                    placeholder="Experience"
                    required
                  />
                </div>
                <div className="col-12 col-md-6 mb-3">
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    placeholder="City"
                    required
                  />
                </div>
                <div className="col-12 mb-3">
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    placeholder="Your Message"
                    required
                  ></textarea>
                  {errors.message && (
                    <span className="error">{errors.message}</span>
                  )}
                </div>
              </div>
              <button type="submit" className="submit-btn">
                Submit
              </button>
            </form>
          </div>
        </section>
      </section>
    </>
  );
};

export default Automotive;
