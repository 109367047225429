import { addProducts } from "../../../app/slices/shoppingSlice";
import AddtocartService from "../../../services/AddtocartService";

export const loadProducts = () => (dispatch, getState) => {
  AddtocartService.fetchAllAddtocart()
    .then((response) => {
      dispatch(addProducts(response?.data));
      localStorage.setItem("responce.data", JSON.stringify(response?.data));
    })
    .catch((err) => {});
};
