import React, { useEffect, useState } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import MailLockIcon from "@mui/icons-material/MailLock";
import PermDataSettingIcon from "@mui/icons-material/PermDataSetting";
//import AddIcon from "@mui/icons-material/Add";
//import RemoveIcon from "@mui/icons-material/Remove";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Seo from "../seo/Seo";
//import AddToCart from "../../../features/frontend/addtocart/AddToCart";
import { loadProducts } from "../../../features/frontend/addtocart/thunk-product";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";

const Hosting = () => {
  const { handleOpen } = React.useContext(CustomerContext);
  const [inputData, setInputData] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadProducts());
  }, [dispatch]);
  // useState section start--------------------------------------
  const [isShowMore, setIsShowMore] = useState(false);

  const toggleReadMoreLess = () => {
    setIsShowMore(!isShowMore);
  };
  // acoordian button
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);

  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings4 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    arrows: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // -------------------
  const handleSubmit = (event) => {
    event.preventDefault();

    // Assuming you have predefined pages based on input values
    const pages = {
      home: "/",
      domain: "/domain",
      "domain booking": "/domain-booking",
      "domain available": "/domain-available",
      "domain transfer": "/domain-transfer",

      hosting: "/hosting-service",
      "shared hosting": "/shared-hosting-provider",
      "wordpress hosting": "/wordpress-hosting-services",
      "dedicated hosting": "/dedicated-hosting-services",
      "vps hosting": "/vps-hosting-services",
      security: "/web-security-services",
      "standard ssl": "/standard-ssl",
      "wildcard ssl": "/wildcard-ssl",
      "organization validation ssl": "/organization-validation-ssl",
      "extended validation ssl": "/extented-validation-ssl",
      email: "/email-hosting-provider",
      "google workspace": "/google-workspace-service-provider",
      microsoft: "/microsoft-365-service-provider",
      "rediff mail": "/rediff-mail",
      branding: "/branding-agency",
      "brand consulting": "/brand-consulting-firm",
      "brand strategy": "/brand-strategy-agency",
      "brand positioning": "/brand-positioning-agency",
      "brand identity": "/brand-identity-agency",
      "brand communication": "/brand-communication-agency",
      "performance marketing": "/performance-marketing-company",
      "social media marketing": "/social-media-marketing-agency",
      "search engine marketing": "/search-engine-marketing-agency",
      "organic marketing": "/organic-marketing-agency",
      "search engine optimization": "/search-engine-optimization-agency",
      "social media optimization": "/social-media-optimization-agency",
      "creative content": "/creative-content-agency",
      "display ads": "/display-ads-agency",
      "web design": "/web-design-agency",
      "print design": "/print-design-agency",
      "content marketing": "/content-marketing-agency",
      "social media": "/social-media-agency",
      "organics marketing": "/organic-social-media-agency",
      "paid marketing": "/paid-marketing-agency",
      "web development": "/web-development-agency",
      "wordpress development": "/wordpress-development-agency",
      "ecommerce development": "/ecommerce-development-agency",
      gallery: "/our-gallery",
      "case studies": "/case-studies",
      blog: "/blog",
      careers: "/career",
      b2b: "/b2b-digital-marketing-agency",
      b2c: "/b2c-digital-marketing-agency",
      power: "/power-digital-marketing-agency",
      hardware: "/hardware-digital-marketing-agency",
      healthcare: "/hosting-service",
      automotive: "/automotive-digital-marketing-agency",
      tech: "/tech-digital-marketing-agency",
      manufacture: "/manufacturing-digital-marketing-agency",
      "raw material": "/raw-material-digital-marketing-agency",
      "real estate": "/realestate-digital-marketing-agency",
      ecommerce: "/ecommerce-digital-marketing-agency",
      "interial furniture": "/interior-furniture-digital-marketing-agency",
      education: "/education-digital-marketing-agency",
      lifestyle: "/lifestyle-digital-marketing-agency",
    };

    // Get the corresponding page based on the input value
    const nextPage =
      pages[inputData.toLowerCase("") && inputData.toLowerCase()];

    if (nextPage) {
      // Redirect to the corresponding page
      navigate(nextPage);
    } else {
      // Handle invalid input or show an error message
      alert("Invalid input. Please enter a valid value.");
    }
  };

  const schema = {
    "@context": "https://schema.org/",
    "@type": "FAQPage",
    mainEntity: [
      {
        "@type": "Question",
        name: "What is Web Hosting?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Web Hosting refers to the service that allows individuals or organizations to make their websites accessible on the internet. It involves storing website files on a server connected to the internet, ensuring visitors can access the website anytime",
        },
      },
      {
        "@type": "Question",
        name: "What types of web hosting are available?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "There are various types of web hosting, including shared hosting, virtual private server (VPS) hosting, dedicated hosting, cloud hosting, and reseller hosting. Each type offers different levels of performance, control, and scalability.",
        },
      },
      {
        "@type": "Question",
        name: "What factors should I consider when choosing a web hosting provider?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Some important factors to consider when choosing a web hosting provider include reliability, performance, customer support, scalability, pricing, security features, control panel options, and backup services. It's also helpful to read reviews and consider the provider's reputation.",
        },
      },
      {
        "@type": "Question",
        name: "Can I host multiple websites on a single hosting plan?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Many hosting providers offer plans allowing you to host multiple websites on a single account. Shared hosting plans often provide this feature, while other types of hosting may have limitations or require additional fees for hosting multiple websites.",
        },
      },
      {
        "@type": "Question",
        name: "How much disk space and bandwidth do I need for my website?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "The required disk space and bandwidth depend on your website's content, expected traffic, and functionality. A few gigabytes of disk space and several hundred gigabytes of bandwidth should be sufficient for small to medium-sized websites. However, choosing a hosting plan that allows room for growth is advisable.",
        },
      },
      {
        "@type": "Question",
        name: "Can I transfer my website from one hosting provider to another?",
        acceptedAnswer: {
          "@type": "Answer",
          text: "Yes, transferring your website from one hosting provider to another is possible. Most hosting providers offer migration services or guides on transferring your website files, databases, and configurations. It's recommended to consult with the new hosting provider or seek technical assistance to ensure a smooth transfer.",
        },
      },
    ],
    website: {
      "@type": "WebSite",
      name: "grank",
      url: "https://www.grank.co.in/",
      potentialAction: {
        "@type": "SearchAction",
        target: "https://www.grank.co.in/hosting-service{search_term_string}",
        "query-input": "required name=search_term_string",
      },
    },
  };

  return (
    <>
      <Seo
        title="Web Hosting Services in Bangalore | Hyderabad"
        description="Web hosting services in bangalore and hyderabad. Explore a range of 
                    reliable and secure hosting options that provide the foundation for your digital success."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="web hosting site,
        buy best hosting site,
        best web hosting,
        best web hosting services in india,
        best hosting provider,
        best website hosting"
        searchengines="ALL"
        canonical="https://www.grank.co.in/hosting-service"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        <div>
          <div className="hosting-banner">
            <div className="row">
              <div className="col-md-5" id="order2">
                <div className="exp-hosting">
                  <motion.h2
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    Experience Real Uptime <br /> With Us!
                  </motion.h2>

                  <motion.p
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    If you are looking for an reliable web hosting provider for
                    optimal web performance, look no further!
                  </motion.p>

                  <motion.h3
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.6 }}
                    onClick={handleOpen}
                  >
                    <NavLink to="">Explore</NavLink>
                  </motion.h3>
                </div>
                <Breadcrumbs
                  className="hosting-breadcumbs breadcrumbs"
                  aria-label="breadcrumb"
                >
                  <NavLink
                    style={({ isActive }) => ({
                      color: isActive ? "#000" : "#000",
                    })}
                    to="/"
                  >
                    <p>Home</p>
                  </NavLink>

                  <Typography color="#000">Hosting</Typography>
                </Breadcrumbs>
              </div>
              <div className="col-md-7" id="order1">
                <img
                  className="img-fluid w-100"
                  src="assest/photos/hosting/hosting-banner.png"
                  alt="G-Rank"
                  title="grank"
                />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="explore-plans">
            <motion.h2
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
            >
              Explore the web hosting plans!
            </motion.h2>

            <motion.h3
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">Click Here</NavLink>
            </motion.h3>
          </div>
        </div>

        <div>
          <div className="features-sec">
            <div className="container">
              <div className="fea">
                <h2>Features</h2>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="box-left">
                    <div className="one-box">
                      <div className="icon-box">
                        <OpenInFullIcon />
                      </div>
                      <div className="content-box">
                        <h3>Scalability</h3>
                        <p>
                          As your business expands and attracts more visitors,
                          upgrading your hosting plan allows for increased
                          traffic, storage, and bandwidth, ensuring optimal
                          website performance during surges in traffic.
                        </p>
                      </div>
                    </div>

                    <div className="one-box">
                      <div className="icon-box">
                        <MailLockIcon />
                      </div>
                      <div className="content-box">
                        <h3>Security and Data Protection</h3>
                        <p>
                          As your business expands and attracts more visitors,
                          upgrading your hosting plan allows for increased
                          traffic, storage, and bandwidth, ensuring optimal
                          website performance during surges in traffic.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="box-right">
                    <div className="one-box">
                      <div className="icon-box">
                        <SupportAgentIcon />
                      </div>
                      <div className="content-box">
                        <h3>Technical Support</h3>
                        <p>
                          Experienced technical support for website hosting,
                          including troubleshooting, server maintenance, and
                          feature configuration. Having a knowledgeable
                          assistant saves time and effort.
                        </p>
                      </div>
                    </div>

                    <div className="one-box">
                      <div className="icon-box">
                        <PermDataSettingIcon />
                      </div>
                      <div className="content-box">
                        <h3>Reliability and Performance</h3>
                        <p>
                          A reliable server infrastructure and robust technical
                          support provide your website with minimal downtime and
                          optimal performance.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="titleh2 mt60">
                <h2>Why Choose Us!</h2>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="whychoose-sec">
            <div className="container-fluid">
              <Slider {...settings4}>
                <div className="slider-div4">
                  <div className="op">
                    <img
                      src="assest/photos/hosting/badge.png"
                      alt="G-Rank"
                      title="grank"
                    />
                    <h2 className="slider-img4">Reliability and Uptime</h2>
                    <p>
                      High uptime guarantee, ideally 99.9% or higher. With good
                      reliability website can be accessible to visitors at all
                      times
                    </p>
                  </div>
                </div>

                <div className="slider-div4">
                  <div className="op">
                    <img
                      src="assest/photos/hosting/high-speed.png"
                      alt="G-Rank"
                      title="grank"
                    />
                    <h2 className="slider-img4">Performance and Speed</h2>
                    <p>
                      Fast loading times for a positive user experience and SEO
                      rankings.
                    </p>
                  </div>
                </div>

                <div className="slider-div4">
                  <div className="op">
                    <img
                      src="assest/photos/hosting/scalability.png"
                      alt="G-Rank"
                      title="grank"
                    />
                    <h2 className="slider-img4">Scalability</h2>
                    <p>
                      Offer scalable plans that allow you to easily upgrade as
                      your website's traffic and needs increase
                    </p>
                  </div>
                </div>

                <div className="slider-div4">
                  <div className="op">
                    <img
                      src="assest/photos/hosting/secure-shield.png"
                      alt="G-Rank"
                      title="grank"
                    />
                    <h2 className="slider-img4">Security Measures</h2>
                    <p>
                      Robust security features, including SSL certificates,
                      regular backups, and malware scanning to keep your website
                      and data safe.
                    </p>
                  </div>
                </div>

                <div className="slider-div4">
                  <div className="op">
                    <img
                      src="assest/photos/hosting/control-panel.png"
                      alt="G-Rank"
                      title="grank"
                    />
                    <h2 className="slider-img4">Control Panel</h2>
                    <p>
                      A user friendly control panel, such as cPanel or Plesk, to
                      manage your website and hosting settings more straight
                      forward and intuitive.
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>

        {/* <div>
          <div className="bestprice-sec">
            <div className="container-fluid">
              <div className="titleh2w">
                <h2>Our Best Pricing Plan</h2>

                <h1>Hosting Services Provider</h1>
                <p>
                  We know that everybody's needs are different. That's why{" "}
                  <br />
                  we've created a plan that'll work for you.
                </p>
              </div>

              <div className="row mt60">
                <div className="col-md-4">
                  <div className="price-box">
                    <div className="row bos">
                      <div className="col-md-6 pr0">
                        <div className="pt-pricebox-info">
                          <span className="pt-price-title"> Basic Plan </span>
                          <div className="dollar-price">
                            <h2 className="price">
                              <span className="dollar">&#x24;</span>
                              29.95
                            </h2>
                            <p className="pt-price-duration">/Per Year</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 pl0">
                        <ul className="pt-list-info">
                          <li>Domain</li>
                          <li>Hosting</li>
                          <li>Website Maintenance</li>
                          <li>SSL Certificate</li>
                          <li>Google Workspace</li>
                          <li>Google Marketing</li>
                          <li>24/7 Support</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="price-box">
                    <div className="row bos">
                      <div className="col-md-6 pr0">
                        <div className="pt-pricebox-info">
                          <span className="pt-price-title">
                            {" "}
                            Standard Plan{" "}
                          </span>
                          <div className="dollar-price">
                            <h2 className="price">
                              <span className="dollar">&#x24;</span>
                              35.95
                            </h2>
                            <p className="pt-price-duration">/Per Year</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 pl0">
                        <ul className="pt-list-info">
                          <li>Domain</li>
                          <li>Hosting</li>
                          <li>Website Maintenance</li>
                          <li>SSL Certificate</li>
                          <li>Google Workspace</li>
                          <li>Google Marketing</li>
                          <li>24/7 Support</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="price-box">
                    <div className="row bos">
                      <div className="col-md-6 pr0">
                        <div className="pt-pricebox-info">
                          <span className="pt-price-title"> Premium Plan </span>
                          <div className="dollar-price">
                            <h2 className="price">
                              <span className="dollar">&#x24;</span>
                              40.44
                            </h2>
                            <p className="pt-price-duration">/Per Year</p>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-6 pl0">
                        <ul className="pt-list-info">
                          <li>Domain</li>
                          <li>Hosting</li>
                          <li>Website Maintenance</li>
                          <li>SSL Certificate</li>
                          <li>Google Workspace</li>
                          <li>Google Marketing</li>
                          <li>24/7 Support</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mt40">
                <div className="col-md-12">
                  <p className="table-p">
                    Comprehensive breakdown of our plans
                  </p>
                </div>
              </div>

              <section>
                <div className="">
                  <div className="overflow-div">
                    <div className=" p-0 table-static-div">
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p style={{ color: " #436fe8" }}>
                            Our Full Features Comparison
                          </p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Domain</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Hosting</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Website Maintenance</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>SSL Certificate</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Google Workspace Mails</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Google Marketing</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Social Media Marketing</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Branding</p>
                        </div>
                      </h4>
                      <h4 className="table-cell-tag2">
                        <div className="cell-inner">
                          <p>Technical Support</p>
                        </div>
                      </h4>
                    </div>
                    <AddToCart />
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div> */}

        {/* FAQs section start*/}
        <div>
          <section className="FAQs-sec">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <motion.h2
                    className="slider-heading"
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.6 }}
                  >
                    FAQ'S
                  </motion.h2>
                  <div className="accordion" id="accordionExample">
                    <div className="card">
                      <div className="card-header" id="headingOne">
                        <h2 className="mb-0 panel-title">
                          <button
                            className="btn btn-link btn-block text-left"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                            onClick={() => setOpen1(!open1)}
                          >
                            What is Web Hosting?
                          </button>
                        </h2>
                      </div>

                      <div
                        id="collapseOne"
                        className="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <div className="card-body-span">
                            Web Hosting refers to the service that allows
                            individuals or organizations to make their websites
                            accessible on the internet. It involves storing
                            website files on a server connected to the internet,
                            ensuring visitors can access the website anytime.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingTwo">
                        <h2 className="mb-0 panel-title">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                            onClick={() => setOpen2(!open2)}
                          >
                            What types of web hosting are available ?
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseTwo"
                        className="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <div className="card-body-span">
                            There are various types of web hosting, including
                            shared hosting, virtual private server (VPS)
                            hosting, dedicated hosting, cloud hosting, and
                            reseller hosting. Each type offers different levels
                            of performance, control, and scalability.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="headingThree">
                        <h2 className="mb-0 panel-title">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                            onClick={() => setOpen3(!open3)}
                          >
                            What factors should I consider when choosing a web
                            hosting provider ?
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapseThree"
                        className="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <div className="card-body-span">
                            Some important factors to consider when choosing a
                            web hosting provider include reliability,
                            performance, customer support, scalability, pricing,
                            security features, control panel options, and backup
                            services. It's also helpful to read reviews and
                            consider the provider's reputation.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading4">
                        <h2 className="mb-0 panel-title">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse4"
                            aria-expanded="false"
                            aria-controls="collapse4"
                            onClick={() => setOpen4(!open4)}
                          >
                            Can I host multiple websites on a single hosting
                            plan ?
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapse4"
                        className="collapse"
                        aria-labelledby="heading4"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <div className="card-body-span">
                            Many hosting providers offer plans allowing you to
                            host multiple websites on a single account. Shared
                            hosting plans often provide this feature, while
                            other types of hosting may have limitations or
                            require additional fees for hosting multiple
                            websites.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card">
                      <div className="card-header" id="heading5">
                        <h2 className="mb-0 panel-title">
                          <button
                            className="btn btn-link btn-block text-left collapsed"
                            type="button"
                            data-toggle="collapse"
                            data-target="#collapse5"
                            aria-expanded="false"
                            aria-controls="collapse5"
                            onClick={() => setOpen5(!open5)}
                          >
                            How much disk space and bandwidth do I need for my
                            website ?
                          </button>
                        </h2>
                      </div>
                      <div
                        id="collapse5"
                        className="collapse"
                        aria-labelledby="heading5"
                        data-parent="#accordionExample"
                      >
                        <div className="card-body">
                          <div className="card-body-span">
                            The required disk space and bandwidth depend on your
                            website's content, expected traffic, and
                            functionality. A few gigabytes of disk space and
                            several hundred gigabytes of bandwidth should be
                            sufficient for small to medium-sized websites.
                            However, choosing a hosting plan that allows room
                            for growth is advisable.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isShowMore && (
                    <div className="accordion" id="accordionExample2">
                      <div className="card">
                        <div className="card-header" id="heading6">
                          <h2 className="mb-0 panel-title">
                            <button
                              className="btn btn-link btn-block text-left collapsed"
                              type="button"
                              data-toggle="collapse"
                              data-target="#collapse6"
                              aria-expanded="false"
                              aria-controls="collapse6"
                              onClick={() => setOpen6(!open6)}
                            >
                              Can I transfer my website from one hosting
                              provider to another ?
                            </button>
                          </h2>
                        </div>
                        <div
                          id="collapse6"
                          className="collapse"
                          aria-labelledby="heading6"
                          data-parent="#accordionExample2"
                        >
                          <div className="card-body">
                            <div className="card-body-span">
                              Yes, transferring your website from one hosting
                              provider to another is possible. Most hosting
                              providers offer migration services or guides on
                              transferring your website files, databases, and
                              configurations. It's recommended to consult with
                              the new hosting provider or seek technical
                              assistance to ensure a smooth transfer.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="readmore-button" onClick={toggleReadMoreLess}>
                    {isShowMore ? (
                      <ArrowDropUpIcon className="readmore-up" />
                    ) : (
                      <ArrowDropDownIcon className="readmore-up" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        {/* FAQs section end*/}

        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div>
              <motion.h2
                className="branding-quick-link"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.6 }}
              >
                Quick Links
                <p className="d-block"> For You</p>
              </motion.h2>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}

        <section>
          <div className="performance-footerimg-div">
            <img
              className="img-fluid w-100"
              src="assest/photos/branding/branding-footer-img.png"
              alt="G-Rank"
              title="grank"
            />
            <div className="performance-footer-div-text">
              <h4>Learn more about our sevices</h4>
              <form className="searchArea" onSubmit={handleSubmit}>
                <button type="submit" tabindex="2">
                  Explore
                </button>
                <div className="inputDiv">
                  <input
                    type="text"
                    id="inputData"
                    name="inputData"
                    value={inputData}
                    onChange={(e) => setInputData(e.target.value)}
                    requiredtabindex="1"
                  />
                </div>
              </form>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Hosting;
