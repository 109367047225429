import { lazy } from "react";
import DashboardIcon from "@mui/icons-material/Dashboard";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import AddCardIcon from "@mui/icons-material/AddCard";
import GroupIcon from "@mui/icons-material/Group";

// Lazy load the components
const Dashboard = lazy(() =>
  import("../../features/admin/dashboard/Dashboard")
);
const Customers = lazy(() =>
  import("../../features/admin/Custumers/Customers")
);
const Users = lazy(() => import("../../features/admin/users/Users"));
const CartDetail = lazy(() =>
  import("../../features/admin/Cartdetail/CartDetail")
);

const adminRoutes = [
  {
    label: "Dashboard",
    path: "",
    showInMenu: true,
    component: Dashboard, // Pass component reference, not JSX
    icon: <DashboardIcon />,
    roles: ["admin"],
    hasSubRoutes: false,
  },
  {
    label: "Customers Details",
    path: "customers",
    showInMenu: true,
    component: Customers,
    icon: <GroupIcon />,
    roles: ["admin", "users", "customer"],
    hasSubRoutes: false,
  },
  {
    label: "Payment Details",
    path: "orders",
    showInMenu: true,
    component: Users,
    icon: <BookmarkBorderIcon />,
    roles: ["admin", "users", "customer"],
    hasSubRoutes: false,
  },
  {
    label: "Cart Details",
    path: "cartdetails",
    showInMenu: true,
    component: CartDetail,
    icon: <AddCardIcon />,
    roles: ["admin", "users", "customer"],
    hasSubRoutes: false,
  },
];

// Export the array
export default adminRoutes;
