import React, { useState } from "react";
import Header from "../header2/Header";
import { motion } from "framer-motion";
import Slider from "react-slick";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { NavLink } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import CustomerContext from "../blog-folder/CustomerContext";
import Seo from "../seo/Seo";
//import AddIcon from "@mui/icons-material/Add";
//import RemoveIcon from "@mui/icons-material/Remove";
const CreativeContent = () => {
  const { handleOpen } = React.useContext(CustomerContext);

  // acoordian button
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  //  useState section end----------------------------------------

  const settings3 = {
    infinite: true,
    speed: 1000,
    autoplay: true,
    slidesToShow: 6,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings7 = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 4,
    speed: 500,
    nextArrow: <SampleNextArrow2 />,
    prevArrow: <SamplePrevArrow2 />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings8 = {
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 4,
    speed: 500,
    nextArrow: <SampleNextArrow2 />,
    prevArrow: <SamplePrevArrow2 />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  // Slider section start-----------------------------------------
  function SampleNextArrow2(props) {
    const { onClick } = props;
    return (
      <div className="slider-custome-button-next2" onClick={onClick}>
        <ArrowForwardIosIcon className="forword-arrow2" />
      </div>
    );
  }

  function SamplePrevArrow2(props) {
    const { onClick } = props;
    return (
      <div className="slider-custome-button-prev2" onClick={onClick}>
        <ArrowBackIosIcon className="backword-arrow2" />
      </div>
    );
  }

  const schema = {
    "@context": "https://schema.org/",
    "@type": "WebSite",
    name: "Grank",
    url: "https://www.grank.co.in/",
    potentialAction: {
      "@type": "SearchAction",
      target:
        "https://www.grank.co.in/creative-content-agency{search_term_string}",
      "query-input": "required name=search_term_string",
    },
  };

  return (
    <>
      <Seo
        title="Creative Agency in Bangalore | Hyderabad"
        description="Creative agency in bangalore and hyderabad. Unleash your brand's creativity and captivate your audience with our Creative and Content services"
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="creative content agency in bangalore,
        creative content agency in hyderabad,
        creative and content agency in bangalore,
        creative and content agency in hyderabad,
        Best creative and content agency in India "
        searchengines="ALL"
        canonical="https://www.grank.co.in/creative-content-agency"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />
        {/* banner start */}
        <div className="creative-banner ">
          <img
            className="img-fluid w-100 mobile-none"
            src="assest/photos/creative-content/creative-content-banner.png"
            alt="G-Rank"
            title="creative content"
          />
          <img
            className="img-fluid w-100 desktop-none"
            src="assest/photos/creative-content/creative-content-banner-mobile.png"
            alt="G-Rank"
            title="creative content"
          />
          <div className="creative-banner-div">
            <motion.h1
              className="animate-charcter"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
            >
              Build emotional
              <span className="d-block">connections</span>
            </motion.h1>
            <h2>Think Big Think Different</h2>

            <motion.h3
              href="#"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              <NavLink to="">Get Started!</NavLink>
            </motion.h3>
            <Breadcrumbs
              className=" breadcrumbs"
              aria-label="breadcrumb"
              style={{ float: "right", paddingTop: "40px", color: "#fff" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#fff" : "#fff",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>

              <Typography color="#fff">Creative Content</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}

        {/* bulb images start */}
        <div className="creative-banner">
          <img
            className="img-fluid w-100"
            src="assest/photos/creative-content/bulb-img.png"
            alt="G-Rank"
            title="creative content"
          />
          <div className="creative-bulb-banner-div">
            <motion.h4
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
            >
              Creativity{" "}
            </motion.h4>
            <motion.h2
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1.2 }}
            >
              is our secret
              <br /> recipe
            </motion.h2>
            <p>
              You need thorough planning, compelling storytelling, and a touch
              of inspiration. From initial sketches to brainstorming sessions,
              we meticulously consider your project across all platforms.
            </p>
          </div>
        </div>

        {/* bulb images end */}
        {/* slider section start */}
        <div className="container-fluid creative-blue-section">
          <div className="creative-blue-div">
            <motion.h4
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 1 }}
            >
              The Power of Content in
              <motion.span
                className="d-block"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1.2 }}
              >
                Digital Marketing
              </motion.span>
            </motion.h4>
          </div>
          <Slider {...settings7}>
            <div className="slider-div77">
              <motion.div
                className="org-sem-slider"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                <h4>
                  Diverse Content <br />
                  Formats
                </h4>
                <p>
                  We create content in various formats, including videos,
                  infographics, podcasts, and traditional blog posts and social
                  media updates.
                </p>
              </motion.div>
            </div>
            <div className="slider-div77">
              <motion.div
                className="org-sem-slider"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                <h4>
                  Data-Driven <br />
                  Approach
                </h4>
                <p>
                  Our strategy is continuously refined using data analysis for
                  better results and performance.
                </p>
              </motion.div>
            </div>
            <div className="slider-div77">
              <motion.div
                className="org-sem-slider"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                <h4>
                  Engaging
                  <br />
                  Storytelling
                </h4>
                <p>
                  Our experienced team crafts content using storytelling
                  techniques to resonate with the target audience.
                </p>
              </motion.div>
            </div>

            <div className="slider-div77">
              <motion.div
                className="org-sem-slider"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                <h4>
                  SEO <br />
                  Focus
                </h4>
                <p>
                  We prioritize search engine optimization (SEO) by utilizing
                  keywords and optimization techniques to drive organic traffic.
                </p>
              </motion.div>
            </div>
            <div className="slider-div77">
              <motion.div
                className="org-sem-slider"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                <h4>
                  Specialization in
                  <br /> Website Optimization
                </h4>
                <p>
                  Our focus is on website optimization, utilizing the best SEO
                  practices to boost your online presence.
                </p>
              </motion.div>
            </div>
            <div className="slider-div77">
              <motion.div
                className="org-sem-slider"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                <h4>
                  Premium SEO <br />
                  Services
                </h4>
                <p>
                  We offer premium SEO services designed to help you achieve a
                  higher ranking and stay ahead in the digital landscape.
                </p>
              </motion.div>
            </div>
          </Slider>
        </div>
        {/* slider section end */}
        {/* Question mark start */}
        <section className="question-creative-section">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 question-creative-section-img">
                <img
                  className="img-fluid w-100 "
                  src="assest/photos/creative-content/grey-back.png"
                  alt="G-Rank"
                  title="creative content"
                />
                <img
                  className="img-fluid w-100 shakex question-creative-img"
                  src="assest/photos/creative-content/question-creative.png"
                  alt="G-Rank"
                  title="creative content"
                />
              </div>
              <div className="col-12 col-md-8 col-lg-8 question-creative-div ">
                <motion.h4
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Why Choose <span className="d-block">Us?</span>
                </motion.h4>
                <motion.h2
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  At our innovative creative and content agency, we specialize
                  in crafting captivating digital experiences. Our team
                  seamlessly weaves dynamic content into a modular design,
                  ensuring an engaging and persuasive presentation for our
                  clients. We settle for nothing less than extraordinary Whether
                  you're seeking eye-catching graphic design, captivating
                  content creation, or innovative web development, we've got you
                  covered.
                </motion.h2>
                <br />
                <br />

                <motion.h3
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Explore our services
                </motion.h3>

                <motion.h2
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  Brand Development Agency specializing in Branding, Content
                  Strategy, Design, Photography, Copywriting, Research,
                  Corporate Presentation, Pitch Deck, and Social Media Designs.
                  We are your complete Digital Marketing and Creative Design
                  Agency.
                </motion.h2>
              </div>
            </div>
          </div>
        </section>
        {/* Question mark end */}
        {/* action yellow start*/}
        <div className="action-yellow-div-text">
          <motion.p
            initial={{ opacity: 0, scale: 0, y: 100 }}
            whileInView={{ opacity: 1, scale: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Want to Elevate Your Brand?
            <br /> Get in Touch.
          </motion.p>
          <NavLink to="">
            <motion.p
              className="action-yellow-div-buttom"
              initial={{ opacity: 0, scale: 0 }}
              whileInView={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6 }}
              onClick={handleOpen}
            >
              Contact Us <ArrowRightAltIcon />
            </motion.p>
          </NavLink>
        </div>
        {/* action yellow end*/}
        {/* slider up section start */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <motion.h4
                className="creative-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Our Creative <span> Services</span>
              </motion.h4>
            </div>
            <div className="col-12">
              <Slider {...settings8}>
                <div className="slider-div77">
                  <motion.div
                    className="box"
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    <div className="hid-box2">
                      <img
                        className=""
                        src="assest/photos/creative-content/creative-icon1.png"
                        alt="G-Rank"
                        title="creative content"
                      />
                      <h4>Graphics and Images</h4>
                    </div>
                    <p className="hid-box">
                      We specialize in creating visually stunning social media
                      branded design posters, customized graphics for various
                      platforms, and advertisements on social media, web, and
                      mobile.
                    </p>
                  </motion.div>
                </div>
                <div className="slider-div77">
                  <motion.div
                    className="box"
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    <div className="hid-box2">
                      <img
                        className=""
                        src="assest/photos/creative-content/creative-icon2.png"
                        alt="G-Rank"
                        title="creative content"
                      />
                      <h4>Case Studies and Whitepapers</h4>
                    </div>
                    <p className="hid-box">
                      Our studio assists in presenting your research and case
                      studies professionally with customized templates, design
                      layouts, charts, graphics, and optimized e-Versions
                    </p>
                  </motion.div>
                </div>
                <div className="slider-div77">
                  <motion.div
                    className="box"
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    <div className="hid-box2">
                      <img
                        className=""
                        src="assest/photos/creative-content/creative-icon3.png"
                        alt="G-Rank"
                        title="creative content"
                      />
                      <h4>Videos</h4>
                    </div>
                    <p className="hid-box">
                      Our services include product explainer videos, animated
                      videos, investor pitch videos, social media content
                      videos, infographic videos, and optional voiceovers and
                      music.
                    </p>
                  </motion.div>
                </div>
                <div className="slider-div77">
                  <motion.div
                    className="box"
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    <div className="hid-box2">
                      <img
                        className=""
                        src="assest/photos/creative-content/creative-icon4.png"
                        alt="G-Rank"
                        title="creative content"
                      />
                      <h4>Emailers</h4>
                    </div>
                    <p className="hid-box">
                      We specialize in creating effective emailer designs to
                      help you engage with your audience and drive desired
                      actions. To maintain a consistent and recognisable brand
                      image, we ensure that your email designs align with your
                      brand guidelines, including fonts, colors, and logo
                      placement.
                    </p>
                  </motion.div>
                </div>
                <div className="slider-div77">
                  <motion.div
                    className="box"
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    <div className="hid-box2">
                      <img
                        className=""
                        src="assest/photos/creative-content/creative-icon5.png"
                        alt="G-Rank"
                        title="creative content"
                      />
                      <h4>Print Design</h4>
                    </div>
                    <p className="hid-box">
                      We create designs for print advertisements that are
                      attention-grabbing and persuasive, helping you reach your
                      target audience effectively. Our team creates eye-catching
                      posters and banners for various purposes, including
                      events, promotions, and advertising campaigns.
                    </p>
                  </motion.div>
                </div>
                <div className="slider-div77">
                  <motion.div
                    className="box"
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    <div className="hid-box2">
                      <img
                        className=""
                        src="assest/photos/creative-content/creative-icon6.png"
                        alt="G-Rank"
                        title="creative content"
                      />
                      <h4>Web Design</h4>
                    </div>
                    <p className="hid-box">
                      We offer responsive web design, ensuring your website
                      looks great on all devices. Our designs follow SEO best
                      practices for improved visibility and rankings. We provide
                      ongoing support and maintenance to keep your website
                      secure and up-to-date.
                    </p>
                  </motion.div>
                </div>
                <div className="slider-div77">
                  <motion.div
                    className="box"
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    <div className="hid-box2">
                      <img
                        className=""
                        src="assest/photos/creative-content/creative-icon7.png"
                        alt="G-Rank"
                        title="creative content"
                      />
                      <h4>Landing Pages</h4>
                    </div>
                    <p className="hid-box">
                      Our responsive and visually appealing landing pages are
                      designed for web and mobile, with development options
                      across HTML, Bootstrap, WordPress, Joomla, Magento, and
                      Prestashop, focusing on conversion and engagement.rite.
                    </p>
                  </motion.div>
                </div>
                <div className="slider-div77">
                  <motion.div
                    className="box"
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    <div className="hid-box2">
                      <img
                        className=""
                        src="assest/photos/creative-content/creative-icon8.png"
                        alt="G-Rank"
                        title="creative content"
                      />
                      <h4>Brochures and Posters</h4>
                    </div>
                    <p className="hid-box">
                      We design custom-made brochures and posters, offering
                      e-versions and print versions, copywriting, and printing
                      assistance.
                    </p>
                  </motion.div>
                </div>
                <div className="slider-div77">
                  <motion.div
                    className="box"
                    initial={{ opacity: 0, scale: 0 }}
                    whileInView={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1 }}
                  >
                    <div className="hid-box2">
                      <img
                        className=""
                        src="assest/photos/creative-content/creative-icon9.png"
                        alt="G-Rank"
                        title="creative content"
                      />
                      <h4>Presentations</h4>
                    </div>
                    <p className="hid-box">
                      We provide concept-based designs, icons, fonts, style
                      guides, and PowerPoint themes to create impactful decks.
                    </p>
                  </motion.div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
        {/* slider up section end */}
        {/* flip card start */}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <motion.h4
                className="creative-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                <span> Our Content</span> services
              </motion.h4>
            </div>
            <div className="col-12 col-md-3 col-lg-3 p-0">
              <div>
                <div className="creative-flip-card" tabIndex="0">
                  <div className="flip-card-inner">
                    <div className="creative-flip-card-front3 creat-img1">
                      <h4>Landing Page Content Creation</h4>
                      {/* <h3>More</h3> */}
                    </div>
                    <div className="flip-card-back">
                      <h3>
                        We specialize in crafting engaging and persuasive
                        content for your landing pages. Our team of experienced
                        writers will ensure that your landing pages effectively
                        communicate your message, capture attention, and drive
                        conversions.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 col-lg-3 p-0">
              <div>
                <div className="creative-flip-card" tabIndex="0">
                  <div className="flip-card-inner">
                    <div className="creative-flip-card-front3 creat-img2">
                      <h4>Product and Service Descriptions</h4>
                      {/* <h3>More</h3> */}
                    </div>
                    <div className="flip-card-back">
                      <h3>
                        Whether you need compelling content to describe your
                        products or services, our creative writers can create
                        informative and engaging descriptions highlighting their
                        unique features and benefits. We'll help you captivate
                        your audience and increase your sales potentia
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 col-lg-3 p-0">
              <div>
                <div className="creative-flip-card" tabIndex="0">
                  <div className="flip-card-inner">
                    <div className="creative-flip-card-front3 creat-img3">
                      <h4>Portfolio Development</h4>
                      {/* <h3>More</h3> */}
                    </div>
                    <div className="flip-card-back">
                      <h3>
                        Showcasing your work effectively is crucial for gaining
                        trust and credibility. Our content marketing services
                        include portfolio development, where we create
                        compelling narratives around your projects and
                        achievements, helping you make a lasting impression on
                        potential clients.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 col-lg-3 p-0">
              <div>
                <div className="creative-flip-card" tabIndex="0">
                  <div className="flip-card-inner">
                    <div className="creative-flip-card-front3 creat-img4">
                      <h4>Whitepaper Content Creation</h4>
                      {/* <h3>More</h3> */}
                    </div>
                    <div className="flip-card-back">
                      <h3>
                        We understand the importance of well-researched and
                        authoritative whitepapers. Our team of experts can
                        create in-depth whitepapers that provide valuable
                        insights and establish your industry expertise.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 col-lg-3 p-0">
              <div>
                <div className="creative-flip-card" tabIndex="0">
                  <div className="flip-card-inner">
                    <div className="creative-flip-card-front3 creat-img5">
                      <h4>Search Engine Optimized (SEO) Content</h4>
                      {/* <h3>More</h3> */}
                    </div>
                    <div className="flip-card-back">
                      <h3>
                        Our content marketing services extend to creating
                        SEO-friendly content that helps your website rank higher
                        in search engine results. We strategically incorporate
                        relevant keywords to boost your organic visibility and
                        attract targeted traffic.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 col-lg-3 p-0">
              <div>
                <div className="creative-flip-card" tabIndex="0">
                  <div className="flip-card-inner">
                    <div className="creative-flip-card-front3 creat-img6">
                      <h4>E-Newsletter Content</h4>
                      {/* <h3>More</h3> */}
                    </div>
                    <div className="flip-card-back">
                      <h3>
                        Keep your subscribers engaged and informed with our
                        expertly crafted e-newsletter content. Our writers will
                        curate compelling content that resonates with your
                        audience, driving higher open and click-through rates.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 col-lg-3 p-0">
              <div>
                <div className="creative-flip-card" tabIndex="0">
                  <div className="flip-card-inner">
                    <div className="creative-flip-card-front3 creat-img7">
                      <h4>Articles and Blog Posts</h4>
                      {/* <h3>More</h3> */}
                    </div>
                    <div className="flip-card-back">
                      <h3>
                        Our talented writers can develop high-quality articles
                        and blog posts tailored to your target audience. From
                        industry trends to informative how-to guides, we create
                        valuable content that keeps readers returning for more.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 col-lg-3 p-0">
              <div>
                <div className="creative-flip-card" tabIndex="0">
                  <div className="flip-card-inner">
                    <div className="creative-flip-card-front3 creat-img8">
                      <h4>Content Proofreading</h4>
                    </div>
                    <div className="flip-card-back">
                      <h3>
                        Ensure your content is error-free and polished with our
                        professional proofreading services. Our meticulous
                        proofreaders will review your content for grammar,
                        spelling, punctuation, and clarity, ensuring your
                        message is delivered flawlessly.
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* flip card end */}
        {/* FAQs section start*/}
        <section>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <motion.h2
                  className="slider-heading"
                  initial={{ opacity: 0, scale: 0 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.6 }}
                >
                  FAQ's & Support
                </motion.h2>
                <div className="accordion" id="accordionExample">
                  <div className="card">
                    <div className="card-header" id="headingOne">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          onClick={() => setOpen1(!open1)}
                        >
                          How can display advertising benefit my business?
                        </button>
                      </h2>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Display advertising can elevate brand visibility,
                          attract new customers, and reinforce brand recall
                          through visually engaging ads strategically placed on
                          relevant websites and platforms.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingTwo">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                          onClick={() => setOpen2(!open2)}
                        >
                          Why is professional web design crucial for my
                          business?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Professional web design not only enhances user
                          experience and brand credibility but also ensures
                          mobile responsiveness, fast loading times, and SEO
                          optimization, ultimately leading to increased website
                          traffic and conversions.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="headingThree">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                          onClick={() => setOpen3(!open3)}
                        >
                          What are the advantages of investing in print design
                          for marketing collateral?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Print design offers tangible marketing materials that
                          can leave a lasting impression on your audience,
                          conveying brand messaging effectively across various
                          mediums such as brochures, flyers, posters, and
                          business cards.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-header" id="heading4">
                      <h2 className="mb-0 panel-title">
                        <button
                          className="btn btn-link btn-block text-left collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapse4"
                          aria-expanded="false"
                          aria-controls="collapse4"
                          onClick={() => setOpen4(!open4)}
                        >
                          How does content marketing contribute to business
                          growth?
                        </button>
                      </h2>
                    </div>
                    <div
                      id="collapse4"
                      className="collapse"
                      aria-labelledby="heading4"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body">
                        <div className="card-body-span">
                          Content marketing establishes brand authority,
                          nurtures customer relationships, and drives organic
                          traffic by delivering valuable, relevant, and
                          consistent content that resonates with your target
                          audience across various digital channels.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* FAQs section end*/}

        {/* Quick Link section start */}
        <section>
          <div className="container-fluid">
            <div className="col-12">
              <motion.h4
                className="creative-header"
                initial={{ opacity: 0, scale: 0 }}
                whileInView={{ opacity: 1, scale: 1 }}
                transition={{ duration: 1 }}
              >
                Quick Links <span>For You</span>
              </motion.h4>
            </div>
            <div>
              <Slider {...settings3}>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/branding-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Branding</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/creative-content-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Creative & <p className="d-block"> Content</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/organic-marketing-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Organic <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/performance-marketing-company"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Performance <p className="d-block"> marketing</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/social-media-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Social Media</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-development-agency"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Development</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/domain"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Domain</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/hosting-service"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">
                      Web <p className="d-block"> Hosting</p>
                    </h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/web-security-services"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Security</h4>
                  </div>
                </NavLink>
                <NavLink
                  style={({ isActive }) => ({
                    color: isActive ? "#000" : "#000",
                  })}
                  to="/email-hosting-provider"
                  className=""
                >
                  <div className="slider-div2">
                    <h4 className="slider-img2">Email</h4>
                  </div>
                </NavLink>
              </Slider>
            </div>
          </div>
        </section>
        {/* Quick Link section end */}
        <img
          className="img-fluid w-100"
          src="assest/photos/branding/branding-footer-img.png"
          alt="G-Rank"
          title="creative content"
        />
      </section>
    </>
  );
};

export default CreativeContent;
