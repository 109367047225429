import { addrediffProducts } from "../../../app/slices/shoppingrediffSlice";
import AddtocartrediffService from "../../../services/AddtocartrediffService";

export const loadProducts = () => (dispatch, getState) => {
  AddtocartrediffService.fetchAllAddtocartrediff()
    .then((response) => {
      dispatch(addrediffProducts(response?.data));
    })
    .catch((err) => {});
};
