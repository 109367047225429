import { addgoogleProducts } from "../../../app/slices/shoppinggoogleSlice";
import AddtocartgoogleService from "../../../services/AddtocartgoogleService";

export const loadProducts = () => (dispatch, getState) => {
  AddtocartgoogleService.fetchAllAddtocartgoogle()
    .then((response) => {
      dispatch(addgoogleProducts(response?.data));
    })
    .catch((err) => {});
};
