import React from "react";
import Header from "../header2/Header";
import FancyBox from "./FancyBox";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Typography from "@mui/material/Typography";
import { NavLink } from "react-router-dom";

const OurGallery = () => {
  return (
    <>
      <section className="section-top">
        <Header />

        {/* banner start */}
        <div
          className="creative-banner-display-ads"
          style={{ backgroundColor: "#fff", position: "relative" }}
        >
          <img
            className="img-fluid w-100"
            src="assest/photos/our-team/meet-our-team-banner-white1.png"
            alt="G-Rank"
          />

          <div className="meet-our-team-banner-div">
            <h2>Gallery</h2>

            <Breadcrumbs
              className="breadcrumbs"
              aria-label="breadcrumb"
              style={{ padding: "40px" }}
            >
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#000",
                })}
                to="/"
              >
                <p>Home</p>
              </NavLink>
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? "#000" : "#000",
                })}
                to="/our-team"
              >
                <p>Our Team</p>
              </NavLink>

              <Typography color="#000">Gallery</Typography>
            </Breadcrumbs>
          </div>
        </div>
        {/* banner end */}

        {/* our team section start*/}
        <section>
          <div className="">
            <ul className="nav nav-tabs2" id="myTab" role="tablist">
              <li className=" nav-item2" role="presentation">
                <button
                  className="nav-link2 active"
                  id="home-tab2"
                  data-toggle="tab"
                  data-target="#home2"
                  type="button"
                  role="tab"
                  aria-controls="home2"
                  aria-selected="true"
                >
                  Festival Moments
                </button>
              </li>
              <li className="nav-item2" role="presentation">
                <button
                  className="nav-link2 "
                  id="home-tab1"
                  data-toggle="tab"
                  data-target="#home1"
                  type="button"
                  role="tab"
                  aria-controls="home1"
                  aria-selected="true"
                >
                  Birthdays & Events
                </button>
              </li>

              <li className="nav-item2" role="presentation">
                <button
                  className="nav-link2"
                  id="profile-tab2"
                  data-toggle="tab"
                  data-target="#profile2"
                  type="button"
                  role="tab"
                  aria-controls="profile2"
                  aria-selected="false"
                >
                  Gaming Adventures
                </button>
              </li>
              <li className="nav-item2" role="presentation">
                <button
                  className="nav-link2"
                  id="contact-tab3"
                  data-toggle="tab"
                  data-target="#contact3"
                  type="button"
                  role="tab"
                  aria-controls="contact3"
                  aria-selected="false"
                >
                  Office Inauguration
                </button>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home2"
              role="tabpanel"
              aria-labelledby="home-tab2"
            >
              <section className="gallery-block-section">
                <FancyBox>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12 padding-none">
                            <a
                              data-fancybox="gallery"
                              href="assest/photos/gallery/festival-img7.jpeg"
                              data-caption="New Year 2024"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/festival-img7.jpeg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/gallery4.jpg"
                              data-fancybox="gallery"
                              data-caption="Ganesh Chaturthi"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/gallery4.jpg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/2.jpg"
                              data-fancybox="gallery"
                              data-caption="Traditional"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/2.jpg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8 col-md-8 col-sm-12 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/gallery1.jpeg"
                              data-fancybox="gallery"
                              data-caption="Our Team"
                            >
                              <img
                                className="gallery-block-img2 w-100"
                                src="assest/photos/gallery/gallery1.jpeg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                          <div className="col-lg-4 col-md-4 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/gallery7.jpeg"
                              data-fancybox="gallery"
                              data-caption="Traditional"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/gallery7.jpeg"
                                alt="Grank"
                              />
                            </a>

                            <a
                              href="assest/photos/gallery/7.jpg"
                              data-fancybox="gallery"
                              data-caption="Ganesh Chaturthi"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/7.jpg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-12  col-md-4 col-lg-4 padding-none">
                        <div className="row">
                          <div className="col-12 padding-none">
                            <a
                              href="assest/photos/gallery/3.jpg"
                              data-fancybox="gallery"
                              data-caption="Ganesh Chaturthi"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/3.jpg"
                                alt="Grank"
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/10.jpg"
                              data-fancybox="gallery"
                              data-caption="Diwali Celebration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/11.jpg"
                              data-fancybox="gallery"
                              data-caption="Diwali Celebration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/11.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </FancyBox>
              </section>
            </div>
            <div
              className="tab-pane "
              id="home1"
              role="tabpanel"
              aria-labelledby="home-tab1"
            >
              <section className="gallery-block-section">
                <div className="container ">
                  <FancyBox>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-8 col-lg-8 padding-none">
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/birthday/6.jpg"
                              data-fancybox="gallery"
                              data-caption="Birthday"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/birthday/6.jpg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/b5.jpg"
                              data-fancybox="gallery"
                              data-caption="Party"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/b5.jpg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/birthday-img3.jpeg"
                              data-fancybox="gallery"
                              data-caption="Cake Cutting"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/birthday-img3.jpeg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8 col-md-8  col-12 padding-none">
                            <a
                              href="assest/photos/gallery/b3.jpg"
                              data-fancybox="gallery"
                              data-caption="Enjoy Moment"
                            >
                              <img
                                className="gallery-block-img2 w-100"
                                src="assest/photos/gallery/b3.jpg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                          <div className="col-lg-4 col-md-4  col-12 padding-none">
                            <a
                              href="assest/photos/gallery/b1.jpg"
                              data-fancybox="gallery"
                              data-caption="Birthday Celebration"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/b1.jpg"
                                alt="Grank"
                              />
                            </a>

                            <a
                              href="assest/photos/gallery/b2.jpg"
                              data-fancybox="gallery"
                              data-caption="Birthday Celebration"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/b2.jpg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-12  col-md-4 col-lg-4 ">
                        <div className="row">
                          <div className="col-12 padding-none">
                            <a
                              href="assest/photos/gallery/b9.jpg"
                              data-fancybox="gallery"
                              data-caption="Birthday Celebration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/b9.jpg"
                                alt="Grank"
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/birthday-img1.jpeg"
                              data-fancybox="gallery"
                              data-caption="Birthday Celebration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/birthday/2.jpg"
                              data-fancybox="gallery"
                              data-caption="Birthday Celebration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/birthday/3.jpg"
                              data-fancybox="gallery"
                              data-caption="Birthday Celebration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/birthday/4.jpg"
                              data-fancybox="gallery"
                              data-caption="Birthday Celebration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/birthday/5.jpg"
                              data-fancybox="gallery"
                              data-caption="Birthday Celebration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/birthday/1.jpg"
                              data-fancybox="gallery"
                              data-caption="Birthday Celebration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/birthday/7.jpg"
                              data-fancybox="gallery"
                              data-caption="Birthday Celebration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/birthday/8.jpg"
                              data-fancybox="gallery"
                              data-caption="Birthday Celebration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/birthday/9.jpg"
                              data-fancybox="gallery"
                              data-caption="Birthday Celebration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/birthday/10.jpg"
                              data-fancybox="gallery"
                              data-caption="Birthday Celebration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/birthday/11.jpg"
                              data-fancybox="gallery"
                              data-caption="Birthday Celebration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/birthday/12.jpg"
                              data-fancybox="gallery"
                              data-caption="Birthday Celebration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </FancyBox>
                </div>
              </section>
            </div>
            <div
              className="tab-pane fade "
              id="profile2"
              role="tabpanel"
              aria-labelledby="profile-tab2"
            >
              <section className="gallery-block-section">
                <div className="container ">
                  <FancyBox>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-8 col-lg-8 padding-none">
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/f1.jpg"
                              data-fancybox="gallery1"
                              data-caption="Gaming Adventure"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/f1.jpg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/gallery12.jpeg"
                              data-fancybox="gallery1"
                              data-caption="Gaming Adventure"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/gallery12.jpeg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/game-img3.jpeg"
                              data-fancybox="gallery1"
                              data-caption="Play Time"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/game-img3.jpeg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8 col-md-8 col-sm-12 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/f2.jpg"
                              data-fancybox="gallery1"
                              data-caption="Gaming Adventure"
                            >
                              <img
                                className="gallery-block-img2 w-100"
                                src="assest/photos/gallery/f2.jpg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/gaming/4.jpg"
                              data-fancybox="gallery1"
                              data-caption="Gaming Adventure"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/gaming/4.jpg"
                                alt="Honer home"
                                title="Honer home"
                              />
                            </a>

                            <a
                              href="assest/photos/gallery/gaming/1.jpg"
                              data-fancybox="gallery1"
                              data-caption="Gaming Adventure"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/gaming/1.jpg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-12  col-md-4 col-lg-4 ">
                        <div className="row">
                          <div className="col-12 padding-none">
                            <a
                              href="assest/photos/gallery/gaming/2.jpg"
                              data-fancybox="gallery1"
                              data-caption="Gaming Adventure"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/gaming/2.jpg"
                                alt="Grank"
                              />
                            </a>

                            <a
                              href="assest/photos/gallery/gaming/3.jpg"
                              data-fancybox="gallery1"
                              data-caption="Gaming Adventure"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </FancyBox>
                </div>
              </section>
            </div>
            <div
              className="tab-pane fade "
              id="contact3"
              role="tabpanel"
              aria-labelledby="contact3"
            >
              <section className="gallery-block-section">
                <div className="container ">
                  <FancyBox>
                    <div className="row">
                      <div className="col-12 col-sm-12 col-md-8 col-lg-8 padding-none">
                        <div className="row">
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/Inauguration/7.jpg"
                              data-fancybox="gallery1"
                              data-caption="Office Inauguration"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/Inauguration/7.jpg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/Inauguration/2.jpg"
                              data-fancybox="gallery1"
                              data-caption="Office Inauguration"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/Inauguration/2.jpg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/Inauguration/3.jpg"
                              data-fancybox="gallery1"
                              data-caption="Office Inauguration"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/Inauguration/3.jpg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8 col-md-8 col-sm-12 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/Inauguration/6.jpg"
                              data-fancybox="gallery1"
                              data-caption="Office Inauguration"
                            >
                              <img
                                className="gallery-block-img2 w-100"
                                src="assest/photos/gallery/Inauguration/6.jpg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                          <div className="col-lg-4 col-md-4 col-sm-12 col-12 padding-none">
                            <a
                              href="assest/photos/gallery/Inauguration/5.jpg"
                              data-fancybox="gallery1"
                              data-caption="Office Inauguration"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/Inauguration/5.jpg"
                                alt="Honer home"
                                title="Honer home"
                              />
                            </a>

                            <a
                              href="assest/photos/gallery/Inauguration/4.jpg"
                              data-fancybox="gallery1"
                              data-caption="Office Inauguration"
                            >
                              <img
                                className="gallery-block-img w-100"
                                src="assest/photos/gallery/Inauguration/4.jpg"
                                alt="Grank"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="col-12  col-md-4 col-lg-4 ">
                        <div className="row">
                          <div className="col-12 padding-none">
                            <a
                              href="assest/photos/gallery/Inauguration/1.jpg"
                              data-fancybox="gallery1"
                              data-caption="Office Inauguration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/Inauguration/1.jpg"
                                alt="Grank"
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/Inauguration/8.jpg"
                              data-fancybox="gallery1"
                              data-caption="Office Inauguration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/Inauguration/9.jpg"
                              data-fancybox="gallery1"
                              data-caption="Office Inauguration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                            <a
                              href="assest/photos/gallery/Inauguration/10.jpg"
                              data-fancybox="gallery1"
                              data-caption="Office Inauguration"
                            >
                              <img
                                className="gallery-block-img3 w-100"
                                src="assest/photos/gallery/10.jpg"
                                alt="Grank"
                                style={{ display: "none" }}
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </FancyBox>
                </div>
              </section>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default OurGallery;
