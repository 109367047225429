// dropdownSlice.js
import { createSlice } from "@reduxjs/toolkit";

const dropdowngoogleSlice = createSlice({
  name: "dropdowngoogle",
  initialState: {}, // Initial state will store selected options for each product
  reducers: {
    setSelectedOptiongoogle: (state, action) => {
      const { productId, selectedOptiongoogle } = action.payload;
      state[productId] = selectedOptiongoogle;
    },
  },
});

export const { setSelectedOptiongoogle } = dropdowngoogleSlice.actions;
export default dropdowngoogleSlice.reducer;
