import React from "react";
import ReactPlayer from "react-player";
import PauseIcon from "@mui/icons-material/Pause";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

function Player2({ isPlaying2, setIsPlaying2 }) {
  const handleisPlaying3 = () => {
    console.log("before playing?", isPlaying2);
    setIsPlaying2(true);
    console.log("after playing?", isPlaying2);
  };
  const handleisPlaying4 = () => {
    console.log("before playing?", isPlaying2);
    setIsPlaying2(false);
    console.log("after playing?", isPlaying2);
  };
  return (
    <div className="al-video">
      <ReactPlayer
        url="assest/videos/main-banner-video-ai-mobile2.mp4"
        playing={isPlaying2}
        autoPlay={true}
        controls={false}
        width="1200"
        height="auto"
        // ref={playerRef}
      />
      <div className="al-video-div">
        <button
          type="button"
          className="btn al-video-button"
          onClick={handleisPlaying3}
        >
          <PlayArrowIcon />
        </button>
        <button
          type="button"
          className="btn al-video-button"
          onClick={handleisPlaying4}
        >
          <PauseIcon />
        </button>
      </div>
    </div>
  );
}

export default Player2;
