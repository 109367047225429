import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectProductssecu,
  selectCardsecu,
  addsecuCard,
} from "../../../app/slices/shoppingsecuSlice";

const Product = ({
  _id,
  name,
  price,
  description,
  description2,
  description3,
  description4,
}) => {
  const dispatch = useDispatch();
  const cardsecu = useSelector(selectCardsecu);
  let message = "Add To Cart";
  if (cardsecu?.productssecu?.some((pro) => pro.id == _id))
    message = "Added To Cart";

  return (
    <div className="col-12 col-md-4 col-lg-3  email-sub-logo-div2">
      <h5>{name}</h5>
      <div className="email-sub-logo-div4">
        <h4>
          <img
            className="img-fluid "
            src="assest/photos/email/rupee.png"
            alt="G-Rank"
            title="Email"
          />
          {price}
          <span>/yr</span>
        </h4>
        <h2>(annual subscription)</h2>
        <h6
          onClick={() =>
            sessionStorage.setItem("product24", _id) |
            dispatch(addsecuCard({ id: _id }))
          }
        >
          {message}
        </h6>
        <h2>Try fee for one month</h2>
        <p>
          <img
            className="img-fluid micro-yes-height"
            src="assest/photos/email/check.png"
            alt="G-Rank"
            title="Email"
          />
          {description}
        </p>
        <p>
          <img
            className="img-fluid micro-yes-height"
            src="assest/photos/email/check.png"
            alt="G-Rank"
            title="Email"
          />
          {description2}
        </p>
        <p>
          <img
            className="img-fluid micro-yes-height"
            src="assest/photos/email/check.png"
            alt="G-Rank"
            title="Email"
          />
          {description3}
        </p>
        <p>
          <img
            className="img-fluid micro-yes-height"
            src="assest/photos/email/check.png"
            alt="G-Rank"
            title="Email"
          />

          {description4}
        </p>
      </div>
    </div>
  );
};
const AddToCartSecu = () => {
  const productssecu = useSelector(selectProductssecu);
  return (
    <>
      {Array.isArray(productssecu) &&
        productssecu.map((prod, i) => <Product key={i} {...prod} />)}
    </>
  );
};

export default AddToCartSecu;
