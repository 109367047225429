import React, { useState, useEffect } from "react";
import Header from "../header2/Header";
import { useNavigate, useLocation } from "react-router-dom";
import Seo from "../seo/Seo";

const Contact = ({ utmParams }) => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    to: "suraj@grank.co.in",
    subject: "Enquire From G-Rank",
    message: "",
    name: "",
    phone: "",
    city: "",
    business: "",
    company: "",
    url: "",
    // utmParams: utmParams.utm_source,
    cc: "server@grank.co.in",
    bcc: "aparna@grank.co.in",
  });

  const location = useLocation();
  useEffect(() => {
    // Update the URL when the component mounts
    setFormData((prevData) => ({ ...prevData, url: window.location.href }));
  }, [location]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("formdata", formData);

    try {
      const response = await fetch("https://servers.myvidya.co.in/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const result = await response.json();
        console.log(result.message);
        localStorage.setItem("userMessage", formData.email);
        navigate("/thank-you");
      } else {
        const errorResult = await response.json();
        console.error("Error:", errorResult.error);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const schema = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    name: "Grank",
    image: "https://www.grank.co.in/assest/photos/g-rank-white-logo.png",
    "@id": "",
    url: "https://www.grank.co.in/contact",
    telephone: "8522953903",
    address: {
      "@type": "PostalAddress",
      streetAddress:
        "2nd Floor, R K Lake Tower, Service Road, Subramani Nagar, Guddadahalli, Hebbal, Bengaluru, Karnataka-560024",
      addressLocality: "Bangalore",
      postalCode: "560024",
      addressCountry: "IN",
    },
    geo: {
      "@type": "GeoCoordinates",
      latitude: 13.0418288,
      longitude: 77.5933501,
    },
    sameAs: [
      "https://www.facebook.com/Grank.India",
      "https://www.instagram.com/grank.india/",
      "https://twitter.com/Grank_India/",
      "https://www.linkedin.com/company/g-rank-digital-marketing-company/",
      "https://www.youtube.com/channel/UCAuK0EC-TmLLHVSmrjxMnIA",
    ],
  };

  return (
    <>
      <Seo
        title="Contact in Bangalore | Hyderabad"
        description="Domain Transfer services in bangalore and hyderbad. Explore how we effortlessly move your web domains to a more reliable, efficient platform."
        name="Company name."
        type="article"
        YahooSeeker="INDEX, FOLLOW"
        msnbot="INDEX, FOLLOW"
        googlebot="INDEX, FOLLOW"
        keywords="best domain transfer services in India "
        searchengines="ALL"
        canonical="https://www.grank.co.in/domain-transfer"
        schema={schema}
      ></Seo>
      <section className="section-top">
        <Header />

        <section className="contact-us-section">
          <div className="container contact-us-contain">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-7 align-self-center">
                <img
                  className="img-fluid w-100 contact-img"
                  src="assest/photos/contact-us/contact-us2.png"
                  alt="G-Rank"
                />
              </div>
              <div className="col-12 col-md-12 col-lg-5 ">
                <div className="contact-us-form">
                  <h1>Get In Touch</h1>
                  <p>
                    Have an enquire or some feedback for us?
                    <br />
                    Fill out the form below to contact our team.
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="contact-flex">
                      <input
                        className="floating-label"
                        placeholder="Name"
                        type="text"
                        name="name"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="contact-flex">
                      <input
                        placeholder="Phone"
                        type="text"
                        name="phone"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="contact-flex">
                      <input
                        placeholder="Company Name"
                        type="text"
                        name="company"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="contact-flex">
                      <select
                        name="business"
                        className="contact-select-div"
                        onChange={handleChange}
                      >
                        <option value="none">Type Of Business</option>
                        <option value="B2B">B2B</option>
                        <option value="B2C">B2C</option>
                      </select>
                    </div>
                    <div className="contact-flex">
                      <h3>
                        <input
                          placeholder="City"
                          type="text"
                          name="city"
                          onChange={handleChange}
                        />
                      </h3>
                    </div>
                    <div className="contact-flex">
                      <input
                        placeholder="Email"
                        type="email"
                        name="email"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="contact-flex">
                      <textarea
                        placeholder="Message"
                        name="message"
                        id="myInput"
                        onChange={handleChange}
                      />
                    </div>
                    <h4>
                      <button type="submit">send</button>
                    </h4>
                  </form>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="contact-address">
                  <p>
                    1-8-382, Ground Floor, Queen’s Plaza,
                    <br /> Begumpet,Hyderabad – 500003
                  </p>
                  <h1>+91 8121826992</h1>
                  <h2>leads@grank.co.in</h2>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6">
                <div className="contact-address">
                  <p>
                    2nd Floor, R K Lake Tower, Service Road, Subramani Nagar,
                    <br />
                    Guddadahalli, Hebbal, Bengaluru, Karnataka-560024
                  </p>
                  <h1>+91 9686442800</h1>
                  <h2>leads@grank.co.in</h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Contact;
