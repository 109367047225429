import Login from "../../features/frontend/authentication/Login";
import Register from "../../features/frontend/authentication/Register";

// Assign array to a variable
const routes = [
  {
    label: "SignIn",
    path: "/login",
    showInMenu: true,
    addRoute: true,
    component: Login, // Pass component reference, not JSX
    authenticated: "no",
  },
  {
    label: "Profile",
    path: "/secured/customers",
    showInMenu: true,
    addRoute: false,
    component: Register, // Pass component reference
    authenticated: "yes",
  },
];

// Export the array as the default export
export default routes;
