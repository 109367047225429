import { addmicroProducts } from "../../../app/slices/shoppingmicroSlice";
import AddtocartmicroService from "../../../services/AddtocartmicroService";

export const loadProducts = () => (dispatch, getState) => {
  AddtocartmicroService.fetchAllAddtocartmicro()
    .then((response) => {
      dispatch(addmicroProducts(response?.data));
    })
    .catch((err) => {});
};
