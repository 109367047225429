import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate, Link } from "react-router-dom";
import Header from "../../../layout/blanklayout/header2/Header";
import axios from "axios";

const theme = createTheme();

const Register = () => {
  const navigate = useNavigate();
  const [values, setValues] = React.useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const registerUser = () => {
    axios
      .post(
        "https://servers.myvidya.co.in/register",

        values
      )
      .then((response) => {
        console.log("values", response);

        alert("Created");
        navigate("/login");
      })
      .catch((err) => {
        console.log("err", err);

        alert(err);
      });
  };

  return (
    <>
      <section className="section-top">
        <Header />
        <ThemeProvider theme={theme}>
          <Box className="sign-section">
            <Container>
              <CssBaseline />
              <Grid container justifyContent="center">
                <Grid item xs={12} md={7} xl={7} sx={{ alignSelf: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src="assest/photos/regi-img.png"
                      alt="G-Rank"
                      className="img-fluid  sign-img"
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={5} lg={5}>
                  <Box
                    sx={{
                      marginTop: 8,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <Paper
                      sx={{ px: 4, py: 4, my: 2, backgroundColor: "#f9f9f9" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          component="h1"
                          variant="h5"
                          className="sign-in-text"
                        >
                          Sign Up
                        </Typography>
                      </Box>
                      <Box
                        component="form"
                        noValidate
                        sx={{ mt: 3 }}
                        onSubmit={handleSubmit}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              autoComplete="given-name"
                              name="name"
                              required
                              value={values.name}
                              fullWidth
                              label="Name"
                              autoFocus
                              onChange={handleChange}
                            />
                          </Grid>

                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              label="Email Address"
                              value={values.email}
                              name="email"
                              autoComplete="email"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              label="Mobile "
                              name="mobile"
                              value={values.mobile}
                              autoComplete="mobile"
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              required
                              fullWidth
                              name="password"
                              label="Password"
                              type="password"
                              value={values.password}
                              autoComplete="new-password"
                              onChange={handleChange}
                            />
                          </Grid>
                        </Grid>
                        <Button
                          // type="submit"
                          fullWidth
                          variant="contained"
                          sx={{ mt: 3, mb: 2 }}
                          onClick={registerUser}
                        >
                          Sign Up
                        </Button>
                        <Grid container justifyContent="flex-end">
                          <Grid item>
                            <Link to="/login">
                              Already have an account? Sign in
                            </Link>
                          </Grid>
                        </Grid>
                      </Box>
                    </Paper>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </ThemeProvider>
      </section>
    </>
  );
};

export default Register;
