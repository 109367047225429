// dropdownSlice.js
import { createSlice } from "@reduxjs/toolkit";

const dropdownmicroSlice = createSlice({
  name: "dropdownmicro",
  initialState: {}, // Initial state will store selected options for each product
  reducers: {
    setSelectedOptionmicro: (state, action) => {
      const { productId, selectedOptionmicro } = action.payload;
      state[productId] = selectedOptionmicro;
    },
  },
});

export const { setSelectedOptionmicro } = dropdownmicroSlice.actions;
export default dropdownmicroSlice.reducer;
